@import "../../Utils/mixins.scss";

@mixin modelSettings($c) {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 50%;
  min-width: 10rem;

  h1 {
    font-family: $header-font-family, sans-serif;
    font-size: 1.5rem;
    font-weight: 300;
    color: $c;
    margin-top: 3rem;
  }

  .modelSettings-item {
    width: 100%;
    margin-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: .1rem solid transparentize($white, .9);

    &-top {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-label {
      font-family: $header-font-family, sans-serif;
      font-size: 1rem;
      font-weight: 400;
      color: $white;
    }

    &-input {
      &-number {
        @include input($c);
        width: 3rem;
        font-size: .8rem;

      }

      &-range {
        margin-top: .5rem;
        width: 100%;

        -webkit-appearance: none;
        appearance: none;
        height: .2rem;
        background: $white;
        outline: none;
        opacity: 0.7;
        transition: 0.3s;

        &:hover {
          opacity: 1;
        }

        &::-webkit-slider-thumb {
          border: .2rem solid $c;
          height: 1rem;
          width: 1rem;
          border-radius: 50%;
          background: $c;
          cursor: pointer;
          -webkit-appearance: none;
        }
      }
    }
  }
}

#expert.modelSettings {
  @include modelSettings($gold);
}

#summarizer.modelSettings {
  @include modelSettings($pink);
}

.modelSettings {
  &-label {
    font-family: $header-font-family, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    color: $white;
    margin-top: 1rem;
  }

  .dropdown {
    margin-top: .5rem;
  }
}