@import "../../../../../Utils/mixins";

.meetAppiCloud {
  transition: .3s;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
  position: relative;

  &-icon {
    position: absolute;
    height: 4rem;
    width: 4rem;
    padding: 1rem;
    border: $thinBorder;
    border-radius: $smallRadius;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s;
    color: transparentize($white, .5);

    img, svg {
      height: 100%;
      width: auto;
    }

    &-azure {
      top: 20%;
      left: 15%;
      transform: scale(1.5);
      color: $roseGold;
      border-color: transparentize($roseGold, .5);
    }

    &-cloud {
      top: 20%;
      right: 10%;
    }


  }

  &-hovered {
    .meetAppiCloud-icon-azure {
      transform: scale(1);
      color: transparentize($white, .5);
      border: $thinBorder;
      left: 10%;
    }

    .meetAppiCloud-icon-cloud {
      transform: scale(1.5);
      color: $gold;
      border-color: transparentize($gold, .5);
      right: 15%;
    }

    .meetAppiCloud-text {
      @include gradientText(-45deg, $white, $gold);
    }
  }

  &-text {
    position: absolute;
    bottom: 0;
    font-size: 1.5rem;
    display: flex;
    //color: $roseGold;
    @include gradientText(-45deg, $roseGold, $white);
    font-weight: 400;
    font-family: $title-font-family;

    p {
      margin: .5rem;
    }

  }

}

@include tablets {
  .meetAppiCloud {
    &-icon {
      height: 5rem;
      width: 5rem;
    }
  }
}

@include phoneLandscape {
  .meetAppiCloud {
    &-icon {
      height: 2rem;
      width: 2rem;
    }
  }
}