@import '../../../../Utils/mixins.scss';

.funToolItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 85vh;

  &-bottom {
    width: 100%;
    display: flex;
    flex-direction: row;

    &-stepper {
      margin-top: 1rem;
      width: 100%;
      color: $white;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      * {
        margin: 0.3rem;
      }

    }
  }

  &-middle {
    margin-top: 1rem;
    height: 80%;
  }
}
