@import "../../../../Utils/mixins.scss";

.staticTitle {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 3;
  position: relative;

  &-wrapper {
    position: relative;
    overflow: hidden;
    align-items: flex-start;
    flex-direction: row;
    height: 75vh;
    width: 100%;
  }

  &-title {
    font-family: $header-font-family, sans-serif;
    font-size: 3rem;
    font-weight: 700;
    margin-top: 1rem;
    margin-bottom: .5rem;
    color: $white;
  }

  &-subtitle {
    font-family: $paragraph-font-family, sans-serif;
    font-size: 1.2rem;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
    color: $white;
  }

  &-buttons {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 3rem;

    &-button {
      all: unset;
      font-family: $paragraph-font-family, sans-serif;
      font-size: 1rem;
      font-weight: 400;
      margin: 0 .8rem;
      padding: .5rem 2rem;
      cursor: pointer;
      transition: all .2s ease-in-out;
      color: $white;
      border-radius: $smallRadius;
      box-shadow: 0 0 1rem .2rem rgba(0, 0, 0, .3);
      text-align: center;

      &-main {
        color: $white;
        background: transparentize($gold, .8);
        border: 1px solid $gold;

        &:hover {
          color: $white;
        }
      }

      &:hover {
        background: transparentize($gold, .5);
      }
    }
  }
}

@include tablets {
  .staticTitle {
    &-wrapper {
      height: 100vh;
    }


    .titleComponent-text-subtitle {
      width: 90%;
      margin-top: 1rem;
      text-align: center;
      font-size: 1.5rem;
    }
  }
}

@include phoneLandscape {
  .titleComponent-text-subtitle {
    font-size: 1.2rem !important;
  }
}