@import "../../../Utils/colors.scss";
@import "../../../Utils/mixins.scss";

.myAgents-wrapper {
  @include defaultPage;
  position: relative;
  overflow: hidden;
  align-items: flex-start;
  flex-direction: row;
  background: $darkerBackground;
  min-height: 100vh;
}

.blobMyAgents {
  position: absolute;
  width: 20vh;
  height: 20vh;
  user-select: none;
  z-index: 1;

}

.blobMyAgents1 {
  top: 15vh;
  left: 5vh;
  transform: scale(2);
}

.blobMyAgents2 {
  top: 10vh;
  right: 0vh;
  transform: scale(2);
}

.blobMyAgents3 {
  top: 90vh;
  left: 0vh;
  transform: scale(2.5);

}

.myAgents {
  width: 100%;
  display: flex;
  height: 100%;
  backdrop-filter: blur(5rem);
  flex-direction: column;
  padding-top: 3rem;
  z-index: 2;
  min-height: calc(100vh - 3rem);

  &-loading {
    align-self: center;
    margin-top: 40vh;
    width: 4rem;
    height: 4rem;
    animation: spin 1s linear infinite;
    color: $gold;
  }


  &-title {
    margin-top: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      font-family: $header-font-family, sans-serif;
      font-size: 2rem;
      font-weight: 300;
      color: $white;
      margin-left: 1rem;
    }

    &-new {
      height: 1.8rem;
      width: 1.8rem;
      color: $gold;
      margin-left: 1rem;
      transition: .3s;

      &:hover {
        cursor: pointer;
        transform: scale(1.1);
      }
    }
  }

  &-title-summarizer {
    .myAgents-title-new {
      color: $pink;
    }
  }

  &-panel {
    margin-left: 5%;
    width: 90%;
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .agentCard {
      width: 30%;
      min-width: 20rem;
      max-width: 35rem;
    }
  }
}