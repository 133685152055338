@import "../../../Utils/mixins";

.userInput-wrapper {
  z-index: 3;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: .5rem;
  margin-left: 3rem;
  padding: .5rem 1rem;
  border-radius: $smallRadius;
  background: $darkBackground;
  box-shadow: 0 0 .2rem .1rem transparentize($darkestBlack, .5);
  width: 70%;
  position: relative;

  &-focused {
    transition: .3s;
    border-color: transparentize($white, .6);
  }

  &-top {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 3rem;
  }

  &-bottom {
    display: flex;
    align-items: center;
    width: 100%;
    height: 2rem;
    margin-top: .5rem;
  }
}

.userInput, .userInput-choosePrompt {
  all: unset;
  font-family: Lato, serif;
  font-size: 1rem;
  z-index: 2;
  display: flex;
  width: 100%;
  max-height: 20vh;
  resize: none;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  padding: 0 .5rem;
  color: transparentize($white, .2);
  overflow-wrap: break-word;

  &::placeholder {
    color: transparentize($white, .5);
  }

  &::-webkit-scrollbar {
    width: .15rem;
  }

  &::-webkit-scrollbar-track {
    background-color: transparentize($white, 1);
  }

}

@mixin iconsWrapper($c) {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: .5rem;
  cursor: pointer;
  transition: .3s;
  padding: .3rem .5rem;
  border-radius: $smallRadius;
  height: 1.5rem;
  border: .1rem solid transparentize($c, .8);

  &-icon {
    width: auto;
    height: .8rem;
    color: $c;
  }

  p {
    color: $c;
    font-family: $header-font-family;
    margin-left: .5rem;
    font-size: .8rem;
  }

  &:hover {
    background: transparentize($c, .7);
  }
}

.userInput-media {
  @include iconsWrapper($roseGold);

  &-file {
    display: flex;
    background: transparentize($roseGold, .4);
    border-radius: .3rem;
    padding: .2rem .5rem;
    margin-right: 1rem;

    &-icon, p {
      color: $darkerBackground;
    }
  }
}

.userInput-choosePrompt {
  align-items: center;
  justify-content: center;
  height: 1.6rem;
}

.userInput-agents {
  @include iconsWrapper($gold);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.userInput-agents-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  margin: 0 .2rem;

  img {
    width: 100%;
    height: 100%;
  }

  .userInput-agents-item-robot {
    width: auto;
    height: 80%;
  }
}

.userInput-summarizer, .userInput-addIcon {
  @include iconsWrapper($pink);

  &-wrapper {
    position: relative;
  }

  &-iconWrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 1.3rem;
    height: 1.3rem;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }

    #major {
      width: 80%;
      height: 100%;
    }

    #minor {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 50%;
      height: 50%;
      color: white;
    }
  }
}

.userInput-addIcon {
  @include iconsWrapper($gold);
}

.userInput-summarizer {
  #major {
    color: $pink;
  }

  .userInput-summarizer-robot {
    width: 100%;
    height: auto;
  }
}

.userInput-loading {
  margin-left: .5rem;

  #major {
    animation: spin .8s linear infinite;
    color: $white;
  }
}

.userInput-addIcon {
  #major {
    color: $gold;
  }
}

.send {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.6rem;
  height: 1.6rem;
  cursor: pointer;
  transition: .3s;
  color: $gold;
  margin-left: .5rem;

  &-final {
    color: $pink;
  }

  &:hover {
    transform: scale(1.1);
  }

}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}