@import "../../../Utils/mixins.scss";
@import "../../../Utils/colors.scss";

.wrapper {
  overflow: hidden;
  position: relative;
  background-color: $darkestBlack;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landingWrapper {
  @include defaultPage;
  position: relative;
  overflow-x: hidden;
  z-index: 2;
}


.landingSubpage {
  margin: 5rem 0;
}

.blobs {
  opacity: .7;
}

.staticList {
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;

  justify-content: center;
  flex-direction: column;
  width: 100%;

  .titleComponent-img > img {
    filter: brightness(100);
  }
}

.quickStart {
  .staticList-list {
    width: 50%;
    min-width: 50rem;
    grid-template-columns: 1fr 1fr;

    .staticListPoint {
      height: 18rem;
      min-width: 20rem;

      h2 {
        text-align: center;
      }

      svg {
        background: none;
      }
    }
  }
}

.forDocuments {
  .staticList-list3 {
    width: 60%;

    .staticListPoint {
      height: 25rem;
    }

    &-upper > .staticListPoint {
      height: 25rem;
    }

    &-lower {
      grid-template-columns: 4fr 5fr;
    }
  }
}

@include tablets {
  .documentShowcase, .funTool {
    display: none !important;
  }
}