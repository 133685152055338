@import "../../../../Utils/mixins";


.userInput-hover {
  position: absolute;
  bottom: 1.4rem;
  left: -8.5rem;
  color: $white;
  font-family: $paragraph-font-family;
  font-weight: 400;
  text-align: center;
  padding-bottom: .3rem;
  z-index: 5;
  h1 {
    padding: 0.7rem 1rem;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: transparentize($darkestBlack, .5);
    backdrop-filter: blur(1rem);
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.36);
    width: 17rem;
    display: flex;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
  }

  &-close {
    margin-left: .5rem;
  }
}