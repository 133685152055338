@import "../../../Utils/mixins";

@include tablets {
  .documentShowcase {
    #stage_1.documentShowcase-wrapper {
      width: 90%;
      height: 50vh;
    }

    #stage_2.documentShowcase-wrapper,
    #stage_3.documentShowcase-wrapper,
    #stage_4.documentShowcase-wrapper {
      height: 85vh;
      width: 90%;
    }
  }
}