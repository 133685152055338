@import "../../../../Utils/mixins.scss";

.solutionIndividuals {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  backdrop-filter: blur(5rem);
  background: transparentize($darkerBackground, .5);

  &-wrapper {
    overflow: hidden;
    position: relative;
  }

  .individualDocuments {
    .staticList-list2 {
      width: 65%;
      min-width: 50rem;
      grid-template-columns: 1fr 1fr;

      .staticListPoint {
        min-height: 18rem;
        height: unset;
        min-width: 20rem;

        h2 {
          text-align: center;
        }

        svg {
          background: none;
        }
      }
    }
  }

  .staticList-list3 {
    width: 50%;
    grid-template-columns: 1fr 1fr;

    .staticListPoint {
      height: 15rem;
      min-width: 20rem;

      h2 {
        text-align: center;
      }

      svg {
        background: none;
      }

      &-img {
        display: none;
      }
    }
  }

  &-customAgents {
    .staticListPoint {
      svg {
        width: 2rem;
        height: 2rem;
        color: $pink;
        margin-top: 0;
        padding: 0;
      }

    }

    .staticProps {
      margin: 5rem 0;
    }

  }
}
