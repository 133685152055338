@import "../../../../Utils/mixins";
@import "../../../../Utils/colors";

.chat-sidebar-agents, .chat-sidebar-summarizer {
  position: relative;
  display: flex;
  overflow-y: auto;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  min-height: calc(100% - 4rem);
  margin-top: 1rem;
  transition: .3s;

  &-filler {
    width: 2rem;
    height: 7rem;
    color: $darkerBackground;
  }

  .line-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    margin-bottom: 1rem;

    .line {
      width: 100%;
      height: 1px;
      background-color: transparentize($white, .7);
      margin: .8rem;
    }

    h1 {
      font-size: 1.2rem;
      font-family: $paragraph-font-family;
      font-weight: 400;
      padding-top: .5rem;
      color: $white;
      width: 100%;
      margin-left: 1rem;
    }
  }

  .chat-sidebar-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
    height: 8rem;
    margin-bottom: 1rem;
    padding: 1.5rem 0;
    cursor: pointer;
    transition: .3s;
    border: $thinBorder;
    background: transparentize($darkBackground, 0.1);
    box-shadow: 1vh 1vh 5vh rgba(0, 0, 0, 0.25);
    border-radius: $smallRadius;

    h1 {
      font-family: $header-font-family, sans-serif;
      font-size: 1rem;
      font-weight: 400;
      margin-right: .5rem;
      margin-left: .5rem;
      color: $white;
      width: calc(100% - 3.5rem);
    }

    img {
      width: 2.5rem;
      height: 2.5rem;
      margin-left: .5rem;
      border-radius: 1rem;
    }

    &-icon {
      width: 2.5rem;
      height: 1.5rem;
      margin-left: .5rem;
      color: $gold;
    }

    &-selected {
      flex-direction: column;
      text-align: center;

      .chat-sidebar-item-icon, img {
        margin-top: 1rem;
      }

      h1 {
        font-family: $header-font-family, sans-serif;
        margin-top: .5rem;
        transform: scale(1.1);
        color: $gold;
      }
    }

    &-expand {
      font-family: $header-font-family, sans-serif;
      font-size: .7rem;
      font-weight: 300;
      margin-top: .5rem;
      margin-bottom: 2rem;
      margin-left: .5rem;
      text-align: center;
      color: $white;
    }

    &-expand-button {
      position: absolute;
      right: .2rem;
      bottom: 0;
      width: auto;
      height: 1.4rem;
      transition: .3s;
      color: $gold;

      &-redirect {
        position: absolute;
        right: 1.5rem;
        bottom: .3rem;
        width: auto;
        height: 1rem;
        transition: .3s;
        color: $gold;

        &:hover {
          color: $white;
        }
      }

      &:hover {
        color: $white;
      }
    }

    #rotate {
      transform: rotate(180deg);
    }
  }

  #agents-item-selected {
    border: 1px solid transparentize($gold, .3);
  }

  #summarizer-item.chat-sidebar-item, #summarizer-item-selected.chat-sidebar-item {

    .chat-sidebar-item-expand-button {
      color: $pink;

      &-redirect {
        color: $pink;

        &:hover {
          color: $white;
        }
      }

      &:hover {
        color: $white;
      }
    }

  }

  #summarizer-item-selected {
    border: 2px solid $pink;
  }

  #summarizer-item.chat-sidebar-item-selected,
  #summarizer-item-selected.chat-sidebar-item-selected {
    h1 {
      color: desaturate($pink, 20%);
    }
  }

  .chat-sidebar-titles {
    font-size: 1.2rem;
    font-weight: 300;
    margin-top: .5rem;
    margin-bottom: 1rem;
    color: $white;
  }
}

.chat-sidebar-item:hover {
  background: $accentsColor;

}