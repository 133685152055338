@import "../../../Utils/mixins.scss";
@import "../../../Utils/colors.scss";

.features {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5rem);
  background: transparentize($darkerBackground, .5);

  &-container {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &-wrapper {
    overflow: hidden;
    position: relative;
  }

  &-feature {
    width: 80%;
    min-width: 50rem;
  }

  &-aiExpert {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    color: $white;
    justify-content: center;
    align-items: center;
    margin-bottom: 15rem;

    &-description {
      width: 30%;
      min-width: 20rem;
      font-size: 1.2rem;

      h2 {
        margin-bottom: 1rem;
      }
    }

    &-funTool {
      display: flex;
      flex-direction: row;
      width: 90%;
      min-height: 22rem;
      padding: 1rem;
      @include glass;
      border: $thinBorder;
      border-radius: $mediumRadius;
      gap: 1rem;


      &-left {
        flex: 3;
        font-size: 1rem;
        position: relative;
        border: $thinBorder;
        border-radius: $mediumRadius;
        @include glass;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
          text-align: center;
          margin-bottom: 1rem;
          @include gradientText(60deg, $roseGold, $pink);
          font-size: 2rem;
        }

        p {
          margin: 0.5rem;
        }

        &-buttons {
          position: absolute;
          bottom: 1rem;
          display: flex;
          flex-direction: row;
          width: 100%;
          align-items: center;
          justify-content: center;
          gap: 3rem;

          &-next {
            @include button($gold);
            font-size: 1.5rem;
          }

          &-prev {
            @include button($red);
            font-size: 1.5rem;
          }
        }
      }

      &-right {
        flex: 7;
        margin-left: 1rem;
        height: 100%;
        position: relative;
        @include glass;
        border: $thinBorder;
        border-radius: $mediumRadius;

        &-experts {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100%;

          .agentCard {
            border-radius: $mediumRadius;
            width: calc(100% - 2rem);
          }

          * {
            pointer-events: none;
          }
        }

        &-mockChat {
          height: 100%;
          overflow: scroll;
          display: flex;
          flex-direction: column;

          .message-innerWrapper {
            padding-bottom: 0;
          }

          &-input {
            width: 80%;
            left: calc(10% - 1rem);
            position: absolute;
            border: $thinBorder;
            border-radius: 1rem;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            bottom: 1rem;
            background: $darkBackground;
            padding: 1rem;
            font-size: 1rem;

            p {
              width: 90%;
            }

            svg {
              font-size: 1.3rem;
              color: $gold;
            }
          }
        }
      }
    }
  }

  &-multiExpert {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 15rem;

    &-text {
      width: 30%;
      min-width: 20rem;
      font-size: 1.2rem;
      color: $white;

      h2 {
        margin-bottom: 1rem;
      }
    }

    &-right {
      display: flex;
      flex-direction: column;
      width: 65%;
      padding: 1rem;
      border-radius: $mediumRadius;
      min-height: 30rem;
      height: auto;

      &-prompt {
        width: 100%;
        @include glass;
        border-radius: $mediumRadius;
        border: $thinBorder;
        margin-bottom: 2rem;

        * {
          pointer-events: none;
        }
      }

      &-responses {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-bottom: 3rem;
        justify-content: space-between;

        &-response {
          width: 49%;
          @include glass;
          border-radius: 1.5rem;
          border: $thinBorder;
          transition: opacity 0.1s ease, left 0.5s ease, transform 0.5s ease 0.5s;
          transition: .3s;
          opacity: 1;

          &-0 {
            left: 0;
          }

          &-1 {
            left: 51%;
          }

          &.selected {
            transform: scale(1.05);
            left: 22.5%;
            opacity: 1;
          }

          &.fade {
            opacity: 0 !important;
          }

          * {
            pointer-events: none;
          }
        }

        :hover {
          border-color: transparentize($color: $pink, $amount: 0.5);
        }
      }

    }

    @include tablets {
      .features-multiExpert-right {
        width: 100%;
        align-items: center;

        &-prompt {
          width: 80%;
        }

        &-responses {
          display: flex;
          flex-direction: column;
          width: 80%;
          align-items: center;
          gap: 2rem;

          &-response {
            width: 100%;
          }
        }
      }
    }
  }

  &-customProfile {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    margin-bottom: 15rem;


    &-rightSide {
      width: 60%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      &-profile {
        color: $white;
        width: 60%;
        @include glass;
        border: $thinBorder;
        padding: 2rem;
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        position: relative;

        &-click {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $pink;
          animation: dropShadow 3s infinite;
          color: $white;
          border-top-right-radius: 50%;
          border-top-left-radius: 50%;
          border-bottom-right-radius: 50%;
          left: calc(100% - 2rem);
          bottom: calc(100% - 2rem);
          border: 1px solid $white;
          padding: 0.5rem;

          @keyframes dropShadow {
            0% {
              box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
            }

            50% {
              box-shadow: 0 0 20px 10px rgba(255, 255, 255, 0);
            }

            100% {
              box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
            }

          }

          &-icon {
            font-size: 1.5rem;
            rotate: -225deg;
            margin-left: -0.25rem;
            margin-bottom: -0.25rem;
          }
        }

        &:hover {
          border-color: transparentize($color: $white, $amount: 0.7);
        }

        &-header {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 1rem;
          margin-bottom: 1rem;

          &-icon {
            font-size: 2rem;
            color: $pink;
          }
        }

        &-textContainer {
          padding: 1rem;
          border: $thinBorder;
          border-radius: 1rem;
          margin-top: 1rem;
          margin-bottom: 1rem;
          font-size: 1.2rem;
        }
      }

      &-responses {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        gap: 3rem;

        &-response {
          width: 40%;
          @include glass;
          padding: 0 1rem;
          border: $thinBorder;
          border-radius: 1rem;
          transition: .3s;
          position: relative;

          &-profile {
            position: absolute;
            margin-bottom: -1.5rem;
            display: flex;
            flex-direction: row;
            color: $white;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            width: 40%;
            background: $pink;
            top: -1rem;
            left: 30%;
            height: 2rem;
            border-radius: $smallRadius;

            h2 {
              font-size: 1.2rem;
            }
          }

          * {
            pointer-events: none;
          }
        }

        :hover {
          border: 1px solid transparentize($color: $white, $amount: 0.7)
        }
      }
    }

    @media(max-width: 900px) {
      .features-customProfile-rightSide-profile {
        width: 55%;
      }

      .features-customProfile-rightSide-responses {
        flex-direction: column;
        width: 100%;
        align-items: center;
      }

      .features-customProfile-rightSide-responses-response {
        width: 60%;
      }
    }
  }

  &-documents {
    display: flex;
    flex-direction: row;
    margin-top: 5rem;
    margin-bottom: 15rem;
    width: 90%;
    justify-content: center;
    align-items: center;

    &-leftSide {
      width: 25%;
      color: $white;
      padding-right: 2%;
      font-size: 1.2rem;

      h2 {
        margin-bottom: 1rem;
      }
    }

    &-rightSide {
      width: 70%;
      display: flex;
      align-items: center;
      justify-content: center;

      &-upload {
        width: 30%;
        height: 80%;
        color: $white;
        font-size: 3rem;
        @include glass;
        padding: 2rem;
        border: $thinBorder;
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &-loading {
          animation: spin 1s linear infinite;
          margin-bottom: 1.5rem;
        }

        &-content {
          width: 80%;
          display: flex;
          justify-content: space-between;

          &-dot {
            width: 0.3rem;
            height: 0.3rem;
            border: 1px solid $white;
            border-radius: 50%;
            margin-bottom: -1.25rem;
            margin-top: 1.5rem;
            transition: background-color 0.3s;

            &-filled {
              background-color: $white;
            }
          }
        }
      }

      &-responses {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        min-height: 50vh;

        &-response {
          @include glass;
          border: $thinBorder;
          padding: 1rem;
          width: 45%;
          border-radius: 1rem;

          .message-innerWrapper,
          .message {
            padding-bottom: 0;
            margin-top: 0;
          }
        }
      }
    }
  }

  @media (max-width: 800px) {
    .features-documents-rightSide-responses {
      flex-direction: column;
      align-items: center;

      &-response {
        width: 55%;
        margin-bottom: 1rem;
      }
    }
  }

  &-summarizers {
    display: flex;
    flex-direction: row;
    margin-bottom: 10rem;
    min-height: 30rem;
    justify-content: center;
    align-items: center;

    &-funtool {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &-prompt {
        width: 100%;
        @include glass;
        padding: 1rem;
        border: $thinBorder;
        margin-bottom: 3rem;
        border-radius: 1rem;
      }

      &-responses {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;

        &-response {
          width: 27.5%;
          @include glass;
          border: $thinBorder;
          padding: 1rem;
          border-radius: 1rem;
        }
      }

      &-button {
        @include buttonAlt($pink);
        margin-top: 3rem;
      }
    }

    &-loadingWrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &-loading {
        pointer-events: none;
        padding-top: 2rem;
        padding-bottom: 2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        @include glass;
        border: $thinBorder;
        border-radius: 1rem;
        display: flex;
        flex-direction: row;
        gap: 2rem;

        transition: width 0.5s ease, height 0.5s ease;

        &-icon {
          color: $white;
          font-size: 3rem;
          margin-right: 2rem;
          animation: spin linear 1s infinite;
        }

        &-notLoaded {
          width: 40%;
          height: 20%;
          align-items: center;
          justify-content: center;
        }

        &-loaded {
          width: 80%;
          min-height: 70%;
          align-items: flex-start;
          justify-content: flex-start;

          * {
            width: 100%;
          }
        }
      }
    }

    @media (max-width: 800px) {
      &-funtool {
        max-width: 60%;
      }

      &-funtool-prompt {
        max-width: 60%;
      }

      &-funtool-responses {
        flex-direction: column;
        align-items: center;
      }

      &-funtool-responses-response {
        width: 60%;
        margin-bottom: 1rem;
      }

      &-loadingWrapper-loading {
        max-width: 40%;
        width: 40%;
      }
    }
  }

  &-knowledgebase {
    margin-top: 5rem;
    min-height: 40rem;
    height: unset;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    &-container {
      width: 40%;
      height: 100%;
      min-height: 40rem;
      @include glass;
      border: $thinBorder;
      border-radius: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $white;
      gap: 1rem;

      &-title {
        font-size: 2.0rem;
        margin-top: 2rem;
        color: $pink;
      }

      &-description {
        font-size: 1.1rem;
        margin-top: .7rem;
        width: 90%;
        text-align: center;
        height: 30%;
      }

      &-animation {
        margin-top: 2rem;
        height: 100%;
        min-height: 22rem;
        width: calc(100% - 1rem);
        margin-bottom: .5rem;
        border-radius: 1rem;
        @include glass;
        border: $thinBorder;
        left: 5%;
        display: flex;
        justify-content: center;
        align-items: center;

        &-line {
          width: 90%;
          display: flex;
          flex-direction: row;
          justify-content: space-around;

          &-dot {
            width: 0.8rem;
            height: 0.8rem;
            border-radius: 50%;
            border: 0.2rem solid $white;
            margin-top: 1.3rem;
            animation: dotAnimation 4s infinite;
          }

          &-dot:nth-child(1) {
            animation-delay: 0s;
          }

          &-dot:nth-child(2) {
            animation-delay: 0.5s;
          }

          &-dot:nth-child(3) {
            animation-delay: 1s;
          }

          &-dot:nth-child(4) {
            animation-delay: 1.5s;
          }

          &-dot:nth-child(5) {
            animation-delay: 2s;
          }

          &-dot:nth-child(6) {
            animation-delay: 2.5s;
          }

          &-dot:nth-child(7) {
            animation-delay: 3s;
          }

          &-dot:nth-child(8) {
            animation-delay: 3.5s;
          }

          &-appi {
            width: 4rem;
          }

          &-icon {
            font-size: 4rem;
          }
        }

        &-orbiting {
          width: 100%;
          height: 15rem;
          position: relative;
        }

        &-center-div {
          font-size: 6rem;
          position: absolute;
          color: $white;
          top: calc(47.5% - 1.5rem);
          left: calc(47.5% - 1.5rem);
          z-index: 1;
        }

        &-orbiting-div {
          font-size: 3rem;
          position: absolute;
          color: $white;
          top: 47.5%;
          left: 47.5%;
          transform-origin: 50% 50%;
          animation: orbit 5s linear infinite;
        }
      }
    }

    @media (max-width: 800px) {
      flex-direction: column;
      align-items: center;
      gap: 2rem;

      &-container {
        width: 50%;
      }
    }
  }
}

.summarizer-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  width: 100%;

  &-title {
    width: 100%;
  }
}

#selectedExpert {
  // NOTE: for AI Expert funtool (first element)
  border-width: 2px;
  border-color: transparentize($color: $pink, $amount: 0.5)
}

.no-hover {
  pointer-events: none;
}

@keyframes spin {

  // loading animation
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


@keyframes orbit {
  0% {
    transform: rotate(0deg) translate(100px) rotate(0deg);
  }

  100% {
    transform: rotate(360deg) translate(100px) rotate(-360deg);
    /* Counter-rotation */
  }
}

@keyframes dotAnimation {

  0%,
  50%,
  100% {
    background-color: rgba($white, 0);
  }

  25% {
    background-color: $white
  }
}


