@import "../../../../../Utils/mixins";

.meetAppiPlatform {
  transition: .3s;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;

  * {
    transition: .5s;
  }

  &-sideImage {
    height: 4rem;
    width: 4rem;
    padding: 1rem;
    border: $thinBorder;
    border-radius: $smallRadius;
    background-color: transparentize($darkBackground, .5);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 80%;
      width: 80%;
    }

    svg {
      height: 80%;
      width: 80%;
      color: $dirtyWhite;
    }

    p {
      position: absolute;
      bottom: -1.8rem;
      color: $grey;
      font-family: $header-font-family;
      font-weight: 400;
      font-size: 1rem;
    }
  }


  &-mainImage {
    height: 5rem;
    padding: 1rem;
    border-radius: $smallRadius;
    background: linear-gradient(145deg, $gold, $pink);
    z-index: 2;
  }
}

.meetAppiPlatform-hovered {
  width: 60%;

  p {
    opacity: 0;
  }

  .meetAppiPlatform {
    &-mainImage {
      height: 6rem;
    }

  }
}

@include tablets {
  .meetAppiPlatform {
    transform: scale(.6);

    p {
      font-size: 1.5rem;
      bottom: -2rem;

    }
  }
}