@import "../../../../Utils/mixins.scss";

.documents {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &-header {
    font-family: $header-font-family, sans-serif;
    font-size: 1.5rem;
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 2rem;
    padding-bottom: .5rem;
    color: $white;
    border-bottom: 1px solid transparentize($white, 0.9);
  }

  &-upload {
    font-family: $header-font-family, sans-serif;
    font-size: 1rem;
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    padding: .4rem;
    cursor: pointer;
    transition: .3s;
    color: $gold;
    border: 1px solid $gold;
    border-radius: 5px;
    background-color: $darkerBackground;

    &:hover {
      color: $darkerBackground;
      background-color: $gold;
    }
  }

  &-title {
    font-family: $header-font-family, sans-serif;
    font-size: 2rem;
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gold;
  }

  &-content {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &-list {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    flex: 4;
    height: calc(100% - 1rem);
  }

  &-viewer {
    padding-top: 1rem;
    margin-left: 2rem;
    flex: 5;
    height: calc(100% - 1rem);
    padding-left: 2rem;
    border-left: 1px solid transparentize($white, 0.9);
    position: relative;

    &-title {
      font-family: $header-font-family, sans-serif;
      font-size: 1rem;
      font-weight: 300;
      color: $white;
      margin-bottom: 1rem;
    }

    &-name {
      font-family: $header-font-family, sans-serif;
      font-size: 1.5rem;
      font-weight: 500;
      color: $gold;
      margin-bottom: 1rem;
    }

    &-item {
      display: grid;
      grid-template-columns: .5fr 7fr 25fr;
      width: 100%;
      align-items: flex-start;
      margin: .5rem 0;

      &-icon {
        margin-top: .2rem;
        font-size: 1rem;
        color: transparentize($white, 0.5);
      }

      &-title {
        font-family: $header-font-family, sans-serif;
        font-size: 1rem;
        font-weight: 300;
        margin-left: .5rem;
        color: transparentize($white, 0.5);
      }

      &-value {
        font-family: $header-font-family, sans-serif;
        font-size: 1rem;
        font-weight: 300;
        margin-left: .5rem;
        color: $white;

      }
    }

    &-delete {
      @include button($red);
      position: absolute;
      bottom: 1rem;
      right: 0;
    }
  }
}

.document {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 1px solid transparentize($white, 0.9);
  transition: .3s;
  margin: 0.5rem 0;

  &-selected {
    border: 1px solid $gold;
  }

  &-title {
    padding: 1rem 1.2rem;
    font-family: $header-font-family, sans-serif;
    font-size: 1rem;
    font-weight: 300;
    max-width: 50%;
    text-align: left;
    word-break: break-word;
    color: $white;
  }

  &-date {
    padding: 1rem 1.2rem;
    font-family: $header-font-family, sans-serif;
    font-size: 1rem;
    font-weight: 300;
    width: 50%;
    text-align: right;
    color: $white;
  }

  &:hover {
    border: 1px solid $gold;
    cursor: pointer;
  }
}