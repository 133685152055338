@import "../../../../Utils/mixins.scss";

.staticCards {
  @include defaultPage;
  min-height: unset;


  &-itemsWrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
  }

  &-item {
    height: 30rem;
    flex: 1;
    max-width: 25rem;
    margin: 1.5rem;
    @include glass;
    border-radius: $mediumRadius;
    border: $thinBorder;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: .3s;


    &-icon {
      margin-top: 2rem;

      svg {
        width: 2.3rem;
        height: 2.3rem;
        background: linear-gradient(145deg, $pink, $roseGold);
        color: $white;
        border-radius: 25%;
        padding: .7rem;
        margin-top: 1rem;
      }
    }

    h1 {
      font-family: $header-font-family, sans-serif;
      font-size: 1.8rem;
      font-weight: 400;
      margin-top: 1rem;
      color: $white;
      padding-bottom: 1rem;
      border-bottom: $thinBorder;
      width: 85%;
      text-align: center;
    }

    p {
      font-family: $title-font-family, sans-serif;
      font-size: 1rem;
      font-weight: 400;
      margin-top: 1rem;
      color: $grey;
      width: 80%;
    }

    &:hover {
      border-color: transparentize($white, .7);
    }
  }

  #mainCard {
    transform: scale(1.1);
  }
}

@include tablets {
  .staticCards {
    &-itemsWrapper {
      flex-direction: column;
    }

    &-item {
      height: unset;

      h1 {
        font-size: 1.5rem;
      }

      p {
        font-size: .9rem;
        padding-bottom: 2rem;
      }
    }
  }
}