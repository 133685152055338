@import "../../../../Utils/mixins";

.blobMain {
  position: absolute;
  width: 20vh;
  height: 20vh;
  user-select: none;
  z-index: 1;
  overflow: hidden;

}

.blob1 {
  top: 70vh;
  left: 15vh;
  transform: scale(3) rotate(-45deg);
}

.blob2 {
  top: -20vh;
  left: -5vh;
  transform: scale(5) rotate(75deg);
}

.blob3 {
  top: -25vh;
  right: 30vh;
  transform: scale(9.5) rotate(60deg);
}

.blob4 {
  top: 180vh;
  right: 20vh;
  transform: scale(7) rotate(90deg);
}

.blob5 {
  top: 250vh;
  left: 10vh;
  transform: scale(4) rotate(45deg);
}

.blob6 {
  top: 340vh;
  right: 20vh;
  transform: scale(4) rotate(45deg);
}

.blob7 {
  top: 430vh;
  left: -10vh;
  transform: scale(4) rotate(45deg);
}

.blob8 {
  top: 510vh;
  right: 20vh;
  transform: scale(6) rotate(45deg);
}

.blob9 {
  top: 600vh;
  left: 20vh;
  transform: scale(4) rotate(45deg);
}

