@import "../../../Utils/mixins";

.documentShowcase {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 90vh;
  width: 100%;

  &-wrapper {
    margin-top: 2rem;
    width: 100%;

    border-radius: $largeRadius;
    @include glass;
    border: $thinBorder;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    transition: .3s;

    * {
      transition: .3s;
    }
  }

  &-stage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: $mediumRadius;
    border: $thinBorder;
    height: 15%;

    &-title {
      font-family: $header-font-family, sans-serif;
      font-size: 2rem;
      font-weight: 500;
      color: $white;
    }

    &-description {
      font-family: $header-font-family, sans-serif;
      font-size: 1.2rem;
      font-weight: 400;
      color: $grey;
      margin-top: .5rem;
    }

  }

  &-content {
    width: 100%;
    height: 85%;

    &-generating {
      &-sidebar {
        width: 20%;
        height: 100%;
        display: flex;
        flex-direction: column;
        border-right: $thinBorder;
        padding: 0 1rem;
        background: none;


        #blob_1 {
          display: none;
        }

        h1,
        &-title {
          font-family: $header-font-family, sans-serif;
          font-size: 1.1rem;
          font-weight: 500;
          color: $white;
          margin-top: 1rem;
          margin-bottom: 1rem;
        }

        .agentCard {
          width: 100%;
          margin: 0;
          height: 5rem;
          min-height: unset;
          margin-bottom: 1rem;
          @include interactableHighlight($white, 2s);

          .categoriesSection {
            display: none;
          }

          &-title {
            font-size: .8rem;
          }

          &-author {
            font-size: .7rem;
          }

          &-logo {
            width: 1.5rem;
            height: 1.5rem;
            padding: .5rem;
          }

        }

        .agentCard#expert_selected {
          border-color: transparentize($pink, .2);
          box-shadow: none !important;
        }
      }
    }

  }

  @mixin document {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: calc(100% - 3rem);
    padding: 1rem;
    border-radius: $smallRadius;
    border: $thinBorder;
    cursor: pointer;
    @include interactableHighlight($white, 2s);

    svg {
      width: 10rem;
      height: 10rem;
      color: $white;


    }

    &:hover {
      background-color: $darkBackground;
    }

    &-title {
      font-family: $header-font-family, sans-serif;
      font-size: 1.2rem;
      font-weight: 500;
      color: $white;
      text-align: center;
      width: 90%;
    }

    &-icon {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      background-color: $orange;
    }
  }

  #stage_1.documentShowcase-wrapper {
    width: 50%;
    height: 50vh;

    .documentShowcase-stage {
      width: 100%;
      height: 25%;
    }

    .documentShowcase-content {
      width: 100%;
      height: 75%;
    }

    .documentShowcase-content-documents {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
      height: 100%;
      padding: 1rem 0;

      .documentShowcase-content-documents-document {
        @include document;
      }
    }
  }

  #stage_2.documentShowcase-wrapper,
  #stage_3.documentShowcase-wrapper,
  #stage_4.documentShowcase-wrapper {
    height: 85vh;
    width: 90%;

    .documentShowcase-stage {
      width: 100%;
    }

    .documentShowcase-content {
      width: 100%;
      height: 75%;

    }
  }

  #stage_2.documentShowcase-wrapper {
    height: 60vh;
    width: 60%;

    .documentShowcase-stage {
      height: 25%;
    }

    .documentShowcase-content {
      width: 100%;
      height: 75%;
      display: flex;
      justify-content: center;
      align-items: center;

      &-extracting {
        display: flex;
        align-items: center;
        height: 100%;
        width: 90%;
        justify-content: space-between;

        &-progress {
          position: relative;
          width: 20rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-bottom: 10rem;

        }

        &-checklist {
          position: absolute;
          top: calc(100% + 1rem);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: $smallRadius;
          padding: .5rem;
          width: 15rem;

          &-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: .5rem;
            border-top: $thinBorder;

            &-title {
              font-family: $header-font-family, sans-serif;
              font-size: 1rem;
              font-weight: 500;
              color: $white;
            }

            svg {
              width: 1rem;
              height: 1rem;
              color: $green;
            }

            svg#loading {
              color: $pink;
              animation: spin 1.5s linear infinite;
            }

          }
        }

        &-arrow {
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          background-color: $pink;
        }

        &-selectedDocument {
          @include document;
          width: 15rem;
          height: 20rem;
        }

        .agentCard {
          width: 25rem;
          margin: 0;
        }
      }
    }
  }

  #stage_3.documentShowcase-wrapper {

    .documentShowcase-stage {
      height: 15%;
    }

    .documentShowcase-content {
      height: 85%;
      margin-top: 1rem;

      &-generating {
        width: 100%;
        height: 100%;
        display: flex;
        border-radius: $mediumRadius;
        border: $thinBorder;

        &-main {
          width: 80%;
          min-height: 100%;
          border-radius: $smallRadius;
          display: flex;
          flex-direction: column;
          align-items: center;


          &-chat {
            width: 100%;
            height: 88%;
            border-radius: $smallRadius;
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow-x: auto;

            .message {
              width: 70%;

              &-innerWrapper {
                padding: 0;
              }
            }

            .message-text {
              margin-top: 0;
            }
          }

          &-inspector {
            width: 100%;
            height: 88%;
            border-radius: $smallRadius;
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow-x: auto;
            background: red;
          }

          &-input {
            position: sticky;
            bottom: 0;
            height: 12%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;

            input {
              @include input($white);
              border: none;
              justify-content: center;
              margin-bottom: .5rem;
              padding: .5rem 1rem;
              border-radius: $smallRadius;
              background: transparentize($darkBackground, .5);
              box-shadow: 0 0 .2rem .1rem transparentize($darkestBlack, .5);
              width: 70%;
              height: 1.8rem;
            }


          }
        }

        &-sidebar {
          width: 25%;
        }

        &-inspector {
          width: 0;
          border-left: $thinBorder;
          opacity: 0;
          padding: 0 1rem;
          display: flex;
          flex-direction: column;
          position: relative;

          * {
            display: none;
          }

          &-close {
            margin-top: 1rem;
            position: absolute;
            right: .5rem;
            top: -.5rem;
            width: 1.5rem;
            height: 1.5rem;
            color: $white;
            cursor: pointer;
            padding: .5rem;

            &:hover {
              color: $pink;
            }
          }

          h1 {
            font-family: $header-font-family, sans-serif;
            font-size: 1.1rem;
            font-weight: 500;
            color: $white;
            margin-top: 1rem;
            margin-bottom: 1rem;
          }

          &-fragment {
            margin: .5rem 0;
            padding: .8rem;
            border-radius: $smallRadius;
            border: $thinBorder;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: $grey;
            cursor: pointer;
            @include interactableHighlight($white, 2s);

            &:hover {
              background-color: transparentize($darkBackground, .8);
              border-color: transparentize($white, .7);
            }

            &-image,
            &-table,
            &-formula {
              display: flex !important;
              align-items: center;

              svg {
                margin-right: .5rem;
              }
            }
          }

          &-document {
            height: calc(100% - 4.5rem);
            width: 100%;
            background: $white;
            border-radius: $mediumRadius;

            &-selected {
              background: transparentize($pink, .8);
              margin: .5rem 1rem;
              border-radius: $smallRadius;

              table {
                width: 100%;
                border-collapse: collapse;
                border-spacing: 0;
                margin-bottom: 1rem;
                border: 1px solid $darkerBackground;

                tr {
                  border-bottom: $thinBorder;
                  display: flex !important;
                }

                th,
                td {
                  flex: 1;
                  height: 1rem;
                  padding: .5rem;
                  text-align: left;
                  border-bottom: 1px solid transparentize($darkerBackground, .5);
                  border-left: 1px solid transparentize($darkerBackground, .5);
                }

                th {
                  background: transparentize($roseGold, .5);
                }
              }
            }

            p {
              padding: 1rem;
              filter: blur(.2rem);
              user-select: none;
            }

            h2 {
              padding: 1rem;
              font-size: 1.2rem;
            }
          }
        }

        &-inspector#focus {
          width: 60%;
        }

        .active {
          width: 40%;
          opacity: 1;

          * {
            display: block;
          }
        }
      }
    }
  }

}
