@import "../../../Utils/mixins";

@mixin box($c) {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 25rem;
  height: 7rem;
  cursor: pointer;
  background: $darkBackground;
  margin: .1rem;
  box-shadow: 0 0 1rem .1rem rgba(0, 0, 0, 0.5);
  transition: .3s;


  .chat-sidebar-modal-boxes-box-icon {
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8%;
    height: auto;
    color: $c;
  }

  .chat-sidebar-modal-boxes-box-title {
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 20%;
    margin-top: .5rem;
    text-align: center;
    color: $c;
  }

  &:hover {
    background: transparentize($c, .8);
    border-right: .1rem solid $c;
    border-left: .1rem solid $c;
  }
}


@include tablets {
  .chat-sidebar {
    z-index: 3;
  }
  .chat-sidebar-icon {
    position: fixed;
    z-index: 5;
    top: 1.1rem;
    left: 1rem;
    width: auto;
    height: 2rem;
    cursor: pointer;
    color: $white;

    &-back {
      left: 4rem;
    }
  }
  .chat-sidebar-modal-wrapper {
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
  }
  .chat-sidebar-modal {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 85%;
    height: 100%;
    border-right: .1rem solid $gold;
    background: $darkerBackground;
    z-index: 3;

    &-boxes {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      width: 90%;

    }

    #box1 {
      @include box($white);
    }

    #box2 {
      @include box($gold);
    }

    #box3 {
      @include box($pink);
    }

    #box4 {
      @include box($roseGold);
    }

    &-history {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100vh;
      width: 100%;
      margin-top: 15vh;
      .chat-sidebar-modal-boxes-box {
        width: 90% !important;
        max-width: unset !important;
      }

      .chat-sidebar-titles {
        display: none;
      }

      .chat-sidebar-history-footer {
      }
    }

    &-agents, &-summarizers {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100vh;
      width: 100%;
      margin-top: 15vh;
    }

    &-CustomProfiles {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100vh;
      width: 100%;
      margin-top: 15vh;

      &-new {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85%;
        height: 2.5rem;
        cursor: pointer;
        transition: .3s;
        border-left: .15rem solid $gold;
        border-right: .15rem solid $gold;
        background: $darkBackground;
        box-shadow: 0 0 1rem .1rem rgba(0, 0, 0, 0.2);
        margin: 1rem 0;

        &-icon {
          width: auto;
          height: 1.2rem;
          transition: .3s;
          color: $gold;
        }

        h1 {
          font-family: $header-font-family, sans-serif;
          font-size: 1rem;
          font-weight: 400;
          margin-left: 1rem;
          color: $gold;
        }

        &:hover {
          border-color: $darkerBackground;
          background: transparentize($gold, 0.2);

          .chat-sidebar-modal-CustomProfiles-new-icon {
            color: $darkerBackground;
          }

          h1 {
            font-weight: 500;
            color: $darkerBackground;
          }
        }
      }
    }

  }
}

