@import "../../../Utils/mixins.scss";

@include smallLaptops {
  .devProfile {

    &-wrapper {
      width: 100%;
      height: auto;
    }

    &-info {
      margin: 0;
      padding-top: 5rem;
      border-left: none;
      width: 100%;
      background: $darkerBackground;
    }

    &-agents {
      width: 100%;
      border-top: 1px solid $gold;
      height: auto;
      flex-direction: column;
      border-right: none;

      &-experts-wrapper, &-summarizers-wrapper {
        width: 100%;
      }

      &-experts, &-summarizers {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}