@import "../../../Utils/mixins";

@include tablets {
  .chat-mainArea {
    width: 100%;
    margin: 0;

    &-chat {
    }

  }
}