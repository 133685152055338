@import "../../../../../Utils/mixins";

// Mask animations
@keyframes revealHorizontal {
  from {
    width: 0;
    background-color: $gold;
  }
  to {
    width: var(--target-width);
    background-color: transparentize($pink, .2);
  }
}

@keyframes revealVertical {
  from {
    height: 0;
    background-color: $white;
  }
  to {
    height: var(--target-height);
    background-color: transparentize($pink, .2);
  }
}

@keyframes getBackground {
  from {
    background-color: transparentize($gold, 1);
  }
  to {
    background-color: $gold;
  }
}

@keyframes getBackgroundToGreen {
  0% {
    background-color: transparentize($gold, 1);
  }
  25%, 90% {
    background-color: $gold;
  }
  100% {
    background-color: $green;
  }
}

@keyframes getBackgroundToRed {
  0% {
    background-color: transparentize($gold, 1);
  }
  25%, 90% {
    background-color: $gold;
  }
  100% {
    background-color: $red;
  }
}

.documentPipelines {
  transition: .3s;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 90%;
  width: 90%;
  position: relative;
  flex-direction: column;
  cursor: pointer;

  &-node {
    border-radius: $smallRadius;
    height: 2rem;
    width: 2rem;
    padding: .5rem;
    position: absolute;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    border: $thinBorder;
    background-color: transparentize($gold, 1);

    svg {
      width: 100%;
      height: 100%;
      color: $white;
    }
  }


  #node1 {
    left: 0;
    background-color: $roseGold;
  }

  #node2 {
    left: 25%;
  }

  #node3 {
    top: 0;
  }

  #node5 {
    bottom: 0;
  }

  #node6 {
    right: 25%;
  }

  #node7 {
    right: 0;
    background-color: $pink;
  }

  &-line {
    position: absolute;
    z-index: 1;
  }

  #line12 {
    --target-width: 25%;
    top: calc(50% - 1.5px);
    left: 2rem;
    height: 3px;
    width: var(--target-width);
  }

  #line23_1 {
    --target-height: 43%;
    bottom: 48%;
    left: 27%;
    height: var(--target-height);
    width: 3px;
  }

  #line23_2 {
    --target-width: 23%;
    top: 1.3rem;
    left: 27%;
    height: 3px;
    width: var(--target-width);
  }

  #line24 {
    --target-width: 23%;
    top: calc(50% - 1.5px);
    left: 27%;
    height: 3px;
    width: var(--target-width);
  }

  #line25_1 {
    --target-height: 41%;
    top: 50%;
    left: 27%;
    height: var(--target-height);
    width: 3px;
  }

  #line25_2 {
    --target-width: 23%;
    bottom: 1.3rem;
    left: 27%;
    height: 3px;
    width: var(--target-width);
  }

  #line36_2 {
    --target-height: 36%;
    top: 9%;
    right: 27%;
    height: var(--target-height);
    width: 3px;
  }

  #line36_1 {
    --target-width: 23%;
    top: 1.3rem;
    left: 50%;
    height: 3px;
    width: var(--target-width);
  }

  #line46 {
    --target-width: 23%;
    top: calc(50% - 1.5px);
    left: 50%;
    height: 3px;
    width: var(--target-width);
  }

  #line56_1 {
    --target-height: 36%;
    bottom: 9%;
    right: 27%;
    height: var(--target-height);
    width: 3px;
  }

  #line56_2 {
    --target-width: 23%;
    bottom: 1.3rem;
    left: 50%;
    height: 3px;
    width: var(--target-width);
  }

  #line67 {
    --target-width: 25%;
    top: calc(50% - 1.5px);
    left: 72%;
    height: 3px;
    width: var(--target-width);
  }

  $speed: .3s;
  $delay: 2s;

  &-hovered {
    #node2 {
      animation: getBackground $speed ease forwards;
      animation-delay: $speed;
    }

    #node3 {
      animation: getBackgroundToGreen 4*$speed ease forwards;
      animation-delay: 3*$speed;
    }

    #node4 {
      animation: getBackgroundToGreen 4*$speed ease forwards;
      animation-delay: 2*$speed;
    }

    #node5 {
      animation: getBackgroundToRed 4*$speed ease forwards;
      animation-delay: 3*$speed;
    }

    #node6 {
      animation: getBackground $speed ease forwards;
      animation-delay: 3*$speed + $delay;
    }

    #line12 {
      animation: revealHorizontal $speed ease forwards;
    }

    #line23_1 {
      animation: revealVertical $speed ease forwards;
      transform-origin: bottom; /* Reverse from bottom to top */
      animation-delay: $speed;
    }

    #line23_2 {
      animation: revealHorizontal $speed ease forwards;
      animation-delay: 2*$speed; // Reveal after line23_1
    }

    #line24 {
      animation: revealHorizontal $speed ease forwards;
      animation-delay: $speed; // Reveal after line23_2
    }

    #line25_1 {
      animation: revealVertical $speed ease forwards;
      transform-origin: top; /* Normal top to bottom */
      animation-delay: $speed; // Reveal after line24
    }

    #line25_2 {
      animation: revealHorizontal $speed ease forwards;
      animation-delay: 2*$speed; // Reveal after line25_1
    }

    #line36_2 {
      animation: revealVertical $speed ease forwards;
      transform-origin: bottom; /* Reverse from bottom to top */
      animation-delay: 3*$speed + $delay; // Reveal after line25_2
    }

    #line36_1 {
      animation: revealHorizontal $speed ease forwards;
      animation-delay: 2*$speed + $delay; // Reveal after line36_2
    }

    #line46 {
      animation: revealHorizontal $speed ease forwards;
      animation-delay: 2*$speed + $delay; // Reveal after line36_1
    }

    #line67 {
      animation: revealHorizontal $speed ease forwards;
      animation-delay: 4*$speed + $delay; // Reveal after line56_2
    }
  }
}

@include phoneLandscape {
  .documentPipelines {
    &-node {
      height: 1.5rem;
      width: 1.5rem;

    }

    #node1, #line12 {
      display: none;
    }

    #node2 {
      left: 0;
    }

    #line23_1, #line23_2, #line24, #line25_1, #line25_2 {
      transform: translateX(-3rem);
    }

    #node3, #node4, #node5 {
      transform: translateX(-1.7rem);
    }

    #line36_2, #line36_1, #line46, #line56_1, #line56_2, #line67 {
      transform: translateX(-1rem);
    }
  }
}