@import "../../../../Utils/mixins.scss";

.staticUs {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 10rem;

  &-title {
    @include bigHeader;
    color: $white;
  }

  &-content {
    display: flex;
    width: 65%;
    justify-content: center;

    &-person {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;
      margin: 3rem;
      background: transparentize($darkestBlack, .5);
      border-radius: $smallRadius;
      padding: 1rem;
      box-shadow: 0 0 1rem .2rem rgba(0, 0, 0, .3);
      border: 1px solid transparentize($white, .8);


      &-flipped {
        transform: scaleX(-1);
      }
    }

    img {
      object-fit: cover;
      margin: 1rem;
      height: 25rem;
      width: calc(100% - 2rem);
      border-radius: $smallRadius;
      box-shadow: 0 0 1rem .2rem rgba(0, 0, 0, .3);

    }

    h1 {
      font-family: $header-font-family, sans-serif;
      font-size: 1.8rem;
      font-weight: 400;
      margin-top: 1rem;
      margin-bottom: 1rem;
      color: $white;
      text-align: center;
    }

    h2 {
      font-family: $header-font-family, sans-serif;
      font-size: 1.2rem;
      font-weight: 400;
      margin-bottom: 1rem;
      color: $pink;
    }

    p {
      font-family: $paragraph-font-family, sans-serif;
      font-size: 1rem;
      font-weight: 400;
      margin-top: 0;
      margin-bottom: 0;
      color: $white;
      text-align: center;
    }

    &-person-socials {
      border-top: 1px solid transparentize($white, .8);
      padding-top: 1rem;
      width: 90%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 2rem;

      &-link {
        color: $grey;
        font-size: 1.5rem;
        margin: 0 .3rem;
        transition: .3s;
        cursor: pointer;

        &:hover {
          color: $white;
        }
      }
    }
  }
}

@include tablets {
  .staticUs {
    &-content {
      &-person {
        margin: 1rem 0;
      }

      flex-direction: column;
      width: 75%;
    }
  }
}

@include phoneLandscape {
  .staticUs {
    &-content {
      flex-direction: column;
      width: 90%;
    }
  }
}
