@import "../../../Utils/mixins";

.contactWrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact {
  display: flex;
  width: 80%;

  &-title {
    @include bigHeader;
    color: $pink;
    font-size: 3rem;
    margin-right: 3rem;
  }

  &-input, &-textarea {
    all: unset;
    @include input($white);
    font-weight: 300;
    font-size: 1.2rem;
    font-family: $header-font-family;
    transition: all 0.3s ease-in-out;
    width: calc(100% - 2rem);
    z-index: 2;
    margin-top: .5rem;
    margin-bottom: 1rem;

    &:focus {
      outline: none;
      border: 1px solid transparentize($white, 0.6);
    }
  }

  &-textarea {
    all: unset;
    resize: none;
    height: 10rem;
    @include input($white);
    font-size: 1.2rem;
    margin-top: 1rem;
    width: calc(100% - 2rem);

    &:focus {
      outline: none;
      border: 1px solid transparentize($white, 0.6);
    }
  }

  &-button {
    all: unset;
    cursor: pointer;
    font-family: $header-font-family, sans-serif;
    font-size: 1.5rem;
    font-weight: 300;
    width: calc(100% - 2rem);
    padding: .5rem 1rem;
    text-align: center;
    white-space: pre-wrap;
    word-break: break-word;
    color: $white;
    border: .1rem solid transparentize($white, 0.9);
    box-shadow: 0 0 0 .1rem transparentize($darkestBlack, 0.9);
    transition: all 0.3s ease-in-out;
    margin-top: 1rem;

    &:hover {
      background-color: transparentize($white, 0.7);
    }
  }

}

.contact-right {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  z-index: 2;
  position: relative;

  form {
    width: 40rem;
    padding: 2rem 1.5rem;
    border-radius: 1rem;
    @include glass;

    label {
      color: $grey;
      font-size: 1rem;
      font-family: $header-font-family;
    }
  }

}

.contact-left {
  width: 50%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  margin-top: 5rem;

  ul {
    padding: 0;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 55%;

    li {
      margin: 1rem;
      color: $white;
      font-size: 1.2rem;
      font-family: $paragraph-font-family;
    }

  }

  button {
    @include ctaButton($pink);
    max-width: 15rem;
    text-align: center;
    padding: 1rem;
  }
}

.contact-right {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50%;
  //height: 100vh;


}

@include smallLaptops {
  .contact {
    flex-direction: column;
    width: 90%;
  }

  .contact-left {
    width: 100%;
    margin-top: 0;
    align-items: center;
    background: red;
    display: none;

    h1 {
      text-align: center;
      font-size: 2rem;
    }

    ul {
      width: unset;
      align-items: center;

      li {
        margin: 1rem;
        font-size: 1.5rem;
        text-align: center;
      }
    }
  }

  .contact-right {
    width: unset;
    margin-top: 2rem;
    margin-left: 0;
  }

  .contact-right form {
    width: 100%;
  }

  .contact-left ul {
    width: 100%;
  }
}