@import "../../../../../Utils/mixins";

.conversationStarter {
  display: flex;
  flex-direction: column;
}

.chat-mainArea-chat-config {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-self: center;
  justify-content: center;
  width: 60%;
  min-width: 50rem;

  &-item {
    position: relative;
    display: flex;
    align-items: center;
    margin: .3rem;
    justify-content: flex-start;
    height: 3rem;
    cursor: pointer;
    //border: 0.1rem solid transparentize($white, 0.9);
    box-shadow: 0 0 .2rem .1rem transparentize($darkestBlack, .5);
    transition: .3s;
    background-color: transparentize($darkBackground, .5);
    border-radius: .8rem;
    width: 100%;

    &-title, h1 {
      font-size: .8rem;
      color: transparentize($white, 0.2);
      font-family: $header-font-family;
      font-weight: 600;
      transition: .3s;
    }

    &-title {
      width: 8rem;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 0.1rem solid transparentize($white, 0.95);
      background-color: transparentize($darkestBlack, .5);
      border-radius: .8rem 0 0 .8rem;
    }

    &-suggestion, &-selected, &-seeAll {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 100%;
      transition: .3s;
      flex: 1;

    }

    &-selected {
      background-color: transparentize($pink, 0.75);
    }

    &-suggestion {
      &-hide {
        opacity: 0;
        pointer-events: none;
      }


      &:hover {
        h1 {
          color: $roseGold;
        }
      }
    }

    &-seeAll {
      border-left: 0.1rem solid transparentize($white, 0.95);

      &:hover {
        background-color: transparentize($roseGold, 0.2);
      }
    }

    &-open {
      background-color: $darkerBackground;
    }

    &-modal {
      position: absolute;
      top: 100%;
      right: -0.2rem;
      min-height: 10rem;
      max-height: 20rem;
      border-radius: 0 .5rem .5rem .5rem;
      overflow: auto;
      display: flex;
      width: calc(100% - 8rem);
      cursor: pointer;
      background-color: $darkerBackground;
      box-shadow: 0 .5rem .5rem rgba(0, 0, 0, .25);
      z-index: 3;
      flex-wrap: wrap;
      align-content: flex-start;

      &-suggestion, &-selected {
        padding: 0.5rem 0;
        width: 14rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;

      }

      &-selected {
        background-color: transparentize($pink, 0.65);
      }

      &-suggestion {
        &:hover {
          background-color: transparentize($roseGold, 0.55);
        }
      }

      &-category {
        width: 30%;

        &-name {
          display: flex;
          border-bottom: 0.1rem solid transparentize($white, 0.95);
          font-size: 1rem !important;
          padding-left: 1rem;
          padding-top: 1rem;
          padding-bottom: 1rem;
        }

        .chat-mainArea-chat-config-item-modal-suggestion,
        .chat-mainArea-chat-config-item-modal-selected {
          height: 1.5rem;
          display: flex;
          justify-content: flex-start !important;
          padding-left: 1rem;
          width: 100%;

        }

      }

      #selected {
        border-right: 0.2rem solid $gold;
        border-left: 0.2rem solid $gold;
        background: transparentize($gold, 0.9);
      }
    }
  }

  &-experts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;

    .agentCard {
      margin: 1rem;
      min-height: unset;
      width: 12rem;
      height: 8rem;
      flex-direction: column;
      background: $darkBackground;

      &-logo {
        margin: .8rem;
        padding: 0;
        width: 1.5rem;
        height: 1.5rem;
        background: none;
      }

      &-content {
        margin-top: .3rem;
        width: 90%;
        margin-left: 5%;
      }

      &-title {
        font-size: 1rem;
      }

      &-author, #blob_1, &-lock {
        display: none;
      }

      .categoriesSection {
        display: none;
      }
    }
  }

  #agent_selected {
    border-color: transparentize($pink, .5);
    background-color: transparentize($pink, 0.75);
  }

}
