@import '../../../Utils/mixins.scss';

@include smallLaptops {
  .sender {
    width: 50rem;
  }
}

@include tablets {
  .sender {
    width: 95%;
  }
}

@include phoneLandscape {
  .sender {
    box-shadow: unset;
    flex-direction: column-reverse;
    height: unset;
    width: 100%;

    .social, .formWrapper {
      width: 100%;
      padding: 2rem 0;
    }
  }
}