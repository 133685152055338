@import "../../Utils/mixins.scss";

.userDashboard {
  @include defaultPage;
  position: relative;
  overflow: hidden;
  align-items: flex-start;
  flex-direction: row;

  &-loading {
    align-items: center;
    justify-content: center;

    &-icon {
      width: 2rem;
      height: 2rem;
      animation: spin 1s linear infinite;
      color: $gold;
    }
  }

  &-title {
    z-index: 6;
    display: flex;
    align-items: center;

    flex-direction: column;
    width: 100%;
    margin-top: 5rem;
    margin-bottom: 2rem;
    border-radius: 2vh;
    font-family: $header-font-family, sans-serif;
    font-size: 1.5rem;
    font-weight: 300;
    color: $white;

    img {
      height: 4rem;
      border-radius: $smallRadius;
    }

    h1 {
      font-family: $header-font-family, sans-serif;
      font-size: 1.5rem;
      font-weight: 300;
      margin-top: 1rem;
      color: $white;
    }

    &-img {
      width: 4rem;
      height: 4rem;
      border-radius: 1rem;
    }
  }

}


.userDashboard-content {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  width: calc(100% - 15rem);
  margin-top: 4rem;
}


.chartWrapper, .chartWrapperSmall {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  @include whiteGlassLight;
  margin: 1vh;
  border-radius: 1vh;

  h2 {
    font-family: 'Asap', sans-serif;
    font-size: 2.5vh;
    font-weight: 500;
    margin-top: 2vh;
    margin-bottom: 2vh;
    text-align: center;
  }
}

.chartWrapper {
  min-width: 60vh;
}

.chartWrapperSmall {
  width: 25%;

  .chart {
    width: 60%;
  }
}

#numberOfRequestsPerDay {
  width: 100%;
}

.plan {
  z-index: 6;
  display: flex;
  align-items: center;
  width: $defaultPageWidth;
  height: 40vh;
  @include whiteGlass;
  margin-top: 2vh;
  border-radius: 2vh;
}

.connections {
  z-index: 6;
  display: flex;
  align-items: center;
  width: $defaultPageWidth;
  height: 70vh;
  @include whiteGlass;
  margin-top: 2vh;
  border-radius: 2vh;

}
