@import "../../Utils/mixins.scss";

.monthSlider {
  display: flex;
  width: 15rem;
  justify-content: space-evenly;
  align-items: center;
  padding: 0.2rem 0.5rem;
  border-radius: $smallRadius;
  border: 1px solid transparentize($white, 0.7);
  background: transparentize($white, 0.95);

  h2 {
    font-family: $header-font-family, sans-serif;
    font-size: 1.2rem;
    font-weight: 300;
    color: $white;
    width: 10rem;
    text-align: center;
  }

  #button {
    height: 1.2rem;
    width: auto;
    color: $white;
    cursor: pointer;
    transition: .3s;
    padding: 0.2rem;

    &:hover {
      color: $roseGold;
    }
  }
}