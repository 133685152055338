@import "../../../../Utils/mixins.scss";

.overview-content-organizationAgents {
  display: flex;

  &-agents {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    justify-content: center;
    gap: 2rem;
    flex: 1;
    overflow-y: auto;
    align-content: flex-start;
    max-height: 100%;

    .agentCard {
      margin: 0;
      width: unset
    ;
    }
  }

}