@import "../../../Utils/mixins.scss";

.devProfile-wrapper {
  position: relative;
  display: flex;
  overflow: hidden;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  .blobDevProfile {
    position: absolute;
    width: 20vh;
    height: 20vh;
    user-select: none;
  }

  .blobDevProfile1 {
    top: 10vh;
    left: -15vh;
    transform: scale(2) rotate(30deg);
  }

  .blobDevProfile2 {
    top: 5vh;
    right: -5vh;
    transform: scale(1) rotate(90deg);
  }

  .blobDevProfile3 {
    top: 60vh;
    left: 10vw;
    transform: scale(2) rotate(60deg);
  }

}

.devProfile {
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5rem);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  span {
    width: 80%;
    height: 1px;
    margin: 1.5rem 0;
    background-color: transparentize($white, 0.7);
  }

  &-info {
    position: relative;
    width: 25%;
    min-width: 20rem;
    height: 30rem;
    background: $darkBackground;
    border-left: .1rem solid $gold;
    margin: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);

    &-img {
      width: 4rem;
      height: 4rem;
      padding: .5rem;
      color: $gold;
      border-radius: $smallRadius;
      background: $darkerBackground;
      box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
      margin-top: 3rem;
    }

    &-name {
      font-size: 1.5rem;
      font-weight: 300;
      color: white;
      margin-top: 1rem;
    }


    &-description {
      width: 80%;
      font-size: 1rem;
      font-weight: 300;
      color: white;
      text-align: center;

    }

    &-social {
      position: absolute;
      bottom: 1rem;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;

      #socialIcon {
        width: 2rem;
        height: 2rem;
        color: $gold;
        border-radius: $smallRadius;
        margin: 0 1rem;
        cursor: pointer;
        transition: .3s;

        &:hover {
          color: $white;
        }
      }
    }
  }

  &-agents {
    width: 70%;
    height: 30rem;
    background: $darkBackground;
    border-right: .1rem solid $gold;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);

    p {
      background: transparentize($white, 0.9);
      width: 1px;
      height: 90%;
      text-align: center;
    }

    &-experts, &-summarizers {
      &-wrapper {
        width: 45%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        &-title {
          font-size: 1.5rem;
          font-weight: 300;
          color: $white;
          margin: 1rem 0;
          width: 100%;
          padding: .5rem 0;
          text-align: center;
        }
      }

      width: 100%;
      display: flex;
      flex-wrap: wrap;
      overflow-y: auto;

      .agentCard {
        background: $darkerBackground;
      }

    }

  }
}