@import "../../../../Utils/mixins.scss";

.settings {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 90%;

  button {
    @include button($gold);
    margin-left: 1rem;
  }


  span {
    width: 80%;
    height: .15rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    background-color: transparentize($white, 0.9);
  }

  &-title {
    font-family: $header-font-family, sans-serif;
    font-size: 2rem;
    font-weight: 300;
    margin-top: 3rem;
    color: $gold;

    &-info {
      position: relative;
      width: 100%;
      padding-bottom: 4rem;

      &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 70%;
        margin: .3rem 0;

        h1 {
          font-family: $header-font-family, sans-serif;
          font-size: 1.2rem;
          font-weight: 300;
          color: $gold;
        }

        h2 {
          font-family: $header-font-family, sans-serif;
          font-size: 1.2rem;
          font-weight: 300;
          color: $white;
        }


      }

      &-edit {
        position: absolute;
        bottom: 0;
        left: calc(50% - 5rem);
        display: flex;
      }
    }
  }

  &-info {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 4rem;


    &-item {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: space-between;
      width: 50%;
      margin: .3rem 0;

      &-input {
        all: unset;
        font-family: $header-font-family, sans-serif;
        font-size: 1rem;
        font-weight: 300;
        width: calc(100% - 2rem);
        padding: .5rem 1rem;
        text-align: start;
        white-space: pre-wrap;
        word-break: break-word;
        color: $white;
        border: .1rem solid transparentize($white, 0.9);
        box-shadow: 0 0 0 .1rem transparentize($darkestBlack, 0.9);
      }

      h1 {
        font-family: $header-font-family, sans-serif;
        font-size: 1rem;
        font-weight: 400;
        margin-top: 1rem;
        margin-bottom: .5rem;
        color: $white;
      }

      h2 {
        font-family: $header-font-family, sans-serif;
        font-size: 1rem;
        font-weight: 300;
        margin-left: 1rem;
        color: $white;
      }

      &-charcount {
        font-family: $header-font-family, sans-serif;
        font-size: .8rem;
        font-weight: 400;
        margin-top: .2rem;
        margin-bottom: .5rem;
        color: $gold;
      }

      &-tags {
        display: flex;
        align-items: center;
        width: 100%;

        &-input {
          @include input($gold);
          font-size: .8rem;
          width: 100%;
          margin: 0;
          padding: 0;
          border: none;
          box-shadow: none;
        }

        &-label {
          font-family: $header-font-family, sans-serif;
          font-size: 1.2rem;
          font-weight: 300;
          width: 100%;
          margin-top: 1rem;
          margin-bottom: .5rem;
          color: $white;
        }

        &-tag {
          @include tag();

          &-add {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0;
          }
        }
      }

      &-color {
        position: relative;
        width: 1.5rem;
        height: 1.5rem;
        margin-top: .5rem;
        margin-left: 1rem;
        border-radius: .2rem;

        &-picker {
          position: absolute;
          top: 50%;
          left: 120%;
        }

        &-icons {
          position: relative;
          display: flex;
          align-items: center;
          width: 100%;

          &-modal {
            position: absolute;
            top: 100%;
            left: 3.2rem;
            overflow: auto;
            height: 20rem;
            width: 40rem;
            padding: .5rem;
            border: 1px solid transparentize($white, 0.9);
            background-color: $darkestBlack;
            z-index: 2;

            &-topbar {
              height: 3rem;
              width: 100%;
              background: red;
            }

            &-icon {
              font-size: 1.5rem;
              margin: .5rem;
              color: $white;
              background: $darkBackground;
              border: .1rem solid transparentize($white, 0.9);
              padding: .5rem;
            }
          }

          &-current {
            font-size: 2rem;
            margin-top: .5rem;
            margin-left: 1.4rem;
            color: $gold;
          }
        }

        &-wrapper {
          display: flex;
          align-items: center;
          width: 100%;
        }
      }
    }

    &-item#info-color {
    }

    &-edit {
      position: absolute;
      bottom: 0;
      left: calc(70% - 5rem);
      display: flex;
    }
  }

  &-update {
    width: 5rem !important;
    margin-top: 1rem !important;
    margin-left: 0 !important;
  }

  &-danger {
    &-title {
      font-family: $header-font-family, sans-serif;
      font-size: 2rem;
      font-weight: 400;
      margin-top: 3rem;
      color: $red;
    }

    &-item {
      display: flex;
      align-items: center;
      padding-bottom: 3rem;

      h1 {
        font-family: $header-font-family, sans-serif;
        font-size: 1.2rem;
        font-weight: 300;
        color: $white;
      }

      button {
        @include button($red);
        margin-left: 1rem;
      }

    }
  }

  &-documentPerms {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    h1 {
      font-family: $header-font-family, sans-serif;
      font-size: 1.2rem;
      font-weight: 300;
      color: $white;
      min-width: 10rem;
      white-space: nowrap;
      margin-top: 0.4rem;
    }

    .dropdown {
      max-width: 8rem;
    }
  }
}

.transferModal {

  &-organization{
    display: flex;
    width: 60%;
    justify-content: space-between;
    align-items: center;
    height: 4rem;

    &-remove{
      justify-content: center;
    }
  }

  .modal-content-big {
    width: 40%;
    height: auto;

    button {
      @include button($red);
      margin: .5rem 0;
    }

    p {
      margin-top: 2rem;
    }

    .dropdown {
      width: 100%;
      margin-right: 1rem;
    }
  }
}