@import "../../../../Utils/mixins.scss";

.staticChoice {
  @include defaultPage;
  min-height: unset;

  &-choices {
    display: flex;
    width: 90%;
    min-width: 40rem;
    max-width: 65rem;
    margin-top: 2rem;
    padding: .3rem;
    background: transparentize($darkerBackground, .4);
    border-radius: $mediumRadius;
    gap: .3rem;
    border: $thinBorder;

    &-choice {
      flex: 1;
      background: transparentize($darkBackground, .5);
      padding: 1rem;
      font-family: $title-font-family;
      font-size: 1rem;
      border-radius: $smallRadius;
      border: $thinBorder;
      color: $white;
      text-align: center;
      transition: .3s;
      cursor: pointer;

      &:hover {
        border-color: transparentize($white, .7);
      }
    }

    #chosenOption {
      background: transparentize($pink, .2);
    }
  }

  &-main {
    width: 50%;
    min-width: 60rem;
    max-width: 90rem;
    margin-top: 1rem;
    padding: .3rem;
    background: transparentize($darkerBackground, .4);
    border-radius: $mediumRadius;
    gap: .3rem;
    border: $thinBorder;
  }
}

@include tablets {
  .staticChoice {
    min-height: unset;

    &-choices {
      flex-direction: column;
      width: 80%;
      min-width: unset;
    }

    &-main {
      width: 80%;
      min-width: unset;
    }
  }
}