@import "../../../Utils/mixins.scss";

.fakeChat {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;


  &-navbar {
    position: absolute;
    top: 0;
    width: 100%;
    height: 4rem;
    background: $darkerBackground;
  }

  &-modal {
    width: 40vw;
    height: 50vh;
    background: $darkerBackground;
    z-index: 4;
    box-shadow: 0 0 2rem .1rem rgba(0, 0, 0, 0.2);
    border-left: .1rem solid $gold;
    border-right: .1rem solid $gold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-icon {
      width: 5rem;
      height: 5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 5rem;
      color: $gold;
    }

    &-header {
      width: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Exo 2", sans-serif;
      font-size: 1.5rem;
      color: $white;
      text-align: center;
    }

    &-login{
      @include button($gold);
      margin-top: 2rem;
    }
  }
}

.fakeChat:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(1vh);
}


@include tablets{
  .fakeChat{
    &-modal{
      width: 40rem;
      max-width: 90vw;
      &-header{
        font-size: 1.2rem;
      }
    }
  }
}