@import '../../Utils/mixins.scss';

.searchBar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50rem;
  height: 3rem;
  border: 1px solid transparentize($white, .8);
  box-shadow: 0 0 1rem .1rem rgba(0, 0, 0, 0.2);
  background-color: transparentize($darkerBackground, .5);
  z-index: 5;

  &-form {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-left: 1rem;
  }

  input {
    font-family: $header-font-family, sans-serif;
    font-size: 1rem;
    font-weight: 300;
    width: 90%;
    height: 100%;
    transition: all 0.2s;
    color: $white;
    border: none;
    background: transparent;

    &:focus {
      outline: none;
    }
  }

  .sendButton {
    width: 10%;
    height: 75%;
    cursor: pointer;
    transition: all 0.3s;
    color: $grey;
    border: none;
    background: transparent;


    &:hover {
      color: $gold;
    }
  }

  .searchResults {
    width: 100%;
    min-height: 5rem;
    max-height: 30rem;
    overflow: auto;
    position: absolute;
    top: 110%;
    left: 0;
    display: flex;
    flex-direction: column;
    z-index: 10;
    border: 1px solid transparentize($white, .8);
    box-shadow: 0 0 1rem .1rem rgba(0, 0, 0, 0.2);
    background-color: $darkerBackground;

    .searchOption {
      display: flex;
      align-items: center;
      justify-content: center;

      h1 {
        font-family: $header-font-family, sans-serif;
        font-size: 1.5rem;
        font-weight: 300;
        color: $white;
        padding: 1rem;
        transition: all 0.2s;
        cursor: pointer;
      }


    }
  }

}

//.searchBarWrapper {
//  position: relative;
//  width: 100%;
//  height: 100%;
//  display: flex;
//  flex-direction: column;
//  justify-content: center;
//  background-color: $darkBackground;
//  box-shadow: 0 0.5vh 1vh rgba(0, 0, 0, 0.2);
//  border-radius: 1vh;
//  padding: 2vh 2vh;
//
//  .searchBarUpper {
//    width: 90%;
//    display: flex;
//    height: 50%;
//
//    .inputWrapper {
//      width: 50%;
//      position: relative;
//
//    }
//
//    input {
//      all: unset;
//      width: 100%;
//      height: 100%;
//      font-size: 2.5vh;
//      padding: 0.5vh 5vh 0.5vh 5vh;
//      background-color: $darkerBackground;
//    }
//
//
//    .select {
//      flex: 1;
//    }
//  }
//
//  .searchBarLower {
//    height: 50%;
//    display: flex;
//    width: 90%;
//
//    .select {
//      min-width: 20vw;
//      max-width: 30vw;
//    }
//  }
//
//  .sendButton {
//    cursor: pointer;
//    position: absolute;
//    right: 2vh;
//    height: 8vh;
//    bottom: 2vh;
//    min-width: 20vh;
//    border-radius: 20vh;
//    background: linear-gradient(45deg, $lightPurple, $orange);
//    @include whiteGlassLightShadow;
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    color: white;
//    font-size: 3vh;
//    font-family: $header-font-family sans-serif;
//    font-weight: 700;
//  }
//}

