@import '../../../Utils/mixins.scss';
@import "../../../Utils/animations";

.readMore {
  @include defaultPage;
  min-height: unset;

  &-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 80%;
    padding: 0 2rem;
    color: $white;

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 1rem;
      height: 30rem;
      width: calc(25% - 2rem);
      min-width: 20rem;
      position: relative;
      transition: .3s;
      cursor: pointer;
      border-radius: $mediumRadius;
      @include glass;
      border: $thinBorder;
      padding: 1rem;

      &-title {
        font-size: 1.5rem;
        margin-top: 1rem;
        font-family: $title-font-family;
        width: 90%;
      }

      &-image {
        height: 75%;
        width: 100%;
        border-radius: $mediumRadius;
        border: $thinBorder;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        object-fit: cover;
      }

      &-description {
        font-size: 1rem;
        margin-top: 1rem;
        width: 90%;
        font-family: $title-font-family;
        color: $grey;
      }

      &-button {
        all: unset;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 1rem;
        padding: .5rem 1rem;
        transition: .3s;
        min-width: 10rem;
        font-size: 1rem;
        border-left: 2px solid transparentize($white, .8);
        color: $white;
        font-family: $paragraph-font-family, sans-serif;
        position: absolute;
        bottom: 1.5rem;
        left: 1.5rem;

        * {
          transition: .3s;
        }

        &-icon {
          color: $white;
        }

        &:hover {
          color: $roseGold;
          border-color: transparentize($roseGold, .5);

          .readMore-content-item-button-icon {
            color: $roseGold;
            transform: translateX(.3rem);
          }
        }
      }


    }
  }

}