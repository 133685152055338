@import '../../../Utils/mixins.scss';
@import "../../../Utils/animations";


.funTool {
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;

  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 5rem;


  &-title {
    @include bigHeader;
    font-size: 4rem;
    color: $white;
    text-align: center;
    width: 50%;

    &-highlight {
      @include gradientText(30deg, $orange, $pink);
    }

  }

  &-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    margin-top: 1rem;
    height: 75%;


    &-choose {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin-bottom: 1rem;

      &-item {
        margin: 0 .5rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        font-family: $title-font-family, sans-serif;
        justify-content: center;
        color: $white;
        padding: .5rem 1rem;
        font-size: 1rem;
        border: 1px solid transparentize($white, 0.8);
        border-radius: 1rem;
        background: transparentize($darkerBackground, .7);
        box-shadow: 0 0 1rem .1rem rgba(0, 0, 0, 0.5);
        transition: .3s;

        &-selected {
          color: $pink;
          border-color: $pink;
        }
      }
    }

    &-panel {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      min-height: 85vh;
    }
  }
}

.funToolComponent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &-wrapper {
    width: 95%;
  }

  &-generatingResponse {
    &-container {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }

    &-inline {
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-height: 90vh;
      font-size: 0.8rem;

      h1 {
        font-size: 1.2rem;
      }
    }
  }


  &-dummyChat {
    margin: auto;
    border-radius: $mediumRadius;
    display: flex;
    flex-direction: row;
    position: relative;
    border: $thinBorder;
    height: 100%;


    &-left {
      position: relative;
      border-right: 1px solid rgba($white, 0.5);
      width: 25%;
      border-top-left-radius: $mediumRadius;
      border-bottom-left-radius: $mediumRadius;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-buttonContainer {
        width: 100%;
        margin-top: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;

        &-next {
          @include button($pink)
        }
      }

      &-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2rem;
        gap: 1rem;
      }

      &-title {
        display: flex;
        color: $white;
        font-size: 1.2rem;
        margin-bottom: 1rem;
      }

      &-overlayLeft {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.3);
        border-top-left-radius: $mediumRadius;
        border-bottom-left-radius: $mediumRadius;
      }
    }

    &-right {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 80%;
      position: relative;
      border-top-right-radius: $mediumRadius;
      border-bottom-right-radius: $mediumRadius;
      overflow-x: hidden;
      overflow-y: auto;
      height: 100%;

      &-prompts {
        margin-top: 5%;
        display: flex;
        flex-direction: column;
        min-height: 50%;
        align-items: center;
        width: 100%;

        &-prompt {
          cursor: pointer;
          width: 70%;
          background-color: $darkerBackground;
          margin-bottom: .5rem;
          border: $thinBorder;
          border-radius: $smallRadius;
          transition: border 0.3s ease;
          text-align: start;
          @include interactableHighlight($pink, 2s);

          h1 {
            margin: 1rem;
            font-family: $header-font-family, sans-serif;
            font-size: 1rem;
            font-weight: 400;
            color: $white;
          }
        }

        &-prompt:hover {
          border-color: transparentize($pink, 0.5);
        }
      }

      .message {
        width: 70%;
        padding: 1.5rem 2.5rem;
        @include interactableHighlight;

        &-innerWrapper {
          padding: 0;
        }
      }

      &-responses {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 1px;
        margin-right: 1px;
        width: 100%;
        padding-bottom: 4rem;
      }

      &-spacer {
        flex-grow: 1;
      }

      &-customProfile {
        color: $white;
        height: 80%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &-input {
          @include input($white);
          width: 60% !important;
          margin-top: 2rem;
          background: $darkBackground;
          border-radius: 1rem;
          padding: 1rem;
          justify-content: space-between;
          display: flex;
          align-items: center;

          &:focus-within {
            border-color: rgba($white, 0.5)
          }

          &-text {
            color: $white;
            width: 80%;
            font-size: 2rem;
            background: none;
            border: none;
            margin-left: 5%;
            outline: none;
          }

          &-icon {
            font-size: 2.5rem;
            color: $white;
            margin-left: auto;
            margin-right: 1rem;
            cursor: pointer;
          }
        }
      }

      &-mockInput {
        @include input($white);
        position: sticky;
        bottom: 5%;
        display: flex;
        align-items: center;
        cursor: pointer;

        p {
          color: white;
          font-size: 1rem;
          font-weight: 300;
          margin-left: 0.5rem;
        }

        margin-bottom: .5rem;
        padding: .5rem 1rem;
        border-radius: $smallRadius;
        background: transparentize($darkBackground, 0);
        box-shadow: 0 0 .2rem .1rem transparentize($darkestBlack, .5);
        width: 70%;
        border: none;
        min-height: 1.8rem;

        &-wrapper {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

      }

      &-overlayRight {
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
        border-top-right-radius: 3vh;
        border-bottom-right-radius: 3vh;
      }
    }
  }

  &-highlight {
    //border: 1px solid transparentize($roseGold, .3) !important;
  }
}

.icon {
  filter: brightness(100);
}

.selected {
  border: 1px solid $pink !important;
  box-shadow: none !important;

}

.response {
  border: 1px solid transparent;
  cursor: pointer;
  border-radius: $mediumRadius;

  &-select {
    background: $greyBackground;
  }
}

.response:hover {
  border: 1px solid transparentize($pink, .3);
}
