@import "../../../../../Utils/mixins";

.documentHallucinations {
  transition: .3s;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
  position: relative;
  flex-direction: column;
  cursor: pointer;


  &-message {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: .5rem;

    &-author {
      font-family: $header-font-family, sans-serif;
      font-size: 1rem;
      font-weight: 500;
      color: $white;
      width: 100%;
      border-bottom: $thinBorder;
      margin-bottom: .5rem;
      padding-bottom: .5rem;
    }

    p {
      font-family: $header-font-family, sans-serif;
      font-size: 1rem;
      font-weight: 400;
      color: $grey;
      animation: fadeIn 1s;
      transition: .3s;
    }
  }
}

.red {
  color: $red !important;
  height: 3em;
  line-height: 1.5em;
}

.green {
  color: $green !important;
  height: 3em;
  line-height: 1.5em;
}
