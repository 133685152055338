@import "../../../../Utils/mixins.scss";
@import "../../../../Utils/colors.scss";

.overview-content-overview {
  display: flex;
  width: 100%;
  height: calc(100vh - 6rem);
  padding: 1rem;
  @mixin panel {
    margin: .5rem;
    border: 1px solid transparentize($white, 0.9);
    border-radius: 1vh;
    background: $darkBackground;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
    color: $white;
  }

  &-modal {
    width: 100%;
    align-items: center;
    &-buttons {
      width: 70%;
      margin-top: 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      button {
        @include button($gold);
      }

      :hover {
        background-color: $gold;
        color: black;
      }
    }
  }

  &-left {
    width: 65%;
    display: flex;
    flex-direction: column;
  }

  &-right {
    display: flex;
    flex-direction: column;
    width: 35%;
  }

  &-item {
    @include panel;
    
    a {
      color: $white;
      text-decoration: none;

      &:visited {
        color: $white;
      }
    }

    h1 {
      font-family: $header-font-family, sans-serif;
      font-size: 1.5rem;
      font-weight: 300;
      margin: 1rem;
    }
  }

  &-info {
    height: 50%;
  }

  &-quickActions {
    height: 20%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    :hover {
      color: $gold;

      &:hover {
        color: $gold;
  
        h1, &-img {
          color: $gold;
        }
      }
    }

    &-line {
      width: 1px;
      height: 80%;
      background: transparentize($white, 0.7);
    }

    :visited {
      color: $white;
    }


    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 70%;
      cursor: pointer;
      text-decoration:none;
      background: none;
      color: $white;
      border: none;

      &-img {
        width: 2rem;
        height: 2rem;
      }

      h1 {
        font-family: $header-font-family, sans-serif;
        font-size: 1.1rem;
        font-weight: 300;
        margin: .5rem 0 0;

      }
    }
  }

  &-employees {
    &-member {
      height: 100%;
    }
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;

    h1 {
      align-self: flex-start;
    }

    &-item {
      &-user {
        color: $gold;
        border: 1px solid transparentize($gold, 0.8);
      }

      width: 95%;
      display: flex;
      align-items: center;
      border: 1px solid transparentize($white, 0.8);
      border-radius: $smallRadius;
      margin: .5rem;
      position: relative;
      transition: .3s;
      cursor: pointer;

      * {
        transition: .3s;
      }

      &-role {
        position: absolute;
        right: 2rem;
        background: $darkerBackground;
        border-radius: $smallRadius;
        color: $white;
        background: transparentize($gold, 0.5);
        font-weight: 500 !important;
        font-size: 1rem !important;
        padding: .3rem 1rem;

      }

      &-arrow {
        position: absolute;
        right: 1rem;
        width: 1.5rem;
        height: 1.5rem;
        color: $white;
        cursor: pointer;
        transform: rotate(90deg);
      }

      &-img {
        width: 1.5rem;
        height: 1.5rem;
        margin: .5rem;

      }

      &:hover {
        background: transparentize($white, 0.9);
        border-color: transparentize($white, 0.7);
      }
    }
  }

  &-experts {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      align-self: flex-start;
    }

    .agentCard {
      width: 90%;

    }
  }

  &-weeklyTeamUsage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include panel;

    &-heading {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 1rem;

      h1 {
        font-family: $header-font-family, sans-serif;
        font-size: 1.5rem;
        font-weight: 300;
        margin-left: 1rem;
        max-width: 40%;
      }

      .dropdown {
        margin: unset;
        max-width: 40%;
        margin-right: 1rem;
      }
    }

    &-bottom {

      .dropdown {
        margin: unset;
        max-width: 25%;
        margin-right: 1rem;
        margin-bottom: 1rem;
        float: right;
      }
    }
  }
}