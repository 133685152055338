@import "../../../../Utils/mixins.scss";

.dashboard-content-payment {
  z-index: 2;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: calc(100% - 15rem);
  margin-top: 2vh;
  border-radius: 2vh;

  &-balance {
    margin-top: 2rem;
    margin-left: 2rem;

    h1 {
      font-family: $header-font-family, sans-serif;
      font-size: 1.5rem;
      font-weight: 400;
      color: transparentize($white, .3);

    }

    p {
      font-family: $header-font-family, sans-serif;
      font-size: 2rem;
      font-weight: 600;
      margin-top: .5rem;
      color: transparentize($white, .1);
    }
  }

  &-title {
    font-family: $header-font-family, sans-serif;
    font-size: 2rem;
    font-weight: 300;
    margin-top: 3rem;
    margin-left: 2rem;
    color: $gold;
    &-open {
      width: calc(100% - 4rem);
      padding-bottom: 1rem;
      border-bottom: 3px solid transparentize($gold, 0.5);
    }

  }

  &-recharge {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 2.5rem;
    margin-top: 1rem;
    margin-left: 2rem;

    input {
      all: unset;
      font-family: $header-font-family, sans-serif;
      font-size: 1rem;
      font-weight: 300;
      height: 100%;
      padding: 0 2rem;
      text-align: start;
      white-space: pre-wrap;
      word-break: break-word;
      color: $white;
      border: .1rem solid transparentize($white, 0.9);
      background: $darkBackground;
      box-shadow: 0 0 0 .1rem transparentize($darkestBlack, 0.9);
    }

    &-icon {
      width: 1.8rem;
      height: 1.8rem;
      margin-right: .5rem;
      color: $white;
    }

    &-button {
      all: unset;
      font-family: $header-font-family, sans-serif;
      font-size: 1rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 4rem;
      height: 100%;
      padding: 0 .5rem;
      transition: all .2s ease-in-out;
      color: $white;
      border: .1rem solid transparentize($white, 0.9);
      background: transparentize($gold, 0.5);
      cursor: not-allowed;
      //&:hover {
      //  background: transparentize($gold, 0);
      //}
    }
  }

  &-warning {
    margin-left: 2rem;
    margin-top: 1rem;
    color: $white;

    &-link {
      margin-left: 2rem;
      margin-top: .5rem;
      color: $gold;
    }
  }
}