@import "../../Utils/mixins.scss";

@include tablets {
  .navbar {
    z-index: 5;

    &-modal {
      position: fixed;
      z-index: 7;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;
      height: 100vh;
      background: transparentize($darkerBackground, 0.03);

      .midSection {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: calc(100% - 4rem);
        overflow: auto;
        border-top: $thinBorder;
        box-sizing: border-box;
        overflow-y: auto; // Ensure vertical scroll
        overflow-x: hidden; // Prevent horizontal scroll
        max-height: calc(100% - 4rem); // Ensure midSection doesn’t exceed available space

        .line {
          background: $white;
        }

        h1 {
          font-family: "Exo 2", sans-serif;
          font-size: 2rem;
          font-weight: 300;
          margin-top: 2rem;
          cursor: pointer;
          transition: 0.3s all;
          color: $pink;
          box-sizing: border-box;
        }
      }

      a,
      p {
        all: unset;
        font-family: "Exo 2", sans-serif;
        font-size: 2rem;
        font-weight: 300;
        margin: 1rem;
        cursor: pointer;
        transition: 0.3s all;
        color: rgba(245, 245, 245, 0.8);
        box-sizing: border-box;
      }


      &-user {
        position: absolute;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border-top: 1px solid $gold;

        &-wrapper {
          display: flex;
          align-items: center;
          padding: 1rem;
          transition: .3s all;
        }

        &-photo {
          width: auto;
          height: 2rem;
          margin: 1rem;
          color: white;
        }

        &-name {
          font-family: "Exo 2", sans-serif;
          font-size: 1.5rem;
          font-weight: 400;
          color: $white;

        }

        &-item {
          margin-left: 1rem;
          color: $white;
          height: 1.5rem;
          width: auto;

          &-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            padding: 1rem;
            cursor: pointer;
            transition: .3s all;
          }
        }
      }
    }
  }

  .appiIcon {
    margin-left: 2rem !important;
  }

  .navbarMain {
    z-index: 10;

    &-burger {
      z-index: 10;
      width: auto;
      height: 2rem;
      margin-right: 1rem;
      cursor: pointer;
      transition: .3s;
      color: $white;
    }
  }

  .navbarInvisibleScroll {
    backdrop-filter: unset;
    background: $darkerBackground;
  }

  .navbarOther {
    .navbarMain-burger {
      transform: scale(1);
    }

  }
}

@include phone {
  .navbar {
    .appiText {
      display: none;
    }
  }
}
