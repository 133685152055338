@import '../../Utils/mixins.scss';

@mixin application($c) {
  position: relative;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  overflow-x: hidden;


  .application-loading {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    backdrop-filter: blur(5rem);

    &-icon {
      width: 5rem;
      height: 5rem;
      color: $c;
      animation: spin .8s linear infinite;
    }
  }

  .application {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;


    &-informationPanel {
      display: flex;
      align-items: center;
      width: 100%;
      height: 20rem;
      padding: 1rem 0;
      position: relative;

      //border-bottom: .1rem solid $gold;
      p {
        margin-left: 5%;
      }

      p, span {
        font-size: 2rem;
        font-weight: 500;
        color: $grey;
        font-family: "Exo 2", serif;
        z-index: 2;
        width: 90%;

        span {
          color: $c;
        }
      }


      &-favourite, &-default {
        transition: .3s;

        &:hover {
          color: $c;

        }
      }

      &-favourite {
        height: 2.4rem;
        width: 2.4rem;
        margin-top: .7rem;
        margin-left: 1rem;
        color: $c;
        cursor: pointer;

        &-outline {
          color: grey;
        }


      }

      &-default {
        height: 2.4rem;
        width: 2.4rem;
        margin-top: .7rem;
        margin-left: 1rem;
        color: grey;
        cursor: pointer;

        &-check {
          color: $c;
        }
      }
    }

    &-selector {
      height: 5vh;
      width: 90%;
      padding: 0 5%;
      border-bottom: 1px solid transparentize($white, .7);
      display: flex;
      margin-top: -5vh;
      z-index: 3;

      &-item {
        font-family: "Exo 2", sans-serif;
        font-size: .6rem;
        font-weight: 300;
        color: $white;
        cursor: pointer;
        transition: .3s;
        margin-right: 1.1rem;
        display: flex;
        align-items: center;
        border-bottom: .15rem solid transparentize($c, 1);
        padding: 0 .3rem;

        svg {
          margin-right: .5rem;
          width: 1rem;
          height: 1rem;
        }

        &:hover {
          color: $grey;
        }
      }

      .selected {
        color: $c;
        border-bottom: .15rem solid transparentize($c, .2);
      }
    }

    &-page {
      width: 90%;
      padding: 0 5%;
    }

    &-categories {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80%;
      padding-bottom: 1rem;
      border-bottom: .1rem solid $c;

      &-item {
        font-family: "Exo 2", sans-serif;
        font-size: .7rem;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 1vh;
        padding: .4rem 1rem;
        color: $c;
        border: .3vh solid rgba(0, 0, 0, 0.7);
        background: $darkerBackground;
        box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
        cursor: pointer;
      }
    }

    &-description {
      display: flex;
      width: 80%;
      padding: 2rem 0;

      &-author {
        display: flex;
        align-items: center;
        flex: 1;
        flex-direction: column;
        justify-content: center;

        p {
          font-family: $header-font-family, sans-serif;
          font-size: 1.5rem;
          font-weight: 300;
          text-align: center;
          color: $white;
        }

        &-card {
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 80%;
          padding: 2rem .2rem;
          background-color: transparentize($darkBackground, .3);
          box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);


          &-icon {
            width: 3rem;
            height: 3rem;
            margin-bottom: 1rem;
            padding: .5rem;
            color: $c;
            border-radius: $smallRadius;
            background: $darkerBackground;
            box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
          }

          &-link {
            font-family: $header-font-family, sans-serif;
            font-size: 1.2rem;
            font-weight: 300;
            margin-top: 1.5rem;
            cursor: pointer;
            transition: .3s;
            text-align: center;
            text-decoration: none;
            color: $c;

            &:hover {
              color: $white;
            }
          }
        }
      }

      &-description {
        display: flex;
        flex: 2.5;
        flex-direction: column;
        border-right: .1rem solid $c;
        align-items: center;

        p {
          width: 80%;
          white-space: pre-wrap;
          font-family: $header-font-family, sans-serif;
          font-size: 1.2rem;
          font-weight: 300;
          color: $white;
        }
      }
    }

    &-chat {
      @include button($c);
      margin-top: 1rem;
      padding: 1rem 4rem;
      margin-bottom: 2rem;
      background: transparentize($c, 0.9);
      box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
    }
  }
}

.applicationWrapper {
  @include application($gold);
}

#applicationSummarizer {
  @include application($pink);
}