@import "../../../Utils/mixins.scss";


@mixin form($c) {
  .createNewAgent-form {
    min-height: calc(100vh - 7rem);
    width: 90vw;
    margin-top: 7rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    padding-bottom: 4rem;

    &-wrapper {
      width: 100%;
      display: flex;
      position: relative;
      padding-bottom: 4rem;
    }

    &-left {
      display: flex;
      flex-direction: column;
      width: 45%;
      border-right: .1rem solid transparentize($white, .9);
    }

    &-right {
      display: flex;
      flex-direction: column;
      width: calc(55% - 2rem);
      padding-left: 3rem;
    }

    &-title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $header-font-family, sans-serif;
      font-size: 2rem;
      font-weight: 300;
      color: $c;
    }

    span {
      width: 100%;
      height: .15rem;
      background-color: transparentize($c, 0.7);
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    &-label, &-prompts-inputs-label {
      font-family: $header-font-family, sans-serif;
      font-size: 1.2rem;
      font-weight: 300;
      color: $white;
      margin-top: 1rem;
      margin-bottom: .5rem;
    }

    &-input {
      @include input($c);
      background: transparentize($darkBackground, .8);

      &-charcount {
        font-family: $header-font-family, sans-serif;
        font-size: 1rem;
        font-weight: 300;
        color: $white;
        align-self: flex-start;
        margin-top: .1rem;
        margin-bottom: 1rem;
        text-align: right;
      }
    }

    &-text {
      font-family: $header-font-family, sans-serif;
      font-size: 1.2rem;
      font-weight: 300;
      color: $white;
      margin-top: 1rem;
      margin-bottom: .5rem;
      text-align: center;
      width: 100%;

      span {
        all: unset;
        color: $c;
        margin: .2rem 0;

      }

    }

    &-tags {
      display: flex;
      width: 100%;
      align-items: center;

      &-input {
        @include input($c);
        width: 100%;
        padding: 0;
        margin: 0;
        border: none;
        box-shadow: none;
        font-size: .8rem;
      }

      &-label {
        font-family: $header-font-family, sans-serif;
        font-size: 1.2rem;
        font-weight: 300;
        color: $white;
        margin-top: 1rem;
        margin-bottom: .5rem;
        width: 100%;
      }

      &-tag {
        @include tag();

        &-add {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 0;
        }
      }
    }


    &-button {
      @include button($c);
      position: absolute;
      right: 0;
      bottom: 0;
      background-color: $darkBackground;
      padding: .5rem 1.5rem;
    }
  }

}

#expert.createNewAgent {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  @include form($c: $gold);
  padding-bottom: 4rem;
}

#summarizer.createNewAgent {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  @include form($c: $pink);
  padding-bottom: 4rem;
}