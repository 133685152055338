@import "../../../../Utils/mixins.scss";

.staticPrompt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 3rem;
  background: transparentize($darkestBlack, .5);
  box-shadow: 0 0 1rem 1rem rgba(0, 0, 0, .2);
  border-top: 1px solid $pink;
  border-bottom: 1px solid $pink;
  padding: 6rem 0;

  &-text {
    display: flex;
    flex-direction: column;
    border-radius: $smallRadius;
    justify-content: center;
    align-items: center;

    &-title {
      font-family: $title-font-family, sans-serif;
      font-size: 5rem;
      font-weight: 600;
      margin-bottom: .5rem;
      color: $pink;

    }

    &-subtitle {
      font-family: $header-font-family, sans-serif;
      font-size: 1.5rem;
      font-weight: 400;
      color: $white;
    }
  }

  &-buttons {
    margin-top: 5rem;
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-button {
      all: unset;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      height: 3rem;
      border: none;
      border-radius: 5px;
      background-color: $pink;
      color: $white;
      font-family: $header-font-family, sans-serif;
      font-size: 1rem;
      font-weight: 600;
      cursor: pointer;
      transition: all .2s ease-in-out;

      &:hover {
        background-color: $white;
        color: $pink;
      }
    }
  }

}

@include tablets {
  .staticPrompt {
    &-text {
      text-align: center;
      width: 95%;
    }

    &-subtitle {
      width: 90%;
    }

    &-buttons {
      width: 80%;
    }
  }
}