@import "../../../Utils/mixins";

.chat-sidebar {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 3rem;
  display: flex;
  flex-direction: column;
  width: 18rem;
  height: 100vh;
  background: $darkerBackground;
  //box-shadow: 1vh 1vh 5vh rgba(0, 0, 0, 0.25);
  border-right: .1rem solid transparentize($white, 0.9);

  ::-webkit-scrollbar-track {
    background-color: transparentize($white, 1);
  }

  &-max {
    width: 50vw;

  }

  &-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    cursor: pointer;
    margin-right: 2rem;

    &-appi {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 2.2rem;
      filter: brightness(100);
    }

    &-appiText {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 2rem;
      margin-left: 2vh;
    }
  }

  &-titles {
    font-size: 1.2rem;
    font-weight: 300;
    margin-top: .5rem;
    margin-bottom: 1rem;
    color: $white;
  }

  &-CustomProfiles {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 2vh;
    transition: .3s;
    height: 100%;
    overflow-y: auto;

    &-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 85%;
      height: 2.5rem;
      cursor: pointer;
      transition: .3s;
      margin-bottom: 1rem;
      border-left: .15rem solid $gold;
      position: relative;

      h1 {
        font-family: $header-font-family, sans-serif;
        font-size: 1rem;
        font-weight: 400;
        margin-left: 1rem;
        color: $white;
      }
    }
  }

  &-dev {
    display: flex;
    align-items: center;
    padding-top: 1rem;
    width: 100%;
    transition: .3s;
    margin-bottom: 1rem;
    position: relative;
    height: 100%;
    flex-direction: column;

    h1 {
      font-family: $header-font-family, sans-serif;
      font-size: 1rem;
      font-weight: 400;
      margin-left: 1rem;
      color: $white;
    }

    &-presets {
      margin-top: 1rem;
      width: 90%;
      display: flex;
      flex-direction: column;

      &-preset {
        cursor: pointer;
        display: flex;
        height: 2rem;
        align-items: center;
        justify-content: space-evenly;
        padding: 0 1rem;
        border: .15rem solid transparentize($white, 0.6);
        border-radius: $smallRadius;
      }
    }

  }
}

.chat-sidebar-selection {
  position: absolute;
  z-index: 6;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 3rem;
  height: 100vh;
  background: $darkerBackground;
  border-right: .1rem solid transparentize($white, 0.9);

  &-icon, &-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 1.4rem;
    margin-top: 1rem;
    padding: .2rem 0;
    cursor: pointer;
    transition: .3s;
    color: $grey;
    border-left: .1rem solid transparent;

    &:hover {
      color: $gold;
    }
  }

  #active {
    border-left: .1rem solid $gold;
    color: $gold;
  }

  .chat-sidebar-selection-logo {
    object-fit: contain;
    filter: brightness(100);
  }

  .hidden {
    opacity: 0;
  }

}

.chat-sidebar-searchbar {
  width: 90%;
  box-shadow: 1vh 1vh 5vh rgba(0, 0, 0, 0.25);
  margin-bottom: 1rem;
  border-left: 2px solid $gold;
  border-right: 2px solid $gold;
  background: $darkBackground;
  position: relative;

  input {
    width: calc(100% - 2rem);
    padding: .5rem 1rem;
    background: none;
    border: none;
    outline: none;
    color: $white;
    font-family: $header-font-family, sans-serif;
    font-size: 1rem;
    font-weight: 300;
  }
}


#summarizer.chat-sidebar-searchbar {
  border-left-color: $pink;
  border-right-color: $pink;
}