@import "../../Utils/mixins";


.chat {
  @include defaultPage;
  flex-direction: row;
  background: $darkerBackground;
  position: relative;
  overflow-x: hidden;

  &-conversation {
    display: flex;
    margin-left: 3rem;
    width: calc(100% - 3rem);
    position: relative;
    margin-top: 4rem;
  }

  &-inspector {
    width: 50%;
    height: 100%;

    &-nothing {
      color: $grey;
      height: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 5rem;
      font-size: 1.2rem;
      font-family: $header-font-family;
    }

    .pdfViewer {
      padding-top: 0;
    }

    &-icons {
      position: absolute;
      cursor: pointer;
      top: 5rem;
      right: 2rem;
      display: flex;
    }

    &-icon {
      padding: .3rem 1rem;
      background: $greyBackground;
      border: 1px solid transparentize($grey, .9);
      border-radius: $smallRadius;
      color: $white;
      font-size: .9rem;
      font-family: $title-font-family;
      font-weight: 500;
      margin-left: 1rem;
      transition: .3s;
      cursor: pointer;
      z-index: 5;

      &:hover {
        background: transparentize($white, .6);
      }
    }

    &-close {
      right: 9rem;
    }
  }

  //.CustomProfiles {
  //  &-header {
  //    margin-top: 3rem;
  //  }
  //
  //  &-form {
  //    position: relative;
  //    display: flex;
  //    flex-direction: column;
  //    align-items: center;
  //    width: 100%;
  //
  //    input {
  //      @include input($white);
  //      width: 70%;
  //    }
  //
  //    &-textarea {
  //      @include input($white);
  //      width: 70%;
  //    }
  //
  //    &-buttons {
  //      display: flex;
  //      margin-top: 1rem;
  //      align-items: center;
  //      justify-content: flex-end;
  //      width: calc(70% + 2rem);
  //
  //      &-cancel {
  //        @include button($red);
  //        margin-right: 1rem;
  //      }
  //
  //      &-proceed {
  //        @include button($gold);
  //      }
  //
  //    }
  //  }
  //}

  .userInput-form {
    z-index: 2;
    display: flex;
    align-items: center;
    background: linear-gradient(0deg, $darkerBackground 0%, $darkerBackground 20%, rgba(0, 0, 0, 0) 100%);
    flex-direction: column;
    width: 100%;

    &-notice {
      font-size: .8rem;
      color: transparentize($white, .5);
      margin-bottom: .75rem;
    }
  }
}

.chat-sidebar-agents-counter {
  position: absolute;
  bottom: 0;
  height: 2rem;
  right: .3rem;
  width: 100%;
  background: $darkerBackground;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &-text {
    font-size: 1rem;
    color: $white;
  }

  span {
    font-weight: 500;
  }

  #counter-low {
    color: $gold;
  }

  #counter-mid {
    color: $gold;
  }

  #counter-high {
    color: $pink;
  }
}




