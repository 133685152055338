@import "../../../Utils/mixins";
@import "../../../Utils/animations";

.chat-inspector {
  //position: absolute;
  //top:0;
  //right: 0;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  height: calc(100vh - 4rem);
  margin-top: 4rem;
  padding-left: 1rem;
  padding-right: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  background: $darkerBackground;
  z-index: 2;
  align-items: center;
  border-left: $thinBorder;

  &-title {
    font-family: $header-font-family, sans-serif;
    font-size: 1.5rem;
    align-self: flex-start;
    font-weight: 300;
    margin-top: 1rem;
    color: $white;
    margin-bottom: 1rem;
  }

  &-document {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    padding: 1rem;
    background: $darkerBackground;
    border-top: $thinBorder;

    &-title {
      font-family: $header-font-family, sans-serif;
      font-size: 1.2rem;
      font-weight: 300;
      color: $gold;
      margin-bottom: 1rem;
      width: 100%;
      word-break: break-word;
    }

    &-part {
      margin: .2rem;
      display: flex;
      flex-direction: column;
      border: 1px solid transparentize($grey, .9);
      padding: .5rem;
      position: relative;
      cursor: pointer;
      border-radius: $smallRadius;
      transition: .3s;

      &-index {
        font-family: $header-font-family, sans-serif;
        font-size: 1rem;
        font-weight: 300;
        color: $roseGold;
        margin-bottom: .5rem;
      }

      &-title {
        font-family: $header-font-family, sans-serif;
        font-size: 1rem;
        font-weight: 300;
        color: $white;
      }

      &-page {
        font-family: $header-font-family, sans-serif;
        font-size: 1rem;
        font-weight: 300;
        color: $white;
        margin-top: .5rem;
        position: absolute;
        right: .5rem;
        top: 0;
      }

      &:hover {
        background: transparentize($white, .8);

      }

      &-highlighted {
        border-color: transparentize($roseGold, .5);
      }
    }
  }

}