@import '../../../Utils/mixins.scss';
@import '../../../Utils/fonts.scss';

.agents {
  padding-bottom: 3rem;

  &-seeMoreButton {
    //font-family: $header-font-family, sans-serif;
    //font-size: 1.2rem;
    //font-weight: 300;
    //display: flex;
    //align-items: center;
    //justify-content: center;
    //width: 25%;
    //height: 3rem;
    //margin-top: 3rem;
    //margin-bottom: 3rem;
    //transition: .3s;
    //color: $white;
    //border-radius: $smallRadius;
    //border: .1rem solid transparentize($gold, .5);
    //background-color: transparentize($darkBackground, .5);
    //box-shadow: 0 0 .5rem .1rem rgba(0, 0, 0, 0.2);
    //cursor: pointer;

    @include button($gold);
    margin-top: 2rem;

    //&:hover {
    //  background-color: transparentize($gold, .5);
    //}
    //
    &-summarizers {
      @include button($pink);
      margin-top: 2rem;
    }
  }

  &-recommendations {
    font-family: $header-font-family, sans-serif;
    font-size: 2rem;
    font-weight: 300;
    margin: 2rem 0;
    padding: 2rem 0;
    color: $white;
    width: 90vw;
    text-align: center;

    &-wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      z-index: 2;
    }

    &-item {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 5rem;

      &-title {
      }

      &-seeMoreButton {
        border-color: transparentize($roseGold, .3);

        &:hover {
          background-color: transparentize($roseGold, .5);
        }
      }
    }
  }

  &-appList {
    z-index: 5;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(23rem, 1fr));
    gap: 1.5rem;
    width: 90%;
    margin: 0 auto;

    .agentCard {
      width: unset;
      max-width: 40rem;
      margin: 0;


      .animatedBlob {
        opacity: .5;
      }
    }

    &-selections {
      display: flex;
      width: 90%;
      margin-top: 6rem;
      margin-bottom: 1rem;

      &-selection {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: .5rem 1rem;
        border-radius: $smallRadius;
        box-shadow: 0 0 .5rem .1rem rgba(0, 0, 0, 0.2);
        transition: .3s;
        cursor: pointer;
        background-color: $darkBackground;
        border: 1px solid transparentize($white, .8);
        margin-right: .5rem;

        h1 {
          font-family: $header-font-family, sans-serif;
          font-size: 1rem;
          font-weight: 300;
          color: $white;
        }

        &-selected {
          background-color: $gold;

          h1 {
            color: $darkBackground;
            font-weight: 400;
          }
        }
      }
    }
  }


  &-title {
    margin-top: 10rem;
    font-family: $title-font-family, sans-serif;
    letter-spacing: .1rem;
    font-size: 3rem;
    font-weight: 400;
    margin-bottom: 3rem;
    color: $white;
  }

  &-searchWrapper {
    width: 40%;
  }

  &-tags {
    display: flex;
    margin-top: 1rem;

    &-tag {
      font-family: $paragraph-font-family, sans-serif;
      font-size: .8rem;
      font-weight: 300;
      margin: 0 .5rem;
      padding: .2rem .8rem;
      cursor: pointer;
      transition: .1s linear;
      color: $white;
      border: $thinBorder;
      border-radius: .3rem;
      background-color: transparentize($accentsColor, .2);
      box-shadow: 0 0 .5rem .1rem rgba(0, 0, 0, 0.2);

      &:hover {
        border: 1px solid transparentize($white, .5);
        background-color: $accentsColor;
      }

    }
  }

}
