@import "../../../Utils/mixins";

@include phoneLandscape {
  .message {
    &-icon {
      &-wrapper {
        margin-left: 0;
      }
    }
  }
  .message-text-wrapper {
    width: 80% !important;
  }
}