@import "../../../../../Utils/mixins";

.meetAppiExperts {
  transition: transform 0.3s ease-in-out;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-auto-flow: row;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  width: 45vw;
  justify-items: start;
  align-items: start;


  .agentCard {
    background: transparentize($darkBackground, .5);
    width: 25rem;
    min-height: unset;
    height: 12.5rem;

    &-title {
      font-size: 1.4rem;
    }

    &-author {
      font-size: 1.3rem;
    }

    &-logo {
      width: 2.5rem;
      height: 2.5rem;
      padding: 1rem;
      background: none;
      border: $thinBorder;
    }

    &-description {
      font-size: 1.2rem;
    }

    #blob_1 {
      display: none;
    }
  }

  &-scroll {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    align-items: center;
    gap: 1rem;

    .agentCard {
      scroll-snap-align: center;
      flex: 0 0 auto;
      padding: 2rem;
    }
  }
}

.meetAppiExperts-hovered {
  .agentCard {
    transform: translate(-200%, -200%);
  }
}

.meetAppiExperts * {
  transition: transform 0.5s;
}

@include tablets {
  .meetAppiExperts {
    width: 120%;

    .agentCard {
      width: 12rem;
      height: 8rem;

      &-description {
        display: none;
      }
    }
  }
}

@include phoneLandscape {
  .meetAppiExperts {
    width: 200%;

    .agentCard {
      width: 8rem;
      height: 6rem;

      &-content {
        width: 100%;
        align-items: center;
      }

      &-logo {
        display: none;
      }

      &-title {
        width: 100%;
        text-align: center;
      }

      &-author {
        width: 100%;
        text-align: center;
        color: $roseGold;
      }
    }
  }
}


@media (max-width: 800px) {
  .meetAppiExperts {
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
    grid-template-rows: 1fr;
    overflow-x: auto;
    grid-auto-flow: column;
    scroll-snap-type: x mandatory;
    gap: 2rem;

    .agentCard {
      scroll-snap-align: start;
      width: 8rem;
      height: 6rem;
      margin: 0;
      margin-top: 1rem !important;

      &-content {
        width: 100%;
        align-items: center;
      }

      &-logo {
        display: none;
      }

      &-title {
        width: 100%;
        font-size: 1rem;
        text-align: center;
      }

      &-author {
        width: 100%;
        text-align: center;
        color: $roseGold;
        font-size: 0.8rem;
      }
    }
  }

  .meetAppiExperts-hovered {
    .agentCard {
      transform: none;
    }
  }

  .meetAppiExperts * {
    transition: none;
  }
}
