@import "../../../Utils/mixins";

@include tablets {
  .userInput-wrapper {
    width: 85%;

    .userInput, .userInput-choosePrompt {
      width: 90%;

      &-agents {
        &-mobile {
          height: 1.7rem;
          width: 1.7rem;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          cursor: pointer;

          &-icon {
            width: 80%;
            height: auto;
            color: $gold;
          }

          h1 {
            color: black;
            position: absolute;
            top: 0;
            right: 0;
            font-size: .6rem;
            background: $white;
            padding: .1rem .3rem;
            border-radius: 50%;
          }

          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }

}