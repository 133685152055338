@import "../../Utils/mixins";

.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(0.2rem);
  display: flex;
  align-items: center;
  justify-content: center;


  &-content {
    background: transparentize($darkerBackground, .05);
    width: 25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 0 0 1rem .1rem rgba(0, 0, 0, 0.5);
    border: $thinBorder;

    &-big {
      justify-content: flex-start;
      align-items: center;
      min-width: 40rem;
      width: 80%;

      h1 {
        margin-top: 3rem;
      }

    }

    &-small {
      justify-content: flex-start;
      align-items: center;
      min-width: 10rem;
      width: 25%;
      height: 20%;
    }

    h1 {
      font-size: 1.5rem;
      color: $white;
      font-family: $header-font-family, sans-serif;
      font-weight: 400;
    }

    &-form {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      input {
        @include input($white);
        width: 70%;
        margin-top: 2rem;
      }

      &-textarea {
        @include input($white);
        width: 70%;
        margin-top: 1rem;
      }
    }

    p {
      font-size: 1rem;
      color: $white;
      font-family: $header-font-family, sans-serif;
      font-weight: 300;
      margin-top: .5rem;
    }

    &-buttons {
      width: 100%;
      margin-top: 2rem;
      display: flex;
      justify-content: flex-end;

      &-cancel, &-proceed {
        width: 15%;
        text-align: center;
        padding: .5rem;
        font-size: 1rem;
        font-family: $header-font-family, sans-serif;
        font-weight: 300;
        border-radius: $smallRadius;
      }

      &-proceed {
        @include button($red);
        margin-right: 1rem;
      }

      &-loading {
        color: $gold;
        transition: .3s;
        cursor: pointer;
        height: 1rem;
        width: 1rem;
        animation: spin .8s linear infinite;
      }

      &-cancel {
        @include button($gold);
        margin-right: 1rem;

      }
    }
  }
}

@include phoneLandscape {
  .modal {
    &-content {
      width: 100%;
    }
  }
}