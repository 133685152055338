@import "../../../../../Utils/mixins";

.sidebar-hover-component {
  position: relative;

  &-text {
    position: absolute;
    top: calc(50% - .7rem);
    left: 120%;
    min-width: 10rem;
    padding: 0.5rem 1rem;
    color: $white;
    border-radius: $smallRadius;
    border: 1px solid rgba(255, 255, 255, 0.36);
    background: $darkerBackground;
    box-shadow: 0 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    font-family: $paragraph-font-family;
    font-weight: 500;
    display: flex;
  }
}