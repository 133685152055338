@import "../../../Utils/mixins";

.feedbackWrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feedback {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 70%;
  height: 100vh;

  &-form {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 60%; // Adjusted to match the width of contact inputs
    min-height: calc(100vh - 6rem);
    margin-top: 4rem;
    border: 1px solid transparentize($white, 0.6); // Adjusted for a lighter look
    background-color: transparentize($darkBackground, 0.6); // More transparency for the background
    box-shadow: 0 0 1.5rem 0.5rem rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease-in-out; // Ensure smooth transitions

    &-title {
      @include bigHeader;
      color: $white; // Changed to white
      font-size: 3rem;
      margin-top: 6rem;
      margin-bottom: 2rem;
    }

    &-input, &-textarea {
      all: unset;
      @include input($white); // Apply a mixin for consistent input styles
      width: 60%; // Adjusted width to match contact style
      margin-top: 1rem;
      font-size: 1.2rem;
      font-weight: 300;
      font-family: $header-font-family;
      transition: all 0.3s ease-in-out;

      &:focus {
        outline: none;
        border: 1px solid transparentize($white, 0.6);
      }
    }

    &-textarea {
      all: unset;
      resize: none;
      height: 10rem;
      @include input($white);
      width: 60%; // Consistent width with inputs
      font-size: 1.2rem;
      margin-top: 1rem;

      &:focus {
        outline: none;
        border: 1px solid transparentize($white, 0.6);
      }
    }

    &-button {
      all: unset;
      cursor: pointer;
      font-family: $header-font-family, sans-serif;
      font-size: 1.5rem;
      font-weight: 300;
      width: 60%;
      padding: .5rem 1rem;
      text-align: center;
      white-space: pre-wrap;
      word-break: break-word;
      color: $white;
      border: .1rem solid transparentize($white, 0.9);
      box-shadow: 0 0 0 .1rem transparentize($darkestBlack, 0.9);
      transition: all 0.3s ease-in-out;
      margin-top: 1rem;

      &:hover {
        background-color: transparentize($white, 0.7);
      }
    }
  }
}
