@import '../../Utils/mixins.scss';

@include phoneLandscape {
  .agents, .devProfile-agents-experts, .devProfile-agents-summarizers{
    .agentCard {
      width: 25rem;
      max-width: 90%;
      height: auto;

      &-wrapper {
        flex-direction: column;
        height: auto;
      }

      &-logo, &logo-default {
        margin-bottom: 1rem;
        margin-left: 0 !important;
      }

      &-description {
        text-align: center;
      }

      .categoriesSection {
        align-items: center;
        justify-content: center;
      }
    }
  }
}