@import './colors.scss';
@import './fonts.scss';
@import './components.scss';

@mixin staticHeader{
  font-family: $header-font-family, sans-serif;
  font-size: 2rem;
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: .5rem;
  color: $white;
}