@import "../../Utils/mixins.scss";

.organization {
  @include defaultPage;
  position: relative;
  overflow: hidden;
  align-items: flex-start;
  flex-direction: row;
  background-color: $darkerBackground;

  &-loading {
    align-items: center;
    justify-content: center;

    &-icon {
      width: 2rem;
      height: 2rem;
      animation: spin 1s linear infinite;
      color: $gold;
    }
  }

  .dropdown {
    margin-top: 5rem;
    margin-bottom: 2rem;
    width: 90%;
  }

  &-noOrganization {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $gold;
    height: 100vh;
    width: 100%;
  }
}
