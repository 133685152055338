@import '../../../../../Utils/mixins';

.individualChatProcessing {
  transition: .3s;
  display: grid;
  height: 100%;
  width: 90%;
  position: relative;
  cursor: pointer;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  justify-content: center;


  &-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: .5rem;

    svg {
      width: 1.8rem;
      height: 1.8rem;
      padding: .7rem;
      border-radius: $mediumRadius;
      background: $pink !important;
      color: $white;
    }

    p {
      font-size: 1rem;
      color: transparentize($white, .5);
      margin-top: .5rem;
    }
  }
}