@import "../../../../Utils/mixins.scss";

.dashboard {
  background: $darkerBackground;

  &-content {
    &-stats {
      margin-top: 2rem;
      z-index: 2;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      padding-bottom: 2rem;

      span {
        width: 100%;
        height: 1px;
        background: transparentize($white, 0.8);
        display: block;
        margin: 1rem 0;
      }

      @mixin panel {
        border: 1px solid transparentize($white, 0.9);
        border-radius: 1vh;
        background: $darkBackground;
        box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
      }

      h1 {
        font-size: 1.5rem;
        color: $white;
        font-family: $header-font-family, sans-serif;
        font-weight: 300;
      }

      &-statRow0 {
        width: calc(95% - 2rem);
        margin-bottom: 1rem;
        @include panel;
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-balance {
          display: flex;

          &-num {
            margin-left: 1rem;
            font-family: $header-font-family, sans-serif;
            font-size: 1.5rem;
            font-weight: 400;
            color: $roseGold;
          }
        }
      }

      &-statRow1 {
        width: calc(95% - 2rem);
        min-height: 30rem;
        padding: 1rem;
        @include panel;

        .numberOfRequestsPerDay {
          height: 20rem;
          margin-top: 1rem;
        }

        &-informations {
          display: grid;
          width: 100%;
          grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
          gap: 1rem;
        }

        &-header {
          display: flex;
          align-items: center;
          margin-bottom: 1rem;

          &-settings {
            display: flex;
            margin-left: 2rem;
            border-radius: $smallRadius;
            border: 1px solid transparentize($white, 0.9);

            &-item, &-item-active {
              font-size: 1rem;
              font-family: $header-font-family, sans-serif;
              font-weight: 400;
              color: $white;
              padding: .3rem 1rem;
              border-radius: $smallRadius;
              cursor: pointer;

              &-active {
                background: transparentize($roseGold, .5);
              }
            }
          }

          &-loading {
            margin-left: 2rem;
            font-size: 1rem;
            font-family: $header-font-family, sans-serif;
            font-weight: 400;
            color: $white;
            animation: spin .8s linear infinite;
          }
        }
      }

      &-statRow2 {
        display: grid;
        width: 95%;
        grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
        gap: 1rem;
      }
    }
  }
}

@include phoneLandscape {
  .dashboard {
    &-content {
      &-stats {
        &-statRow1 {
          flex-wrap: wrap;
          justify-content: center;

          .informationBlock {
            grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
          }
        }

        &-statRow2 {
          .monthSlider {
            width: 100%;
          }
        }
      }
    }
  }
}