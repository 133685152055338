@import "../../../../Utils/mixins.scss";


.customInstructionItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 85%;
  min-height: 2.5rem;
  cursor: pointer;
  transition: .3s;
  margin-bottom: 1rem;
  border-left: .15rem solid $gold;
  border-right: .15rem solid rgba(0, 0, 0, 0);
  position: relative;

  &-active {
    border-right: .15rem solid $gold;
    box-shadow: 0.5vh 0.5vh 2vh rgba(0, 0, 0, 0.2);

  }

  &-selected {
    border-color: $pink;

    .customInstructionItem-button {
      #delete, #edit {
        color: $pink;
      }
    }

    .customInstructionItem-icon {
      color: $pink;
    }

  }

  &-icon {
    margin-left: 1rem;
    width: 1.6rem;
    height: 1.6rem;
    transition: .3s;
    color: $gold;
  }

  &:hover {
    color: $darkerBackground;
  }

  h1 {
    font-family: $header-font-family, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    margin-left: 1rem;
    width: 70%;
    color: $white;
  }

  &-button {
    display: flex;
    position: absolute;
    right: 0;
    width: 33%;
    margin-left: auto;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    background: rgba(15, 15, 15, 0.3);
    backdrop-filter: blur(.25vh);

    #delete, #edit {
      height: 50%;
      width: auto;
      margin-right: .5rem;
      color: $gold;
      transition: .3s;


      &:hover {
        color: $white;
      }
    }

  }
}


.customInstructionItem-selected:hover {
  h1 {
    color: $pink;
  }
}