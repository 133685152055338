@import '../../../Utils/mixins.scss';

.email {
  @include defaultPage;
  height: auto;
  min-height: unset;
  padding-bottom: 2rem;
}

.sender {
  z-index: 3;
  display: flex;
  align-items: center;
  width: 70rem;
  height: 30rem;
  margin-top: 7vh;
  background: $darkestBlack;
  box-shadow: 0 0 1rem .1rem rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5vh);

  h2 {
    @include mediumHeader;
    font-weight: 400;
    align-self: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: $white;
  }

  .social {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 60%;
    height: 100%;
    border-right: 1px solid transparentize($white, 0.8);

    .socialItem {
      all: unset;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 90%;
      margin-top: .5rem;
      padding: 0.5rem 0;
      cursor: pointer;
      transition: 0.3s;
      color: $white;

      #socialIcon {
        width: 2rem;
        height: 2rem;
        color: $white;
        margin-left: 2vh;
        transition: 0.3s;

      }

      h3 {
        font-size: 1.3rem;
        @include smallHeader;
        font-weight: 500;
        margin-left: 1rem;
        transition: 0.3s;
        color: $white;

      }
    }

    .socialItem:hover {
      color: $white;
      background: transparentize($pink, 0.6);
      border-radius: 1rem;

      #socialIcon, h3 {
        color: $white;
      }
    }
  }

  .formWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 70%;
    height: 100%;

    h2 {
      @include mediumHeader;
      font-weight: 400;
      align-self: center;
      margin-bottom: 1rem;
      color: $white;
    }

    h3 {
      font-size: 2vh;
      align-self: center;
      color: $darkBackground;
    }

  }

  form {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    transition: 0.2s;
  }


  input[type=email], input[type=text] {
    @include input($gold);
    width: 80%;
    margin-bottom: .5rem;
  }

  .formWrapper-checkbox {
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    height: 1.2rem;
    margin: .5rem;
    padding: .5rem 1rem;
    color: $white;

    label {
      font-size: 1rem;
      max-width: 90%;
      height: 100%;
      color: $white;
    }

    input {
      margin-left: 1rem;
    }
  }

  button {
    all: unset;
    cursor: pointer;
    @include button($gold);
  }

  button:hover {
    font-weight: 400;
    color: $darkBackground;
    background: $gold;
  }
}
