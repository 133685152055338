@import '../../../Utils/mixins';

.animatedBlob {
  z-index: 0;
  &-wrapper {
    position: absolute;
    width: 20vh;
    height: 20vh;
    overflow: hidden;
    user-select: none;
  z-index: 0;
    filter: blur(1rem) opacity(.9);
  }

  &-noAnim {
    animation: none !important;
    transition: none !important;
  }

  width: 100%;
  height: 100%;
  animation: pulse 8s linear infinite,
  skew 5s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes skew {
  0%, 100% {
    transform: skew(0deg, 0deg);
  }
  50% {
    transform: skew(10deg, 10deg);
  }
}


