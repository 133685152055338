@import '../../../../../Utils/mixins';

.individualChatUnderstands {
  transition: .3s;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 90%;
  position: relative;
  cursor: pointer;
  align-content: center;

  &-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: .5rem;
    width: 6rem;

    svg {
      width: 1.8rem;
      height: 1.8rem;
      padding: .7rem;
      border-radius: $mediumRadius;
      background: $goldSaturated !important;
      color: $white;
    }

    p {
      font-size: 1rem;
      color: transparentize($white, .5);
      margin-top: .5rem;
    }
  }
}