@import '../../Utils/mixins.scss';

@include phoneLandscape {
  .application {
    .informationPanel {
      flex-direction: column;
      text-align: center;
      width: 100%;

      &-logo {
        margin: 0;
        background: $darkerBackground;
        padding: 1rem;
        height: 4rem;
        width: auto;
        border-radius: 1rem;
        border: .1rem solid transparentize($gold, 0.6);
      }

      h1 {
        margin: 1rem 0;
        width: 95%;
        font-size: 2.5rem;
      }
    }

    &-description {
      width: 95%;
      font-size: 1.5rem;
      flex-direction: column;

      &-description {
        border-right: none;
        text-align: center;
        margin-bottom: 2rem;
      }

      &-author {
        &-card {
          flex-direction: row;
          align-items: center;
          justify-content: space-evenly;
          cursor: pointer;

          * {
            margin: 0;
          }

          &-link {
            display: none;
          }
        }
      }
    }
  }

}