@import "../../../../Utils/mixins.scss";

.prompts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 4rem;
  margin-top: 4rem;

  span {
    width: 100% !important;
    margin: 3rem 0;
  }

  h1 {
    font-family: $header-font-family, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    color: $gold;
    margin-bottom: 1rem;
  }

  input, textarea {
    all: unset;
    font-family: $header-font-family, sans-serif;
    font-size: 1rem;
    font-weight: 300;
    width: calc(100% - 2rem);
    padding: .5rem 1rem;
    text-align: start;
    white-space: pre-wrap;
    word-break: break-word;
    color: $white;
    border: .1rem solid transparentize($white, 0.9);
    box-shadow: 0 0 0 .1rem transparentize($darkestBlack, 0.9);

    &::placeholder {
      color: transparentize($white, 0.4);
    }

  }

  &-button {
    @include button($gold);
    margin-top: 2rem;
  }
}