@import "../../../Utils/mixins.scss";

.organization-selector {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  color: $white;
  position: relative;


  &-title {
    margin-top: 9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;

    h1 {
      font-family: $header-font-family, sans-serif;
      font-size: 2rem;
      font-weight: 300;
      color: $white;
      margin-left: 1rem;
    }

    &-new {
      height: 1.8rem;
      width: 1.8rem;
      color: $gold;
      margin-left: 1rem;
      transition: .3s;

      &:hover {
        cursor: pointer;
        transform: scale(1.1);
      }
    }
  }

  &-items {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 30rem));
    flex-wrap: wrap;
    justify-content: center;
    z-index: 2;
    width: 90%;
    padding-top: 3rem;
    height: 100%;
  }

  &-item {
    margin: 1rem;
    z-index: 2;
    height: 10rem;
    box-shadow: 0 0 1rem .1rem rgba(0, 0, 0, 0.5);
    border: $thinBorder;
    background: $darkBackground;
    border-radius: 1rem;
    cursor: pointer;

    h1 {
      font-size: 1.5rem;
      font-weight: 300;
      margin: 0;
      padding: 1rem;
    }
  }

  &-loading {
    width: 2rem;
    height: 2rem;
    animation: spin 1s linear infinite;
    color: $gold;
    margin-top: 5rem;
    align-self: center;
  }
}