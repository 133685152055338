@import '../../Utils/mixins.scss';

.agentsWrapper {
  overflow: hidden;
  position: relative;
  background: $darkerBackground;
}

.agents {
  @include defaultPage;
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(5rem);

  &-loading {
    align-self: center;
    margin-top: 30vh;
    width: 4rem;
    height: 4rem;
    animation: spin 1s linear infinite;
    color: $gold;
  }

}