@import "../../Utils/mixins.scss";

.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;

  .dropdown-selected {
    padding: .5rem;
    border: 1px solid transparentize($white, .8);
    cursor: pointer;
    color: $white;
    background: $darkerBackground;
    border-radius: $smallRadius;
    display: flex;
    justify-content: space-between;
  }

  &-icon {
    margin-top: .2rem;
    transition: .3s;
  }

  &-icon#rotated {
    transform: rotate(180deg);
  }

  .dropdown-list {
    position: absolute;
    margin-top: .3rem;
    width: 100%;
    color: $white;
    background: $greyBackground;
    border: 1px solid transparentize($white, .7);
    z-index: 1000;
    border-radius: $smallRadius;

    .dropdown-item {
      padding: .5rem;
      border-radius: $smallRadius;
      cursor: pointer;

      &:hover {
        background-color: $accentsColor;
      }

      &-create {
        color: $gold;
        display: flex;
        align-items: center;
        justify-content: space-between;

        svg {
          margin-top: .2rem;
        }
      }
    }
  }
}
