//$darkerBackground: #1E2329;
//$darkBackground: #2A2F35;

$greyBackground: #212026;
$darkBackground: #1E1E1E;
$darkerBackground: #171717;
$darkestBlack: #0D0D0D;

$darkBackground05: rgba(37, 37, 39, 0.5);

$orange: #F5A302;
$gold: #B09A51;
$goldSaturated: #c2a853;
$yellow: #FFEB08;
$white: #E2E1E5;
$grey: #A7A3AF;
$dirtyWhite: #E0DCD9;
$offWhite: #F7F7F7;
$offWhite05: rgba(247, 247, 247, 0.5);
$offWhite03: rgba(247, 247, 247, 0.3);
$offWhite01: rgba(247, 247, 247, 0.15);
$pink: #ED1660;
$purple: #6A0DAD;
$roseGold: #D18B95;
$red: #b40c0c;
$blue: #0C6CB4;
$green: #0CB46C;

//CHAT

$codeColor: #0F0F0F;
$accentsColor: #2D2D2D;
$messageUser: #1E2329;
$messageAgent: #282c31;
$messageSummarizer: #1F1F1D;

$chatBackground: #212222;
//$chatBackground: #232628;

