@import '../../../Utils/mixins.scss';
@import "../../../Utils/animations";

.faq {
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  margin-top: 10rem;

  &-title {
    @include bigHeader;
    color: $white;
  }

  &-content {
    width: 100%;
    margin-top: 2rem;
    display: flex;
  }

  &-categories {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20%;

    &-category {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 1rem;
      cursor: pointer;
      transition: all 0.3s ease;
      font-size: 1.2rem;
      font-family: $header-font-family, sans-serif;
      border-bottom: 1px solid transparentize($white, 0.8);
      color: $white;
      height: 3rem;

      &:hover {
        color: $roseGold;
      }

      &-active {
        color: $pink;

        &:hover {
          color: $pink;
        }
      }
    }
  }

  &-questions {
    display: flex;
    flex-direction: column;
    width: calc(80% - 4rem);
    margin-left: 4rem;

    &-question {
      display: flex;
      justify-content: center;
      padding: 1rem;
      flex-direction: column;
      cursor: pointer;
      transition: all 0.3s ease;
      font-size: 1.2rem;
      font-family: $header-font-family, sans-serif;
      border: 1px solid transparentize($white, 0.8);
      color: $white;
      min-height: 3rem;
      margin-bottom: 1rem;
      border-radius: $smallRadius;
      position: relative;
      @include fadeIn(1s);

      h2 {
        font-size: 1.4rem;
        font-family: $header-font-family, sans-serif;
        color: $white;
        height: 3rem;
        display: flex;
        align-items: center;
      }

      p {
        font-size: 1.2rem;
        color: $grey;
        margin-top: 1rem;
        width: 90%;
      }

      svg {
        position: absolute;
        right: 1rem;
        bottom: 1rem;
        font-size: 1.5rem;
        color: $white;
        transform: rotate(180deg);
        transition: .3s;
      }

      svg#open {
        transform: none;
      }
    }
  }
}