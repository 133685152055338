@import "../../../Utils/mixins.scss";


.home {
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;

  &-announcement {
    border: 1px solid transparentize($roseGold, .6);
    border-radius: $mediumRadius;
    color: transparentize($white, .2);
    font-size: 1rem;
    font-weight: 400;
    padding: .7rem 2rem;
    cursor: pointer;
    transition: .3s;
    background: transparentize($roseGold, .8);


    &:hover {
      background-color: $offWhite03;
    }
  }

  &-titleWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 80vw;
    height: 70vh;

    &-buttonsWrapper {
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 3rem;

      a {
        all: unset;
      }

      .home-titleWrapper-buttonsWrapper-button {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: flex-start;
        width: 30%;
        min-height: 3rem;
        padding: 1.3rem 1rem;
        padding-bottom: 3rem;
        margin: 1rem;
        cursor: pointer;
        transition: 0.3s;
        color: $dirtyWhite;
        border: 1px solid rgba(255, 255, 255, .12);
        border-radius: 0.5rem;
        background-color: $offWhite01;
        font-optical-sizing: auto;

        h1 {
          font-family: "DM Sans", sans-serif;
          font-size: 1.3rem;
          font-weight: 500;
        }

        h2 {
          font-family: "DM Sans", sans-serif;
          font-size: 1rem;
          font-weight: 300;
          margin-top: 0.5rem;
          width: 90%;
        }

        &-icon {
          position: absolute;
          bottom: 1rem;
          right: 1.2rem;
          width: 1.5rem;
          height: 1.5rem;
          transition: 0.3s;
          border-radius: 50%;

        }

        &:hover {
          .home-titleWrapper-buttonsWrapper-button-icon {
            background-color: $offWhite03;
            transform: translateX(0.5rem);
          }
        }
      }

      .home-titleWrapper-buttonsWrapper-button:hover {
        background-color: $offWhite03;
      }

      #second {
        min-height: 7rem;
        border: none;
        background: linear-gradient(90deg, transparentize($pink, .7), transparentize($roseGold, .6));

      }
    }
  }

  &-tiles {
    margin-top: 4rem;
    width: 90%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1rem;

    &-tile {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 1.5rem;
      border-radius: $mediumRadius;
      transition: .3s;
      cursor: pointer;
      height: 18rem;
      position: relative;
      background: transparentize($roseGold, .8);
      //background: linear-gradient(120deg, transparentize($pink, .95), transparentize($roseGold, .5));
      box-shadow: 0 0 1rem .5rem rgba(0, 0, 0, .1);

      &:hover {
        transform: translateY(-0.5rem);
        box-shadow: 0 0 1.5rem rgba(0, 0, 0, .2);

        svg {
          color: $white;
        }
      }

      svg {
        position: absolute;
        top: 1.5rem;
        left: 1.5rem;
        width: 3rem;
        height: 3rem;
        color: transparentize($white, .3);
        transition: .3s;
      }

      &-figure {
        font-size: 3rem;
        margin-top: 6rem;
        color: $gold;
        font-family: $header-font-family, sans-serif;
        font-weight: 500;
      }

      &-title {
        font-family: "DM Sans", sans-serif;
        font-size: 1.3rem;
        font-weight: 500;
        margin-top: 1rem;
        color: $dirtyWhite;
      }

      &-description {
        font-family: "DM Sans", sans-serif;
        font-size: 1rem;
        font-weight: 300;
        color: $dirtyWhite;
        margin-top: 0.5rem;
      }

    }
  }


  &-background {
    position: absolute;
    bottom: 0;
    left: 4%;
    width: 90%;
    height: 70vh;
    object-fit: cover;
  }

  &-title {
    font-family: $header-font-family, sans-serif;
    font-size: 4rem;
    font-weight: 600;
    position: relative;
    z-index: 3;
    color: $white;
    text-align: center;
    margin-top: 7rem;

    b {
      font-weight: 800;
      //color: $pink;
      @include gradientText(60deg, $pink, $roseGold);
    }
  }

  #upper {
    font-size: 3.5rem;
    font-weight: 400;
    margin-top: 5rem;
    color: $white;
  }

  &-description {
    font-size: 2rem;
    font-weight: 500;
    position: relative;
    z-index: 3;
    width: 80%;
    margin-top: 1.2rem;
    text-align: center;
    color: $white;
  }

}
