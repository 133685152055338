@import '../../../../../Utils/mixins.scss';
@import "../../../../../Utils/animations";
@import "../../../../../Utils/colors.scss";

.aiExpert {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 80vh;
  background-color: $darkerBackground;
  border-radius: 3rem;
  padding-top: 3.5rem;
  border: 1px solid transparentize($white, 0.7);
}

#selectedCard {
  border-color: transparentize($pink, .2);
  box-shadow: none !important;
}

#no-interact {
  box-shadow: none !important;
}

.no-interact {
  box-shadow: none !important;
}

.interact {
  @include interactableHighlight;
}
