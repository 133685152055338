@import "../../../../Utils/mixins.scss";

.staticProps {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;

  h1 {
    @include bigHeader;
    color: $white;
    margin-top: 4rem;
    margin-bottom: 2rem;
  }

  &-itemsWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    height: 100%;
  }

  &-item {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    border: $thinBorder;
    border-radius: $smallRadius;
    background: $darkBackground05;
    height: 22rem;
    flex: 1;
    min-width: 20rem;
    margin: 1rem;
    transition: .3s;
    box-shadow: 0 0 1rem .2rem rgba(0, 0, 0, .3);


    svg {
      font-size: 3rem;
      margin: 0 1.5rem;
      color: $roseGold;
      transition: .3s;
    }

    h1 {
      font-family: $header-font-family, sans-serif;
      font-size: 1.8rem;
      font-weight: 400;
      margin-top: 1rem;
      margin-bottom: 1rem;
      margin-left: 1.5rem;
      width: calc(100% - 3rem);
      color: $white;
      display: flex;
      align-items: flex-end;


    }

    p {
      font-family: $title-font-family, sans-serif;
      font-size: 1rem;
      font-weight: 400;
      margin-bottom: 2rem;
      margin-left: 1.5rem;
      width: calc(100% - 3rem);
      color: $grey;
      height: 6rem;
    }

    &:hover {
      background-color: $accentsColor;
      transform: scale(1.05);
    }
  }
}

.staticProps#alt {
  .staticProps-item {
    svg {
      color: $gold;
    }
  }
}

@include tablets {
  .staticProps {
    width: 90%;

    &-itemsWrapper {
      flex-direction: column;
    }

    &-item {
      padding-top: 2rem;
    }
  }

  .staticProps-item {
    height: 20rem;
  }
}