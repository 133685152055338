@import "../../../../Utils/mixins.scss";

.staticGrid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90%;

  h1 {
    @include bigHeader;
    color: $white;
    margin-top: 4rem;
  }

  &-itemsWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;
  }

  &-item {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    border-radius: 1rem;
    height: 40vh;
    min-width: 20rem;
    width: 45%;
    margin: 1rem;
    transition: .3s;
    box-shadow: 0 0 1rem .2rem rgba(0, 0, 0, .3);
    background-position: center;
    background-size: cover;
    position: relative;

    &-inside {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      width: 100%;
      border-radius: 1rem;
      height: 100%;
      background-color: transparentize($darkerBackground, .1);

    }

    &-description {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      width: 100%;
      border-radius: 1rem;
      height: 100%;
      background-color: rgba(0, 0, 0, .6);
      backdrop-filter: blur(.5rem);
    }


    h1 {
      font-family: $header-font-family, sans-serif;
      font-size: 1.8rem;
      font-weight: 400;
      margin-top: 2rem;
      margin-bottom: 1rem;
      margin-left: 1.5rem;
      width: calc(100% - 3rem);
      color: $white;
    }

    h2 {
      margin-top: 3.5rem;
      font-family: $title-font-family, sans-serif;
      font-size: 1.3rem;
      font-weight: 400;
      margin-bottom: 2rem;
      margin-left: 1.5rem;
      width: 75%;
      color: $white;
    }

    p {
      font-family: $title-font-family, sans-serif;
      font-size: 1rem;
      font-weight: 400;
      margin-bottom: 2rem;
      margin-left: 1.5rem;
      width: 75%;
      color: $grey;
    }

    button {
      all: unset;
      position: absolute;
      align-self: flex-end;
      margin-right: 1.5rem;
      margin-bottom: 1.5rem;
      padding: .5rem 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: .8rem;
      cursor: pointer;
      transition: .3s;
      background-color: transparentize($pink, .5);
      box-shadow: 0 0 1rem .1rem rgba(0, 0, 0, 0.2);
      font-size: 1.2rem;
      color: $white;
      font-family: $paragraph-font-family, sans-serif;

      &:hover {
        background-color: transparentize($pink, .2);
      }
    }

    &:hover {
      background-color: $accentsColor;
      transform: scale(1.01);
    }
  }
}

@include tablets {
  .staticGrid {
    h1 {
      margin-top: 2rem;
    }

    &-item {
      height: 25rem;
      min-width: 15rem;
      width: 90%;
    }
  }
}