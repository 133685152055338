@import "../../Utils/mixins.scss";

.pricing-wrapper {
  @include defaultPage;
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 4rem;
  min-height: unset;
}

.blobPricing {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  filter: blur(6rem);
  opacity: .5;
}

.blobPricing1 {
  top: -50vw;
  left: -45vw;
  transform: scale(.5);
}

.blobPricing2 {
  top: -10vh;
  left: 45vw;
  transform: scale(.5);
  opacity: .5;
}

.blobPricing3 {
  top: 80vh;
  right: 45vw;
  transform: scale(.5);
  opacity: .5;
}

.blobPricing4 {
  top: 180vh;
  left: -50vw;
  transform: scale(.5);
  opacity: .5;
}

.blobPricing5 {
  top: 120vh;
  left: 85vw;
  transform: scale(.5);
  opacity: .5;
}

.pricing {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  z-index: 10;

  .titleComponent {
    margin-top: 7rem;
    margin-bottom: 4rem;
  }

  &-slider {
    width: 60%;
    margin-top: 2rem;
    padding: 2rem;
    border-radius: $mediumRadius;
    border: $thinBorder;
    background-color: transparentize($darkBackground, .5);

    &-texts {
      display: flex;
      justify-content: space-between;

      p {
        font-size: 1.2rem;
        font-weight: 500;
        margin-bottom: 2rem;
        color: $white;
      }

      &-number {
        color: $pink !important;
      }
    }

    .rc-slider {
      &-handle,
      &-handle:focus,
      &-track,
      &-handle-dragging,
      &-dot-active {
        background-color: $pink;
        border-color: $pink !important;
        box-shadow: none !important;
      }
    }

  }

  &-cards {
    width: calc(60% + 6rem);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 2rem;
  }

  &-card {
    flex: 1;
    background-color: transparentize($darkBackground, .5);
    margin: 1rem;
    border-radius: $mediumRadius;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: $thinBorder;
    padding-top: 1rem;
    padding-bottom: 2rem;
    position: relative;
    transition: .3s;

    h1 {
      position: absolute;
      top: -.8rem;
      background-color: $pink;
      padding: .2rem 1rem;
      border-radius: $mediumRadius;
      color: $white;
      font-size: 1rem;
      font-weight: 500;

    }

    &-header {
      display: flex;
      flex-direction: column;
      padding: 1.5rem;
      color: $white;
      font-size: 1.5rem;
      width: calc(90% - 3rem);
      height: 15rem;

      h2 {
        font-size: 2rem;
        font-weight: 500;
        margin-bottom: .5rem;
      }

      p {
        font-size: 1.2rem;
        font-weight: 300;
        height: 5rem;
      }

      h3 {
        font-size: 1.5rem;
        font-weight: 500;
        margin-top: 2rem;
        margin-bottom: 2rem;

        span {
          font-size: 1.2rem;
          font-weight: 300;
          color: $grey;
        }
      }
    }

    button {
      @include buttonAlt($pink);
      align-self: center;
      width: calc(90% - 1.5rem);
      margin-right: 0;
    }

    &-features {
      display: flex;
      flex-direction: column;
      color: $white;
      align-self: center;
      width: 90%;
      margin-top: 2rem;

      h4 {
        font-size: 1.1rem;
        font-weight: 500;
        margin-bottom: .5rem;
      }

      p {
        font-size: 1rem;
        font-weight: 300;
        height: 50%;
        overflow: hidden;
        text-align: start;
        padding: .5rem 0;
      }
    }

    &:hover {
      transform: scale(1.02);
      border-color: transparentize($white, .8);
    }
  }

  &-features {
    background-color: transparentize($darkBackground, .3);
    margin: 3rem;
    border-radius: $mediumRadius;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: $thinBorder;
    position: relative;
    transition: .3s;
    height: 20rem;
    width: calc(60% + 4rem);

    h2 {
      font-size: 1.8rem;
      font-weight: 400;
      margin-top: 2rem;
      color: $white;
      text-align: start;
      width: 95%;
    }

    h3 {
      font-size: 1.5rem;
      font-weight: 500;
      margin-top: 2rem;
      margin-bottom: 2rem;
      color: $white;
    }
  }

  .faq {
    margin-bottom: 5rem;
  }
}

@include tablets {
  .pricing {

    &-slider {
      width: 80%;
    }

    &-cards {
      flex-direction: column;
      width: 100% !important;
      align-items: unset;

      &-card {
        width: 90% !important;
      }
    }

    &-features {
      width: 90% !important;

      h2, h3 {
        w\idth: 90%;
        text-align: center;
      }

    }
  }
}