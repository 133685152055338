@import "../../../../Utils/mixins";

.userInput-searchBar {
  position: absolute;
  height: 12rem;
  top: -14rem;
  left: 0;
  width: calc(100% - 2rem);
  padding: .5rem 1rem;
  border: .1rem solid transparentize($white, 0.8);
  border-radius: $smallRadius;
  background: $darkerBackground;
  box-shadow: 1vh 1vh 5vh rgba(0, 0, 0, 0.25);

  input {
    @include input($white);
    margin-top: .5rem;
    width: 100%;
    padding: 0;
    padding-bottom: .5rem;
    border: unset;
  }

  &-items {
    height: 10rem;
    overflow-y: auto;

    &-item {
      padding: .5rem;
      cursor: pointer;

      h1 {
        font-family: $header-font-family, sans-serif;
        font-weight: 300;
        color: $white;
        font-size: 1.2rem;
      }

      &:hover {
        h1 {
          color: $gold;
        }
      }
    }
  }
}