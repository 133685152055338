@import "../../Utils/mixins";

.documentUploadModal-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(0.2rem);
  display: flex;
  align-items: center;
  justify-content: center;
}

.documentUploadModal {
  background: $darkerBackground;
  width: 35rem;
  min-height: 20rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: $smallRadius;
  box-shadow: 0 0 1rem .5rem rgba(0, 0, 0, 0.2);

  &-title {
    display: flex;
    font-family: $header-font-family, sans-serif;
    font-size: 2rem;
    font-weight: 300;
    color: $gold
  }

  &-dropzone {
    margin-top: 1rem;
    height: 15rem;
    border: 1px dashed transparentize($white, 0.5);
    border-radius: $smallRadius;
    display: flex;
    justify-content: center;
    align-items: center;

    &-icon {
      color: $white;
      font-size: 1.5rem;
    }

    p {
      width: 50%;
      text-align: center;
      color: $white;
    }
  }

  &-buttons {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
  }

  &-cancel, &-proceed {
    width: 15%;
    text-align: center;
    padding: .5rem;
    font-size: 1rem;
    font-family: $header-font-family, sans-serif;
    font-weight: 400;
    border-radius: $smallRadius;
    transition: .3s;
    margin-left: 1rem;
  }

  &-loading {
    color: $gold;
    cursor: pointer;
    height: 1rem;
    width: 1rem;
    animation: spin .8s linear infinite;
  }

  &-proceed {
    background: $gold;
    color: $darkerBackground;
    cursor: pointer;

    &:hover {
      background: $gold;
      color: $white;
    }
  }

  &-cancel {
    background: $darkerBackground;
    color: $white;
    transition: .3s;
    cursor: pointer;
    border: .05rem solid desaturate($white, 50%);

    &:hover {
      background: $darkBackground;
    }
  }
}


@include phoneLandscape {
  .modal {
    &-content {
      width: 100%;
    }
  }
}