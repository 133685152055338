@import '../../../Utils/mixins.scss';
@import "../../../Utils/animations";


.ctaUsecase {
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 10rem 0;

  &-title {
    text-align: center;
    width: 50%;

    &-highlight {
      @include gradientText(30deg, $white, $roseGold);
    }

  }

  &-subtitle {
    font-family: $paragraph-font-family;
    font-size: 1.4rem;
    color: $grey;
    margin-top: 1rem;
    width: 35%;
    text-align: center;
  }

  &-button {
    @include buttonAlt($pink);
    margin: 0;
    margin-top: 3rem;
    font-weight: 500;
    background: linear-gradient(45deg, $gold, $pink);

  }
}

@include tablets {
  .ctaUsecase {
    &-title {
      width: 70%;
    }

    &-subtitle {
      width: 80%;
    }
  }
}

@include phoneLandscape {
  .ctaUsecase {
    &-title {
      width: 90%;
    }

    &-subtitle {
      width: 90%;
    }

    &-button {
      font-size: 1.2rem;
      width: 70%;
      text-align: center;
    }
  }
}