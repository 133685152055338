@import "../../../../Utils/mixins";

.message-images-modal {

  &-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(0.5rem);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-main {
    max-height: 90vh;
    max-width: 100vw;
    object-fit: contain;
    transform: scale(1.5);
  }

  &-content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex: 1;
    height: 100vh;
  }

  &-inspector {
    flex: 1;
    max-width: 35%;

    .chat-inspector {
      width: calc(100% - 2rem) !important;
      margin-top: unset;
      height: 100vh;
    }
  }

  &-text {
    font-size: 3rem;
    font-weight: 400;
    text-align: center;
    color: $white;
    margin-bottom: 1rem;
  }

  &-arrow {
    position: absolute;
    bottom: 2rem;
    width: 3rem;
    height: 3rem;
    color: $gold;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: .3s;

    svg {
      font-size: 2rem;
      color: $white;
    }

    &:hover {
      color: $pink;
    }

    &-left {
      left: calc(50% - 3rem);
    }

    &-right {
      right: calc(50% - 3rem);
    }
  }


  &-close {
    position: absolute;
    top: 1rem;
    left: 1rem;
    color: $white;
    font-size: 2rem;
    cursor: pointer;
    transition: .3s;
    z-index: 10;

    &:hover {
      color: $pink;
    }
  }

  table {
    max-height: 80vh;
    max-width: 90vw;
    object-fit: contain;
    background: $white;
    border-collapse: collapse;

    th, td {
      padding: 1rem 2rem;
      text-align: center;
      border: 1px solid $gold;
      font-weight: 400;
      font-size: 1.4rem;
      font-family: $header-font-family;
    }

    th {
      background: $gold;
    }

  }
}
