@import "../../../../Utils/mixins.scss";

.staticFlow {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 80%;
  min-height: 20vh;
  padding: 3rem 0;
  border-top: $thinBorder;

  &-title {
    @include staticHeader;
    margin-left: 6rem;
  }

  &-flow {
    display: flex;
    flex-direction: column;
    width: calc(100% - 12rem);
    margin-left: 6rem;

    &-bullet {
      position: relative;
      margin-top: 2rem;
      border-top: $thinBorder;

      &-number {
        font-family: $header-font-family, sans-serif;
        font-size: 1rem;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -.8rem;
        left: -.8rem;
        color: $white;
        width: 1.6rem;
        height: 1.6rem;
        background: $pink;
        border-radius: 25%;
      }

      &-text {
        display: flex;
        width: 100%;
        min-height: 8rem;
        padding: 1.5rem 0;

        &-title {
          font-family: $header-font-family, sans-serif;
          font-size: 1.3rem;
          font-weight: 500;
          width: 25%;
          margin-bottom: .5rem;
          color: $white;
        }

        &-text {
          font-family: $header-font-family, sans-serif;
          font-size: 1.1rem;
          font-weight: 400;
          width: 75%;
          margin-bottom: .5rem;
          color: $white;
        }
      }
    }
  }

}