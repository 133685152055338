@import '../../Utils/mixins.scss';

@include tablets {
  .searchBar {
    flex-direction: column;
    height: 100%;
    background: $darkerBackground;
    padding: 1rem 0;

    &-form {
      padding: 0;
      height: 4rem;
      width: 90%;

      input {
        height: 100%;
        width: 100%;
        text-align: center;
        border: 1px solid transparentize($gold, .5);
      }
    }

    &-select {
      width: 90%;
      margin-top: 1rem;
      border: 1px solid transparentize($gold, .5);
      height: 4rem;
    }

    .sendButton {
      @include button($gold);
      margin-top: 2rem;
      height: 3rem;
      width: 50%;
      background: transparentize($gold, .9);
    }
  }
}