@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@mixin fadeIn($time) {
  animation: fadeIn $time forwards;
}


@mixin textFadeIn($time) {
  animation: fadeIn $time forwards;
}