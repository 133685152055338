@import './colors.scss';
@import './fonts.scss';
@import './components.scss';
@import "./textMixins";

$defaultPageWidth: 150vh;
$aboutPageHeight: 200vh;

$smallRadius: .5rem;
$mediumRadius: calc(2 * $smallRadius);
$largeRadius: calc(3 * $smallRadius);

$thinBorder: 1px solid transparentize($white, .9);

@mixin button($c) {
  all: unset;
  padding: .3rem 1rem;
  border-right: 2px solid $c;
  border-left: 2px solid $c;
  font-family: $header-font-family, sans-serif;
  font-weight: 300;
  cursor: pointer;
  transition: .3s;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;

  &:hover {
    background-color: desaturate(transparentize($c, .5), 20%);
  }
}

@mixin ctaButton($c) {
  all: unset;
  font-family: $header-font-family, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  margin-left: $smallRadius;
  color: $white;
  box-shadow: 0 0 0 .1rem transparentize($darkestBlack, 0.9);
  background: linear-gradient(45deg,
      transparentize($c, .2),
      transparentize($gold, .1));
  padding: .4rem 1.7rem;
  border-radius: .8rem;
  transition: .3s;
  cursor: pointer;
  background-size: 200% 200%;

  &:hover {
    background-position: right;
  }


}

@mixin buttonAlt($c) {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  padding: .8rem .8rem;
  border-radius: $smallRadius;
  cursor: pointer;
  transition: .3s;
  background-color: transparentize($c, .5);
  box-shadow: 0 0 1rem .1rem rgba(0, 0, 0, 0.4);
  min-width: 10rem;
  font-size: 1rem;
  color: $white;
  font-family: $paragraph-font-family, sans-serif;

  &:hover {
    background-color: transparentize($c, .2);
  }

}


@mixin tag {
  margin: 0 .5rem;
  padding: .2rem .8rem;
  background-color: transparentize($accentsColor, .2);
  box-shadow: 0 0 .5rem .1rem rgba(0, 0, 0, 0.2);
  color: $white;
  border: $thinBorder;
  font-family: $paragraph-font-family, sans-serif;
  border-radius: .3rem;
  font-size: .8rem;
  font-weight: 300;
  cursor: pointer;
  transition: .1s linear;
  position: relative;

  &:hover {
    background-color: $accentsColor;
    border: 1px solid transparentize($white, .5);
  }

  &-delete {
    position: absolute;
    top: -.4rem;
    right: -.4rem;
    width: .8rem;
    height: .8rem;
    border-radius: 50%;
    transition: .3s;
    background: transparentize($pink, .5);

    &:hover {
      background: $pink;
      color: $white;
    }
  }

  &-deleteCreating {
    position: absolute;
    top: calc(50% - .5rem);
    right: -3rem;
    width: 1rem;
    height: 1rem;
    transition: .3s;
    border-radius: 50%;

    &:hover {
      color: $pink;
    }
  }

  &-confirm {
    position: absolute;
    top: calc(50% - .5rem);
    right: -1.5rem;
    width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s;

    &:hover {
      color: $gold;
    }
  }
}

@mixin input($c) {
  all: unset;
  cursor: text;
  font-family: $header-font-family, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  width: calc(90% - 2rem);
  padding: .5rem 1rem;
  text-align: start;
  white-space: pre-wrap;
  word-break: break-word;
  color: $white;
  border: .1rem solid transparentize($white, 0.9);
  box-shadow: 0 0 0 .1rem transparentize($darkestBlack, 0.9);

  &:focus {
    border-color: $c;
  }
}

@mixin defaultPage {
  width: 100%;
  min-height: 100vh;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin gradientText($deg, $color1, $color2) {
  background: linear-gradient($deg, $color1, $color2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin gradientText3($deg, $color1, $color2, $color3) {
  background: linear-gradient($deg, $color1, $color2, $color3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin whiteGlass {
  background-color: $offWhite05;
  box-shadow: 0.5vh 0.5vh 2vh rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10vh);
}

@mixin whiteGlassLight {
  background-color: $offWhite05;
  box-shadow: 0.5vh 0.5vh 2vh rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10vh);
}

@mixin glass {
  background: transparentize($darkerBackground, .5);
  //backdrop-filter: blur(5rem);
  box-shadow: 0 0 1rem .5rem rgba(0, 0, 0, 0.3);
}

@mixin shadow {
  box-shadow: 0 0 1rem .5rem rgba(0, 0, 0, 0.3);

}

@mixin bigHeader {
  font-size: 4rem;
  font-family: $header-font-family, sans-serif;
  font-weight: 500;
}

@mixin mediumHeader {
  font-size: 1.4rem;
  font-family: $header-font-family, sans-serif;
  font-weight: 300;
}

@mixin smallHeader {
  font-size: .9rem;
  font-family: $header-font-family, sans-serif;
  font-weight: 300;
}

@mixin mediumParagraph {
  font-size: 1.3rem;
  font-family: $header-font-family, sans-serif;
  font-weight: 300;
}

@mixin interactableHighlight($shadow-color: rgb(255, 255, 255), $duration: 1s) {
  animation: pulse-shadow $duration infinite;
  box-shadow: 0 0 2px $shadow-color;

  @keyframes pulse-shadow {
    0% {
      box-shadow: 0 0 2px rgba($shadow-color, 0.5);
    }

    50% {
      box-shadow: 0 0 3px rgba($shadow-color, 0.8);
    }

    100% {
      box-shadow: 0 0 3px rgba($shadow-color, 0.5);
    }
  }
}


@mixin laptops {
  @media (max-width: 1400px) {
    @content;
  }
}

@mixin smallLaptops {
  @media (max-width: 1199px) {
    @content;
  }
}

@mixin tablets {
  @media (max-width: 991px) {
    @content;
  }
}

@mixin phoneLandscape {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin phone {
  @media (max-width: 575px) {
    @content;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
