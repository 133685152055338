@import "../../../../Utils/mixins";

.message-images {
  margin-top: 2rem;
  display: flex;
  gap: 1rem;

  &-item {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 8px;
    padding: .5rem;
    transition: .3s;
    cursor: pointer;
    min-width: 5rem;

    img {
      max-width: 100%;
      max-height: 12rem;
      object-fit: cover;
      border-radius: $smallRadius;
    }

    &-table {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      svg {
        font-size: 3rem;
        color: $white;
      }

      p {
        color: $white;
        font-size: 1.4rem;
        font-weight: 400;
        text-align: center;
        margin-top: 1rem;
      }
    }


    &:hover {
      background-color: transparentize($white, .8);
    }
  }

}

.message-formulas {
  display: block;
  margin-top: 0;

  &-item {
    display: block;
    color: $white;
    margin-top: 1rem;
  }
}

.generationStage {
  display: flex;
  align-items: center;
  margin-left: 2rem;
  margin-top: .5rem;
  width: 20rem;
  padding: .5rem 1rem;
  background: transparentize($darkBackground, .5);
  border: 1px solid transparentize($white, .7);
  border-radius: $smallRadius;
  font-size: 1rem;
  color: $gold;

  svg {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    color: $gold;
    animation: spin 1s linear infinite;
  }

}