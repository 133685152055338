@import "../../Utils/mixins.scss";
@import "../../Utils/animations.scss";

.navbar {
  position: fixed;
  z-index: 5;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  transition: .3s;

  .navbarMain {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 4rem;
    transition: all 0.5s;

    .midSection {
      display: flex;
      align-items: center;

    }

    .navbar-user-wrapper {
      position: relative;
      width: 1.6em;
      height: 1.6rem;
      margin-right: 1rem;
      margin-left: .5rem;
    }

    .navbar-user {
      width: 100%;
      height: 100%;
      cursor: pointer;
      transition: 0.3s all;
      color: $white;

      &:hover {
        color: $gold;
      }

      &-dropdown {
        position: absolute;
        z-index: 5;
        top: 180%;
        right: 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 15rem;
        min-height: 10rem;
        border-radius: $smallRadius;
        background-color: transparentize($darkerBackground, .05);
        box-shadow: 0 0 1rem .1rem rgba(0, 0, 0, 0.2);
        border: 2px solid transparentize($white, .9);

        &-photo {
          width: 3rem;
          height: 3rem;
          margin-top: 1.5rem;
          color: $white;
          border-radius: $smallRadius;
        }

        &-name {
          font-size: 1.2rem;
          font-weight: 300;
          margin: 1rem 0;
          color: $white;
        }

        &-item {
          display: flex;
          align-items: center;
          width: 100%;
          padding: 0;
          transition: 0.3s all;

          &-icon {
            height: 1.2rem;
            color: $white;
            margin-left: 1rem;
          }

          &-text {
            font-size: 1.3rem;
            font-weight: 300;
            cursor: pointer;
            transition: 0.3s all;
            text-align: center;
            color: $white;
          }

          &:hover {

            .navbar-user-dropdown-item-text,
            .navbar-user-dropdown-item-icon {
              color: $gold;
            }
          }
        }
      }
    }


  }

  .logos {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    cursor: pointer;
    transition: all 0.5s;

    .appiIcon {
      height: 2.5rem;
      transition: all 0.5s;
    }

    .appiText {
      height: 2.5rem;
      margin-left: 3vh;
      animation: fadeIn 1s;
    }


  }

  .line {
    background: whitesmoke
  }

  .navbar-link {
    all: unset;
    font-family: $title-font-family, sans-serif;
    font-size: 1.3rem;
    font-weight: 400;
    position: relative;
    padding: 1rem .8rem;
    cursor: pointer;
    transition: .3s all;
    color: $white;
    display: flex;
    align-items: center;

    &-icon {
      margin-left: .2rem;
      transition: .3s;
    }
  }

  .navbar-link:hover {
    color: $gold;

    .navbar-link-icon {
      transform: rotate(180deg);
    }
  }

  &-midSection {
    &-modal-wrapper {
      position: absolute;
      top: calc(100%);
      left: -8rem;
      width: 25rem;
    }

    &-modal {
      margin-top: .5rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-radius: $smallRadius;
      background-color: transparentize($darkestBlack, .05);
      box-shadow: 0 0 1rem .1rem rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(5vh);
      height: 100%;
      width: 100%;

      &-item {
        all: unset;
        display: flex;
        align-items: flex-start;
        cursor: pointer;
        transition: .3s;
        color: $white;
        padding: 1rem;
        height: 100%;
        width: calc(100% - 2rem);
        border-radius: $smallRadius;

        &-icon {
          width: 1.2rem;
          height: 1.2rem;
          transition: .3s;
          color: $white;
        }

        &-text {
          display: flex;
          flex-direction: column;
          width: calc(100% - 1.7rem);
          margin-left: 1rem;

          h1 {
            font-family: $header-font-family, sans-serif;
            font-size: 1rem;
            font-weight: 400;
            margin-bottom: .2rem;
            transition: .3s;

          }

          p {
            font-family: $header-font-family, sans-serif;
            font-size: 1rem;
            font-weight: 400;
            padding: 0;
            width: 95%;
            transition: .3s;
            color: $grey;
          }
        }

        &:hover {
          background-color: transparentize($white, .8);

          .navbar-midSection-modal-item {

            //&-icon, &-text > h1, &-text > p {
            //  color: $gold;
            //}
          }
        }
      }
    }
  }

  .login-button {
    cursor: pointer;

    span {
      background: linear-gradient(45deg,
          transparentize($pink, .7),
          transparentize($pink, .4));
      border: 1px solid transparentize($pink, .5);
      padding: .4rem 1.7rem;
      color: $white;
      border-radius: .8rem;
      transition: .3s;
    }

    &:hover {
      span {
        background-color: transparentize($pink, .2);
      }
    }
  }

}

.navbarHome {
  .navbarMain {
    height: 7rem;
  }

  .appiIcon {
    margin-left: 1rem;
    transform: scale(1.5);
    filter: brightness(100);
  }

}

.navbarOther {
  //not home
  border-bottom: 0.2vh solid rgba(255, 255, 255, .1);
  background-color: transparentize($darkerBackground, .4);
  box-shadow: 0 0.5vh .5vh rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(1rem);

  .appiIcon {
    margin-left: 1rem;
    transform: translateY(.3vh);
    filter: brightness(1);
  }

  .navbar-link {
    color: $white;
  }

  .line {
    color: $white;
  }
}

.navbarInvisible {
  width: 100%;

  .appiIcon {
    display: none;
  }

  .appiText {
    display: none;
  }

}

.navbar-open {
  border-bottom: 0.2vh solid rgba(0, 0, 0, 0.2);
  background-color: transparentize($darkestBlack, .05);
  box-shadow: 0 0.5vh .5vh rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5vh);

  .appiIcon {
    margin-left: 1rem;
    transform: translateY(.3vh);
    filter: brightness(1);
  }

  .line {
    color: $white;
  }
}

.navbarInvisibleScroll {
  //chat scroll
  width: 100%;
  border-bottom: 0.2vh solid rgba(0, 0, 0, 0.2);
  background-color: transparentize($darkerBackground, .5);
  box-shadow: 0 0.5vh .5vh rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5vh);

  .appiIcon {
    display: none;
  }

  .appiText {
    display: none;
  }
}

.navbarNoBG {
  background-color: transparent;

  .navbarMain {
    border-bottom: $thinBorder;
  }

  .appiIcon {
    margin-left: 1rem;
    transform: translateY(.3vh);
    filter: brightness(1);
  }

}

.navbarDefault {
  border-bottom: 0.2vh solid rgba(0, 0, 0, 0.2);

  background-color: transparentize($darkerBackground, .5);
  box-shadow: 0 0.5vh .5vh rgba(0, 0, 0, 0.2);

  .appiIcon {
    margin-left: 2.5vh;
    transform: translateY(.3vh);
    filter: brightness(1);
  }

  backdrop-filter: blur(5vh);

}
