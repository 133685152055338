@import "../../../../Utils/mixins.scss";

.solutionsBusinesses {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 3;
  background: transparentize($darkerBackground, .5);

  &-wrapper {
    overflow: hidden;
    position: relative;
    z-index: 0;
  }

  &-documents {
    .staticListPoint {
      height: 30rem;
    }
  }

  &-security {
    .staticList-list {
      grid-template-columns: 1fr 1fr;
    }

    .staticListPoint {
      height: 30rem;
    }

    &-div {
      width: 100%;
      height: 100%;
    }
  }

  &-organizations {
    .staticList-list {
      grid-template-columns: 1fr 1fr;
    }

    .staticListPoint {
      height: 30rem;
    }
  }
}

.enterprise {
  &-security {
    .staticList-list {
      grid-template-columns: 1fr 1fr;
      gap: 3rem;
      display: flex;
    }

    .staticList-list2 {
      align-items: center;
    }

    .staticListPoint {
      justify-content: flex-start;
      width: 80%;
      height: 30rem;

      &-text {
        h1 {
          font-size: 1.8rem;
        }

        h2 {
          font-size: 1.5rem;
        }
      }

    }
  }

  &-images {
    &-shareExperts {
      width: 100%;
      height: 100%;
      @include glass;
      border: $thinBorder;
      border-radius: 1rem;
      margin: 2rem 1rem 1rem;
      color: $white;
      font-size: 5rem;
      position: relative;

      &-line {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 9rem;
        position: absolute;

        &-dot {
          width: 0.7rem;
          height: 0.7rem;
          background-color: $white;
          border-radius: 50%;
        }

        &:nth-child(1) {
          top: 50%;
          left: calc(50% - 4.5rem);
        }

        &:nth-child(2) {
          left: calc(50% - 4.5rem);
          rotate: -15deg;
          top: 30%;
        }

        &:nth-child(3) {
          left: calc(50% - 4.5rem);
          rotate: 15deg;
          top: 70%;
        }
      }

      &-expert {
        position: absolute;
        top: calc(50% - 2.5rem);
        left: 15%;
      }

      &-users {
        position: absolute;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        right: 15%;
        top: 0;
      }
    }

    &-knowledgebase {
      width: 100%;
      height: 100%;
      @include glass;
      border: $thinBorder;
      border-radius: 1rem;
      margin: 1rem;
      margin-top: 2rem;
      font-size: 5rem;
      color: $white;
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;

      &-row {
        display: flex;
        width: 70%;
        justify-content: space-between;
        flex-direction: row;
        margin-top: 3rem;
      }

      &-dot {
        width: 1rem;
        height: 1rem;
        background-color: $white;
        border-radius: 50%;
        margin-top: 2rem;
        margin-bottom: 2rem;
      }
    }
  }
}


.documentsComponent {
  width: 95%;
  height: 70% !important;
  min-height: 30rem;
  @include glass;
  border: $thinBorder;
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 1rem;
  color: $white;
  position: relative;

  &-document {
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;

    svg {
      width: 4rem;
    }


    &-icon {
      font-size: 4rem;
    }

    &:nth-child(1) {
      left: 10%;
      top: 10%;
    }

    &:nth-child(2) {
      left: 10%;
      bottom: 10%;
    }

    &:nth-child(3) {
      right: 10%;
      top: 10%;
    }

    &:nth-child(4) {
      right: 10%;
      bottom: 10%;
    }
  }

  &-arrow {
    width: 15rem;
    height: 15rem;
    position: absolute;

    svg {
      border-radius: 50%;

      .arrow-main-line,
      .arrow-pointer-line {
        stroke-dasharray: 100;
        stroke-dashoffset: 100;
        animation-fill-mode: forwards;
        animation-timing-function: ease-out;
      }

      .arrow-main-line {
        animation: drawLine .3s forwards; // Animating the main line
      }

      .arrow-pointer-line {
        animation: drawLine .5s forwards .3s; // Animating the pointers after the main line
      }
    }

    // Rotate specific arrows using nth-child
    &:nth-child(5) {
      transform: rotate(17deg);
      left: 20%;
      bottom: 43%;
    }

    &:nth-child(6) {
      transform: rotate(-17deg);
      left: 20%;
      top: 43%;
    }

    &:nth-child(7) {
      transform: rotate(197deg);
      right: 20%;
      top: 43%;
    }

    &:nth-child(8) {
      transform: rotate(163deg);
      right: 20%;
      bottom: 43%;
    }
  }

  &-appi {
    width: 5rem;
    position: absolute;
    left: calc(50% - 2.5rem);
    top: 42.5%;
  }
}

.arrow-stroke {
  stroke-dasharray: 200;
  stroke-dashoffset: 200;
  transition: stroke-dashoffset 1s ease;
}

/* Apply hover effect to parent container */
.documentsComponent:hover .arrow-stroke {
  stroke-dashoffset: 0;
}

@keyframes drawLine {
  to {
    stroke-dashoffset: 0;
  }
}


@include tablets {
  .documentsComponent-arrow {
    display: none;
  }
}

@include phoneLandscape {
  .solutionsBusinesses-organizations {
    .staticList-list {
      .enterprise-images-shareExperts-line-dot {
        display: none;
      }

      .enterprise-images-shareExperts-users {
        transform: scale(.5);
      }

      .enterprise-images-shareExperts-expert {
        transform: scale(.5);
      }
    }
  }
  .enterprise-images-knowledgebase-row {
    width: 100%;
  }
  .enterprise-images-knowledgebase > svg {
    transform: scale(.5);
  }
}