@import "../../Utils/mixins.scss";

.dashboard {
  @include defaultPage;
  position: relative;
  overflow: hidden;
  align-items: flex-start;
  flex-direction: row;
  background-color: $darkerBackground;

  &-loading {
    align-items: center;
    justify-content: center;

    &-icon {
      width: 2rem;
      height: 2rem;
      animation: spin 1s linear infinite;
      color: $gold;
    }
  }

  @mixin organizationSidebarMenu($c) {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    &-item {
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: .5rem;
      margin-bottom: .5rem;
      cursor: pointer;
      transition: .3s;

      &-img {
        width: 1.2rem;
        height: 1.2rem;
        margin-right: .7rem;
        margin-left: .7rem;
        color: $c;
      }

      &-title {
        font-family: $header-font-family, sans-serif;
        font-size: 1.2rem;
        font-weight: 300;
        color: $white;
      }

      &:hover {
        background-color: $c;
        border-radius: 1vh;

        .dashboard-sidebar-menu-item-img, .dashboard-sidebar-menu-developer-item-img {
          color: $darkerBackground;
        }

        .dashboard-sidebar-menu-item-title, .dashboard-sidebar-menu-developer-item-title {
          color: $darkerBackground;
          font-weight: 400;
        }
      }

      &-selected {
        background-color: $accentsColor;
        border-radius: $smallRadius;
      }
    }
  }

  &-sidebar {
    position: fixed;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 15rem;
    min-height: 100vh;
    border-right: $thinBorder;

    &-title {
      z-index: 6;
      display: flex;
      align-items: center;

      flex-direction: column;
      width: 100%;
      margin-top: 5rem;
      margin-bottom: 2rem;
      border-radius: 2vh;

      img {
        height: 4rem;
        border-radius: $smallRadius;
      }

      h1 {
        font-family: $header-font-family, sans-serif;
        font-size: 1.5rem;
        font-weight: 300;
        margin-top: 1rem;
        color: $white;
      }

      &-img {
        width: 4rem;
        height: 4rem;
        border-radius: 1rem;
        background-color: $white;
      }
    }

    &-menu {
      @include organizationSidebarMenu($gold);

      &-developer {
        margin-top: 1.5rem;
        @include organizationSidebarMenu($pink);
      }
    }
  }

  &-content {
    display: flex;
    flex: 1;
    justify-content: center;
    width: calc(100% - 15rem);
    margin-left: 15rem;
    margin-top: 4rem;
  }


}
