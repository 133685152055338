@import '../../../Utils/mixins';

.freeBlob {
  &-wrapper {
    position: absolute;
    width: 20vh;
    height: 20vh;
    overflow: hidden;
    z-index: 0;
    user-select: none;
    filter: blur(4rem);
  }


  &-noAnim {
    animation: none !important;
  }

  width: 100%;
  height: 100%;
  animation: appear 2s ease forwards,
  disappear 2s ease forwards 13s;

}

.freeBlobEnv {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
  user-select: none;

  &-blobs {
    width: 100%;
    height: 100%;
  }

  &-blobs:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

@keyframes appear {
  from {
    opacity: 0;
    transform: scale(0.2);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes disappear {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.2);
  }
}

