@import "../../../Utils/mixins.scss";

.dashboard-searchPage {
  display: flex;
  width: 95%;
  margin-left: 2.5%;
  height: calc(100vh - 6rem);
  flex-direction: column;

  &-title {
    font-family: $header-font-family, sans-serif;
    font-size: 2rem;
    font-weight: 300;
    color: $gold;
    margin-top: 2rem;
  }

  &-searchBar {
    @include input($white);
    margin: 1rem 0;
    border-color: transparentize($white, .5);
    width: calc(100% - 2rem);
    border-radius: $smallRadius;
  }

  &-items {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    justify-content: center;
    gap: 2rem;
    flex: 1;
    overflow-y: auto;
    max-height: 100%;
  }

  &-item {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
    background: $darkBackground;
    height: 8rem;
    transition: transform 0.3s;
    cursor: pointer;
  }

}