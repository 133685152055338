@import "../../Utils/mixins.scss";

.alerts {
  position: fixed;
  z-index: 4;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  bottom: 1rem;
  right: 2rem;

  &-alert {
    margin-bottom: 1rem;
    padding: 1rem;
    backdrop-filter: blur(1rem);
    display: flex;
    min-width: 20rem;
    padding-right: 5rem;
    box-shadow: 0 0 1rem .5rem rgba(0, 0, 0, .4);
    align-items: center;
    position: relative;

    &-icon {
      height: 1.5rem;
      width: auto;
      color: $white;
      margin-right: 1rem;

      &-close {
        position: absolute;
        right: 0;
        cursor: pointer;
      }
    }

    &-header {
      font-size: 1.2rem;
      color: transparentize($white, 0.5);
      font-family: 'Exo 2', sans-serif;
      font-weight: 500;

      &-highlight {
        font-size: 1.2rem;
        color: $white;
        font-family: $header-font-family, sans-serif;
        font-weight: 400;
      }

    }
  }

  @mixin alertColors($c) {
    border-right: .4vh solid $c;
    border-left: .4vh solid $c;
    background: transparentize($c, .5);
  }

  #info {
    @include alertColors($blue);
  }

  #success {
    @include alertColors($green);
  }

  #warning {
    @include alertColors($yellow);
  }

  #error {
    @include alertColors($red);
  }

}

@include phoneLandscape {
  .alerts {
    bottom: 0;
    right: 0;
    width: 100%;
    align-items: center;
    &-alert {
      min-width: unset !important;
      width: 50%;
    }

  }
}