@import "../../Utils/mixins";

.earlyPartners {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  //backdrop-filter: blur(2rem);
  background: transparentize($darkerBackground, .5);
  z-index: 3;
  position: relative;

  &-wrapper {
    overflow: hidden;
    position: relative;
    z-index: 0;
  }

  &-verified {
    position: relative;
    display: flex;
    overflow: hidden;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: 10rem 0;
  }

  .footer {
    z-index: 5;
  }


  .staticChoice {
    padding: 3rem 0;
  }

  .ticks {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, auto);
    gap: 1rem;
    height: 100%;
    padding: 2rem;

    &-tick {
      display: flex;
      align-items: center;
      color: $white;
      background: transparentize($darkBackground, .5);
      padding: .8rem;
      border-radius: $smallRadius;
      border: $thinBorder;
      cursor: pointer;

      p {
        margin-left: .5rem;
        font-size: 1.2rem;
        font-family: $title-font-family;
      }

      &:hover {
        background: transparentize($darkBackground, .1);
        border-color: transparentize($white, .7);
      }
    }
  }
}

@include tablets {
  .ticks {
    display: flex !important;
    flex-direction: column !important;

    &-tick {
      padding: 1rem !important;
    }
  }
}