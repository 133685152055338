@import "../../../../Utils/mixins.scss";

.solutionsBusinesses {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparentize($darkerBackground, .5);

  &-wrapper {
    overflow: hidden;
    position: relative;
  }

  .staticProps {
    margin: 7rem 0;
  }
}

.ctaMission {
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 15rem 0;

  &-subtitle {
    font-family: $paragraph-font-family;
    font-size: 1.4rem;
    color: $grey;
    margin-top: 1rem;
    width: 35%;
    text-align: center;
  }

  &-button {
    @include buttonAlt($pink);
    margin: 0;
    margin-top: 3rem;
    font-weight: 500;
    background: linear-gradient(45deg, $gold, $pink);

  }

}

.labs-requirements {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  p {
    margin-top: 1rem;
    font-size: 2.5rem;
    font-weight: 500;
    color: $white;
  }

  &-description {
    font-size: 3rem;
    font-weight: 500;
    color: $white;
    margin-bottom: 2rem;
  }

  &-skills {
    margin-top: 0 !important;
    margin-bottom: .5rem !important;
  }

  li {
    font-size: 1rem;
    color: $grey;
    margin-bottom: 1rem;
    margin-left: 3rem;
  }

}

@include tablets {
  .ctaMission {
    &-title {
      width: 70%;
    }

    &-subtitle {
      width: 80%;
    }
  }
}

@include phoneLandscape {
  .ctaMission {
    &-title {
      width: 90%;
    }

    &-subtitle {
      width: 90%;
    }

    &-button {
      font-size: 1.2rem;
      width: 70%;
      text-align: center;
    }
  }
}

