@import "../../../Utils/mixins.scss";

.informationBlock {
  position: relative;
  flex: 1;
  height: 4rem;
  border-radius: 1vh;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 1rem 2rem;
  font-family: $header-font-family, sans-serif;
  font-size: 2rem;
  font-weight: 400;
  text-align: center;
  color: $white;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.4);


  &-title {
    position: absolute;
    top: .5rem;
    left: .5rem;
    font-size: 1.2rem;
    font-family: $header-font-family, sans-serif;
    font-weight: 400;
    color: transparentize($white, 0.3);
  }
}