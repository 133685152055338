@import "../../../../../Utils/mixins.scss";


.expertCreation {
    display: flex;
    flex-direction: column;
    width: 100%;
  
    * {
      transition: .3s;
    }
}

.sampleInput {
    color: $white;
}