@import "../../../Utils/mixins";

.chat-mainArea {
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: calc(100vh - 4rem);

  &-chat {
    width: 100%;
    position: relative;
    overflow-y: auto;
    flex-direction: column;
    justify-content: flex-end;
    height: calc(100vh - 14rem);
    margin-bottom: 1rem;

    &-dummy {
      width: 100%;
      height: 4rem;
    }

    &-instructionHeader {
      position: relative;
      z-index: 3;
      display: flex;
      justify-content: center;
      padding: 1rem 0;
      color: $white;
      //background: #2b2f34;
    }

    &-loading {
      position: absolute;
      top: 40vh;
      left: 50%;
      width: 3rem;
      height: 3rem;
      animation: spin 1s linear infinite;
      color: $gold;
    }

    &-welcome {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      margin-top: 3rem;

      img {
        width: 4rem;
        height: auto;
        filter: brightness(100);
        position: relative;
      }

    }


    &-config-start {
      .chat-mainArea-chat-config-instructions {
        h1 {
        }

        &-selected {
          &-name {
            text-transform: uppercase;
            color: $gold;
          }
        }
      }
    }
  }
}

.chat-mainArea-welcome > .chat-mainArea-chat {
  justify-content: flex-start;
  width: 100%;
}

.chat-appiIcon {
  width: 4rem;
  height: auto;
  margin-top: 3rem;
  filter: brightness(100) invert(1);
}