@import "../../../Utils/mixins";

.chat-sidebar-history {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 2vh;
  transition: .3s;
  height: 100%;
  overflow-y: auto;


  &-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &-new {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85%;
    height: 2.5rem;
    cursor: pointer;
    transition: .3s;
    //border-left: .15rem solid $gold;
    //border-right: .15rem solid $gold;
    border-radius: $smallRadius;
    border: 1px solid $gold;
    background: $darkBackground;
    box-shadow: 0 0 1rem .1rem rgba(0, 0, 0, 0.2);
    margin-bottom: 2rem;

    &-icon {
      width: auto;
      height: 1.2rem;
      transition: .3s;
      color: $gold;
    }

    h1 {
      font-family: $header-font-family, sans-serif;
      font-size: 1rem;
      font-weight: 400;
      margin-left: 1rem;
      color: $gold;
    }
  }

  &-footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: $darkerBackground;
    height: 3rem;
    z-index: 6;

    &-trash {
      width: auto;
      margin-right: .5rem;
      height: 1.4rem;
      transition: .3s;
      color: $white;
      z-index: 5;
      cursor: pointer;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  &-breakpoint {
    font-size: 1rem;
    color: transparentize($white, 0.5);
    margin-bottom: .5rem;
  }

  &-wrapper {
    width: 85%;
    display: flex;
    flex-direction: column;
  }
}

.chat-sidebar-history-new:hover {
  border-color: $darkerBackground;
  background: transparentize($gold, 0.2);

  .chat-sidebar-history-new-icon {
    color: $darkerBackground;
  }

  h1 {
    font-weight: 500;
    color: $darkerBackground;
  }
}

.chat-sidebar-history-line {
  width: 80%;
  height: 1px;
  background-color: $gold;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.chat-sidebar-history-trash {
  height: 1rem;
  width: auto;
  position: absolute;
  bottom: 0;
  left: 1rem;
}

.chat-sidebar-history-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-self: center;
  width: 95%;
  height: 2.2rem;
  cursor: pointer;
  transition: .3s;
  margin-bottom: 1rem;
  border-left: .15rem solid $gold;
  position: relative;

  &-icon {
    margin-left: 1rem;
    width: auto;
    height: 1rem;
    transition: .3s;
    color: $gold;

  }

  h1 {
    font-family: $header-font-family, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    margin-left: 1rem;
    color: $white;
  }

  &-delete {
    position: absolute;
    right: .5rem;
    width: auto;
    height: 1.2rem;
    transition: .3s;
    color: $gold;
    z-index: 5;
  }

  &:hover {
    background: $darkBackground;

    .chat-sidebar-history-item-icon {
      color: $gold;
    }

    h1 {
      font-weight: 500;
      color: $gold;
    }

  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent 70%, $darkerBackground 95%, $darkerBackground 100%);
    bottom: 0;
    transition: .3s;
  }
}

#selected {
  border-left-color: $pink;
  border-right: .15rem solid $pink;

  .chat-sidebar-history-item-icon, h1 {
    color: $pink
  }

  .chat-sidebar-history-item-delete {
    color: $pink;

    &:hover {
      transform: scale(1.1);
    }
  }

  &:hover {
    background: none;
  }
}

#selected.chat-sidebar-history-item {
  &:after {
    background: linear-gradient(90deg, transparent 65%, $darkerBackground 80%, $darkerBackground 100%);
  }
}
