@import "../../../../Utils/mixins.scss";

.staticList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 5rem 0;
  padding: 3rem 0;
  border-top: $thinBorder;
  position: relative;

  &-list {
    margin-top: 2rem;
    width: 85rem;
    max-width: 80%;
    height: 100%;
    gap: 1rem;
    display: flex;
    flex-direction: column;
  }

  &-list2 {
    display: grid;
    grid-template-columns: 5fr 3fr;
    grid-auto-rows: minmax(100px, auto);
    flex-direction: row;

    .staticListPoint {
      height: 40rem;
    }
  }

  &-list3 {
    grid-template-rows: auto 1fr;
  }

  &-list3-upper {
    grid-column: 1 / -1;
    width: 100%;

    .staticListPoint {
      height: 30rem;
    }
  }

  &-list3-lower {
    display: grid;
    grid-template-columns: 5fr 3fr;
    gap: 1rem;
    width: 100%;

    .staticListPoint {
      height: 35rem;
    }
  }
}

.staticListPoint {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: $largeRadius;
  transition: .3s;
  padding: 1rem;
  @include glass;
  border: $thinBorder;

  &-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h1 {
      font-family: $header-font-family, sans-serif;
      font-size: 1.5rem;
      font-weight: 500;
      margin-top: 1rem;
      margin-bottom: .5rem;
      color: $white;
    }

    h2 {
      font-family: $header-font-family, sans-serif;
      font-size: 1rem;
      font-weight: 400;
      margin-top: .5rem;
      width: 90%;
      margin-bottom: .5rem;
      color: transparentize($white, .3);
    }
  }

  &-icon {
    svg {
      width: 2.3rem;
      height: 2.3rem;
      background: linear-gradient(145deg, $pink, $roseGold);
      color: $white;
      border-radius: 25%;
      padding: .7rem;
      margin-top: 1rem;
    }
  }

  &-img, &-children {
    width: 100%;
    height: 100%;
    border-radius: $mediumRadius;
    overflow: hidden;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-children {
    border: $thinBorder;
  }

  &-img {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: inherit;
    }
  }

  &:hover {
    border-color: transparentize($white, .8);
    background: transparentize($darkerBackground, .3);
  }

  &-button {
    all: unset;
    padding: .5rem 1.3rem;
    border: 1px solid transparentize($white, .8);
    border-radius: $smallRadius;
    font-family: $header-font-family, sans-serif;
    font-weight: 300;
    cursor: pointer;
    transition: .3s;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;

    &:hover {
      background-color: transparentize($white, .7);
    }
  }
}

@include tablets {
  .staticList {

    &-list3 {
      grid-template-rows: auto auto 1fr;
      width: 100% !important;

      .staticListPoint {
        min-width: unset !important;
      }
    }

    &-list3-upper {

      .staticListPoint {
        height: 30rem;
      }
    }

    &-list3-lower {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .staticListPoint {
        min-width: unset !important;
        height: 30rem;
      }
    }

    &-list2 {
      display: flex;
      flex-direction: column;
      width: 80% !important;
      min-width: unset !important;

      .staticListPoint {
        min-width: unset !important;
      }
    }

    &-list1 {
      display: flex;
      flex-direction: column;
      min-width: unset !important;

      .staticListPoint {
        min-width: unset !important;
      }
    }
  }
}

@include phoneLandscape {
  .staticList-list3 {
    width: 90% !important;
  }
}