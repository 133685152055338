@import '../../../../Utils/mixins.scss';
@import "../../../../Utils/animations";

.newsletter {
  .titleComponent-img > img {
    filter: brightness(100);
  }

  &-divider {
    height: 1px;
    width: 100%;
    background: transparentize($white, 0.8);
    margin: 3rem 0;
  }

  &-form {
    display: flex;
    justify-content: center;

    button {
      @include ctaButton($pink);
      margin-left: 1rem;
    }
  }

  input {
    @include input($roseGold);
    width: 60%;
    border-radius: $smallRadius;
    height: 2rem;
    background: transparentize($darkerBackground, 0.7);
  }


  &-intents {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      color: $pink;
      font-size: 1.3rem;
      font-family: $title-font-family, sans-serif;
      font-weight: 400;
    }

    h2 {
      color: $white;
      font-size: 1rem;
      font-family: $header-font-family, sans-serif;
      font-weight: 300;
      margin-top: .3rem;
    }

    button {
      @include button($roseGold);
      margin-top: 1rem;
    }

  }
}

@include tablets {
  .newsletter-form {
    flex-direction: column;
    align-items: center;

    button {
      margin-left: 0;
      margin-top: 2rem;
      padding: 1rem 2rem;
    }
  }
}

@include phoneLandscape {
  .newsletter {
    .titleComponent {
      width: calc(100% - 2rem);
    }

    &-intents {
      width: 95%;
      margin-left: 2.5%;
      h1{
        text-align: center;
      }
    }

  }
  .newsletter-form {
    flex-direction: column;
    align-items: center;

    button {
      margin-left: 0;
      margin-top: 2rem;
      padding: 1rem 2rem;
    }
  }
}