@import '../../../Utils/mixins.scss';

.agents {
  &-bottomSection {
    display: flex;
    align-items: flex-start;
    width: 100%;

    .searchBar {
      width: 100%;

      .agentCard {
        &-left {
          width: 25%;
        }
      }
    }
  }

  &-filters {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 8rem);
    margin-right: 1rem;
    border-right: .1rem solid transparentize($white, .9);
    background: transparentize($darkBackground, .5);
    box-shadow: 0 0 .5rem .1rem rgba(0, 0, 0, 0.5);
    padding: 4rem 1rem;
    position: relative;
    width: 25%;

    &-title {
      font-family: $header-font-family, sans-serif;
      font-size: 1.2rem;
      font-weight: 300;
      margin-top: 1rem;
      margin-bottom: .5rem;
      color: $roseGold;
    }

    &-radio {
      width: 100%;
      margin-top: .5rem;
      color: $white;
      font-family: $header-font-family, sans-serif;
      font-size: 1.2rem;
      font-weight: 300;
      cursor: pointer;

      input {
        cursor: pointer;
        margin-right: .5rem;
      }

      label {
        cursor: pointer;
        margin-left: .5rem;
      }
    }

    &-checkbox {
      display: flex;
      flex-wrap: wrap;
      margin-top: .5rem;

      &-item {
        margin: .25rem;
        padding: .2rem .8rem;
        background-color: transparentize($accentsColor, .2);
        box-shadow: 0 0 .5rem .1rem rgba(0, 0, 0, 0.2);
        color: $white;
        border: $thinBorder;
        font-family: $paragraph-font-family, sans-serif;
        border-radius: .3rem;
        font-size: .8rem;
        font-weight: 300;
        cursor: pointer;
        transition: .1s linear;

        &:hover {
          background-color: $accentsColor;
          border: 1px solid transparentize($white, .5);
        }
      }
    }

    &-button {
      @include button($roseGold);
      position: absolute;
      bottom: 1rem;
      right: 1rem;

    }
  }

  &-appList {
    &-filtered {
      .agentCard {
        width: calc(50% - 3rem);

        &-left {
          width: 20%;
        }
      }
    }
  }
}