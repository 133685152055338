@import "../../Utils/mixins.scss";
@import "../../Utils/colors.scss";

.registerComponent {
  z-index: 3;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 30rem;
  border-top: 0.2rem solid $gold;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  background: transparentize($darkerBackground, 0.1);

  form {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 90%;
  }

  label {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 90%;
  }

  input {
    @include input($gold);
    cursor: text;
  }

  h2 {
    font-family: $header-font-family, sans-serif;
    font-size: 1rem;
    font-weight: 300;
    color: $white;
  }

  label {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 70%;
    margin-bottom: .7rem;

  }

  &-requirements {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    width: 70%;
    color: $gold;
    font-family: $header-font-family, sans-serif;
    margin-top: 1rem;
    font-size: .9rem;

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: .5rem;
    }
  }

  .registerComponent-nameWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70%;

    label {
      width: 40%;

      input {
        width: 100%;
      }
    }
  }

  .registerComponent-submit, #swap {
    all: unset;
    font-family: "$header-font-family", sans-serif;
    margin-top: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border-radius: $smallRadius;
    background: $darkBackground;
    font-size: .9rem;
    padding: 0.4rem 1rem;
    color: $gold;
    border: .1rem solid $gold;

    &-inactive {
      background: $darkerBackground;
      color: grey;
      border: .1rem solid grey;

    }
  }

  .registerComponent-submit:hover {
    background: $gold;
    color: $darkBackground;
  }

  .registerComponent-submit-inactive:hover {
    cursor: not-allowed;
    background: $darkerBackground;
    color: grey;
  }

  button:focus {
    outline: none;
  }

  .line {
    background: $darkerBackground;
    width: 100%;
    height: .2rem;
    margin-top: .5rem;
    margin-bottom: 1rem;
  }

  .swap {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 90%;
    margin-top: 15vh;
    margin-bottom: 2vh;
  }

  #swap {
    margin: 0;
  }

  .S9gUrf-YoZ4jf {
    margin-top: 2vh;
    transform: scale(1.5);

  }
}
