@import "../../../Utils/mixins";
@import "../../../Utils/animations";

.messageBlock {
  width: 80%;
  margin-left: 10%;

  &-unfolded {
    &-vertical {
      display: flex;

      .message {
        width: 100%;
        margin-right: .2rem;
        margin-left: .2rem;
        border-right: .1rem solid transparentize(white, .9);
      }
    }
  }
}

.message {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1rem;
  padding: 1rem;

  &-innerWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 2rem;
    padding-top: .5rem;
  }

  &-context {
    margin-top: .5rem;
    color: $white;
    display: flex;

    &-document {
      padding: .5rem 1rem;
      margin: .2rem;
      border-radius: $smallRadius;
      background: transparentize($greyBackground, .6);
      border: 1px solid transparentize($grey, .5);
      font-size: .8rem;
      transition: .3s;

      &:hover {
        background: transparentize($greyBackground, .1);
      }
    }
  }

  &-controls {
    margin-left: -.5rem;
    position: absolute;
    bottom: .2rem;
    display: flex;
    align-items: center;
    height: 1rem;

    &-version {
      display: flex;
      margin-right: .5rem;
      color: $gold;

      &-arrow {
        width: 1.1rem;
        height: 1.1rem;
        cursor: pointer;
      }

      h1 {
        font-family: $header-font-family;
        font-size: .9rem;
        user-select: none;
      }
    }

    &-iconHolder {
      display: flex;
      justify-content: center;
      cursor: pointer;

      &-left {
        left: 2.4rem;
      }

      &-icon {
        width: 1rem;
        height: 1rem;
        margin-right: .2rem;
        margin-left: .2rem;
        transition: .3s;
        color: $gold;

        &-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: .2rem;
          padding: .3rem .5rem;
          cursor: pointer;
          transition: .3s;

          p {
            font-size: .8rem;
            color: $gold;
            margin-left: .2rem;
          }

          &:hover {
            border-radius: $smallRadius;
            background: transparentize($white, .8);
          }
        }

        &-rotated {
          transform: rotate(180deg);
        }

      }

      &-edit {
        width: 1.2rem;
        height: 1.2rem;
        margin-top: .2rem;
      }

      &-reload, &-copy {
        width: 1rem;
        height: 1rem;
      }
    }


  }

  &-user {
    border-top-color: transparentize($gold, .9);
  }


  &-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 40%;
    height: 3rem;
    border-bottom: .1rem solid transparentize(white, .9);
  }

  .message-icon {
    width: auto;
    height: 50%;
    color: $gold;

    &-sender {
      font-family: $header-font-family, serif;
      font-size: 1.1rem;
      font-weight: 500;
      width: auto;
      margin-left: .5rem;
      color: transparentize($white, .2);
    }
  }


  .message-text-wrapper {
    font-weight: 500;
    width: 100%;
    margin-right: 2vh;
    color: $white;


    code {
      width: 100% !important;
    }
  }

  &-checkbox {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    width: auto;
    height: 1.6rem;

    &-checked {
      color: $gold;

      &-final {
        color: $pink;
      }
    }
  }


}

.message-bot {
  cursor: pointer;
}

.messageBlock-folded {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;

  .message-text-wrapper {
    width: 100%;
    text-align: center;
  }

  .message-controls {
    display: none;
  }

  .message {
    display: flex;
    align-items: center;
    flex: 1;
    width: 100%;
    min-width: 15rem;
    height: 1.5rem;
    padding: .5rem 0;
    background: $darkBackground;
    border: .1rem solid transparentize(white, .9);
    border-radius: $smallRadius;
    margin: .5rem;
    transition: .3s;

    &-innerWrapper {
      width: 100%;
      padding: 0;
    }

    .messageText {
      margin-top: 0;
    }

    &-icon-wrapper {
      display: none;

    }

    &-sender {
      all: unset;
      font-family: $title-font-family, serif;
      font-size: .8rem;
      width: 100%;
      color: transparentize($white, 0.4);
    }

    &:hover {
      background: $greyBackground;
    }
  }
}


.message-fold-icon {
  font-size: .8rem;
  font-weight: 500;
  position: absolute;
  top: .4rem;
  right: 1rem;
  width: auto;
  transition: .3s;
  color: $white;

}

.message-fold-icon-selected {
  transform: rotate(180deg);
}

.message-chosen {
  position: relative;

  &:after {
    position: absolute;
    top: 0;
    right: 0;
    width: .1rem;
    height: 100%;
    content: "";
    background: $gold;
  }

}

.message-chosen-final {
  position: relative;
  cursor: unset;

  &:after {
    position: absolute;
    top: 0;
    right: 0;
    width: .15rem;
    height: 100%;
    content: "";
    background: $pink;
  }
}

.message-toSelect {
  cursor: pointer;
  border-radius: $smallRadius;
  background: #3f3f3f;
}


.message-text {
  font-family: $paragraph-font-family, serif;
  font-size: 1rem;
  font-weight: 400;
  margin-top: 1rem;
  color: $white;


  h1, h2, h3, h4, p, strong, li {
    font-family: $paragraph-font-family;
    font-weight: 400;
    line-height: 1.5;
    margin-top: 1rem;
  }

  table {
    background: transparentize($darkBackground, .2);
    border: $thinBorder;
    border-collapse: collapse;

    thead {
      background: transparentize($roseGold, .9);
    }

    th, td {
      font-size: .9rem;
      padding: 0.5rem;
      border: $thinBorder;
    }

    th {
      font-size: 1rem;
      color: $roseGold;
    }
  }

  em {
    font-weight: 400;
    font-style: italic;
    color: $roseGold;
  }

  p {
    font-size: 1rem;
    line-height: 1.5;
    white-space: pre-wrap;
    letter-spacing: .5px;
    color: rgb(209, 213, 219);
  }

  * {
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: break-word;
  }

  strong {
    font-weight: 600;
    color: #FBFBFB;
  }

  li {
    list-style-type: square;

    &::marker {
      color: $gold;
    }
  }

  &-code {
    position: relative;
    width: 100%;
    margin-top: 1rem;
    padding-top: 1rem;
    background: transparentize($codeColor, .5);
    box-shadow: 0 0 1rem 0.2rem rgba(0, 0, 0, 0.2);
    border: $thinBorder;

    &-header {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 2rem;
      border-bottom: 1px solid transparentize($white, .5);

      h1 {
        font-size: 1rem;
        width: calc(50% - 1rem);
        margin-top: 0 !important;
        margin-left: 1rem;
        text-align: right;
        color: transparentize($white, .2);
      }

      &-copy {
        font-family: "Exo 2", sans-serif;
        font-size: .9rem;
        font-weight: 300;
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100% - 1rem);
        margin-top: 0 !important;
        cursor: pointer;
        transition: .3s;
        color: $white;
        padding: .3rem .5rem;
        margin-right: .2rem;

        &:hover {
          background: $darkestBlack;
          border-radius: .3rem;
        }
      }
    }
  }

  &-edit {
    padding-left: 1rem;
    border-left: 1px solid $gold;
  }
}

.syntax-highlighter {
  overflow-x: hidden !important;
  margin: 1rem 0 !important;
  background: transparent !important;

  code {
    width: 100% !important;
    background: transparent !important;
  }
}