@import '../../../Utils/mixins.scss';

.footer {
  overflow: hidden;
  width: 100%;
  background: transparentize($darkestBlack, .3);
  display: flex;
  flex-direction: column;

  &-upper {
    display: flex;
    width: 100%;
    height: 6rem;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid transparentize($white, 0.9);

    &-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50%;
    }

    &-links {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &-link {
        color: $grey;
        height: 2rem;
        width: 2rem;
        margin: .5rem;
        transition: .3s;
        cursor: pointer;

        &:hover {
          color: $white;
        }
      }
    }

  }

  &-mid {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  &-lower {
    font-family: $header-font-family, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 3rem;
    color: $white;
  }


  &-links {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 15%;

    h1 {
      font-family: $header-font-family, sans-serif;
      font-size: 1.5rem;
      font-weight: 400;
      margin-top: 1rem;
      color: $roseGold;
    }

    .link-list {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      width: 70%;
      height: 15rem;

      a {
        font-family: $header-font-family, sans-serif;
        font-size: 1rem;
        font-weight: 400;
        margin-top: .5rem;
        transition: .3s;
        text-decoration: none;
        color: $grey;
      }

      a:hover {
        color: $white;
      }
    }

  }

}


@include tablets {
  .footer {
    &-mid {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    &-links {
      width: 100%;
      align-items: center;

      .link-list {
        width: unset;

        a {
          text-align: center;
          width: calc(100% - 1rem);
          padding: .5rem;
        }
      }
    }
  }
}
