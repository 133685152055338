@import "../../../Utils/mixins.scss";

.notFound {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  h1 {
    margin-top: 10rem;
    font-size: 3rem;
    font-weight: 600;
    color: $gold;
    margin-bottom: 1rem;
  }

  p {
    margin-top: 10rem;
    font-size: 1.5rem;
    font-weight: 400;
    color: $white;
    margin-bottom: 1rem;
  }

  a {
    @include buttonAlt($gold);
  }
}