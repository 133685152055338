@import "../../../../../Utils/mixins";

.documentSecurity {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &-lock {
    width: 2rem;
    height: 2rem;
    padding: 1rem;
    color: transparentize($white, 1);
    transition: .3s;
  }

  &-inside {
    width: calc(100% - 2rem);
    height: calc(100% - 2rem);
    margin-left: 1rem;
    border-radius: $smallRadius;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    img {
      width: auto;
      height: 4rem;
      position: absolute;
      z-index: 2;
    }

    svg {
      position: absolute;
      height: 2.5rem;
      width: 2.5rem;
      transition: .3s;
      opacity: .8;
      color: $grey;
      border-radius: $smallRadius;
      z-index: 1;
    }

    #doc0 {
      right: 10%;
      bottom: 15%;
    }

    #doc1 {
      left: 10%;
      top: 5%;
    }

    #doc2 {
      left: 5%;
      top: 35%;
    }

    #doc3 {
      left: 20%;
      top: 55%;
    }

    #doc4 {
      right: 10%;
      top: 15%;
    }

    #doc5 {
      right: 40%;
      top: 5%;
    }

    #doc6 {
      right: 25%;
      top: 35%;
    }

    #doc7 {
      right: 55%;
      bottom: 7%;
    }

    #doc8 {
      left: 30%;
      top: 25%;
    }

    #doc9 {
      right: 30%;
      bottom: 15%;
    }


  }
}

.documentSecurity-hovered {
  .documentSecurity-lock {
    color: $white;
  }

  .documentSecurity-inside {
    border: $thinBorder;
    background: transparentize($white, .95);
  }

  @for $i from 0 through 6 {
    #doc#{$i} {
      top: unset;
      left: unset;
      bottom: unset;
      right: unset;
      $angle: $i * 51.43deg;
      $radius: 80px;
      transform: translate(calc(-50% + 1.25rem), calc(-50% + 1.25rem)) rotate($angle) translateY(-$radius) rotate(-$angle);
    }
  }
  @for $i from 7 through 9 {
    #doc#{$i} {
      display: none;
    }
  }
}

@include phoneLandscape {
  .documentSecurity {
    &-inside {
      svg {
        height: 2rem;
        width: 2rem;
      }

      img {
        height: 3rem;
        background-image: linear-gradient(90deg, $gold, $roseGold);
        padding: .5rem;
        border-radius: $smallRadius;
        margin-left: .5rem;
        margin-top: .5rem;
      }
    }
  }
}