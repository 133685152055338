@import "../../../Utils/mixins.scss";

@include smallLaptops {
  .home {
    &-titleWrapper {
      align-self: flex-end;
      width: 90%;
      height: unset;
      margin-top: 8rem
    }

    &-tiles {
      grid-template-columns:  1fr 1fr;
    }

    &-title {
      font-size: 3rem;
    }

    &-description {
      font-size: 1.5rem;
    }

  }
}

@include tablets {
  .home {
    height: unset;

    &-titleWrapper-buttonsWrapper {
      &-button {
        margin-left: unset !important;
        width: 15rem !important;
      }
    }
  }
}

@include phoneLandscape {
  .home {

    &-tiles {
      grid-template-columns:  1fr;

      &-tile {
        height: unset;
      }
    }

    .carouselContainer {
      display: none;
    }

    &-titleWrapper {
      width: 100%;
    }

    &-description {
      width: 90% !important;
      text-align: center;
    }
  }
}