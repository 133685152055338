@import "../../../../Utils/mixins.scss";

.dashboard-content-account {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: calc(100% - 4rem);
  margin-left: 2rem;

  input {
    all: unset;
    font-family: $header-font-family, sans-serif;
    font-size: 1rem;
    font-weight: 300;
    width: calc(100% - 2rem);
    padding: .5rem 1rem;
    text-align: start;
    white-space: pre-wrap;
    word-break: break-word;
    color: $white;
    border: .1rem solid transparentize($white, 0.9);
    box-shadow: 0 0 0 .1rem transparentize($darkestBlack, 0.9);
  }

  button {
    @include button($gold);
  }

  span {
    width: 50%;
    height: .15rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: transparentize($white, 0.9);
  }

  &-title {
    font-family: $header-font-family, sans-serif;
    font-size: 2rem;
    font-weight: 300;
    margin-top: 3rem;
    color: $gold;
  }

  &-info {
    position: relative;
    width: 100%;
    padding-bottom: 2rem;

    &-item {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: space-between;
      width: 50%;
      margin: .3rem 0;

      h1 {
        font-family: $header-font-family, sans-serif;
        font-size: 1rem;
        font-weight: 400;
        color: $white;
        margin-bottom: .5rem;
        margin-top: 1rem;
      }

      h2 {
        font-family: $header-font-family, sans-serif;
        font-size: 1rem;
        font-weight: 300;
        margin-left: 1rem;
        color: $white;
      }
    }

    &-edit {
      margin-top: 2rem
    }
  }

  &-keys {
    width: 50%;

    h1 {
      font-family: $header-font-family, sans-serif;
      font-size: 2rem;
      font-weight: 300;
      margin-top: 2rem;
      color: $gold;
    }


    &-key {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: 1rem;

      h2 {
        font-family: $header-font-family, sans-serif;
        font-size: 1.2rem;
        font-weight: 300;
        color: $white;
      }

      &-wrapper {
        display: flex;
        align-items: center;
        width: 50%;
      }

      input {
        width: 90% !important;
        max-width: 90% !important;
      }

      &-icon {
        width: 2rem;
        height: 2rem;
        margin-left: .5rem;
        cursor: pointer;
        color: $gold;
      }
    }
  }

  &-password {
    display: flex;
    flex-direction: column;
    width: 50%;

    h1 {
      font-family: $header-font-family, sans-serif;
      font-size: 2rem;
      font-weight: 300;
      margin-top: 2rem;
      color: $gold;
    }

    &-change {
      position: relative;
      width: 100%;
      padding-bottom: 3rem;


      input {
        margin-top: .8rem;
      }

      &-button {
        position: absolute !important;
        right: 0 !important;
        bottom: 0 !important;
      }
    }
  }

  &-developer {
    width: 100%;

    &-item {
      display: flex;
      align-items: center;

      h1 {
        font-family: $header-font-family, sans-serif;
        font-size: 1.2rem;
        font-weight: 300;
        color: $white;
      }

      button {
        margin-left: 1rem;
      }

    }
  }

  &-default {
    h1 {
      font-family: $header-font-family, sans-serif;
      font-size: 2rem;
      font-weight: 300;
      margin-top: 1rem;
      color: $gold;
    }

    h2 {
      font-family: $header-font-family, sans-serif;
      font-size: 1rem;
      font-weight: 300;
      margin-top: 1rem;
      color: $white;
    }
  }

  &-delete {
    display: flex;
    align-items: center;
    padding-bottom: 3rem;

    &-title {
      font-family: $paragraph-font-family, sans-serif;
      font-size: 2rem;
      font-weight: 400;
      margin-top: 3rem;
      color: $red;
    }

    h1 {
      font-family: $header-font-family, sans-serif;
      font-size: 1.2rem;
      font-weight: 300;
      color: $white;
    }

    button {
      @include button($red);
      margin-left: 1rem;
    }

  }
}