@import './Utils/fonts.scss';
@import './Utils/colors.scss';

html,
body {
  min-height: 100vh;
}

* {
  font-family: $header-font-family, serif;
  font-weight: 400;
}

::-webkit-scrollbar {
  width: 0.1rem;
}

::-webkit-scrollbar-track {
  background-color: $white;
}

::-webkit-scrollbar-thumb {
  background-color: $gold;
  transition: .3s;
}

::-webkit-scrollbar-thumb:hover {
  background-color: $yellow;
}

h1, h2, h3, h4 {
  font-family: $header-font-family, serif;
}