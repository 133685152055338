@import "./Utils/colors.scss";
body,
html {
  height: 100vh;
  width: 100%;
  background: $darkestBlack;
}

* {
  padding: 0;
  margin: 0;
}
