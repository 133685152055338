@import "../../Utils/mixins.scss";

.loginWrapper {
  @include defaultPage;
  position: relative;
  overflow: hidden;
  min-height: 90vh;
  background: $darkerBackground;
  z-index: 1;
}

.login {
  @include defaultPage;
  position: relative;
  overflow: hidden;
  min-height: 92vh;
  padding-top: 8vh;
  backdrop-filter: blur(7rem);
  z-index: 2;
}

.registerComponent-registerTitle, .registerComponent-orHeader, .loginComponent-loginHeader, .loginComponent-orHeader {
  font-family: $header-font-family, sans-serif;
  font-size: 2rem;
  font-weight: 300;
  margin-bottom: 1.5rem;
  color: $white;
  margin-top: 2rem;
}

.loginComponent-altlogs, .registerComponent-altLogs {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 90%;
}

.loginComponent-google, .registerComponent-google {
  cursor: pointer;
  height: 2rem;
  width: 2rem;
  background: $darkerBackground;
  padding: .6rem;
  border-radius: 1rem;
  border: .1rem solid $darkBackground;
  transition: .3s;
}

.loginComponent-google:hover, .registerComponent-google:hover {
  border-color: $gold;
}

.registerComponent-orHeader, .loginComponent-orHeader {
  margin-top: 1rem;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px $darkerBackground inset !important;
  -webkit-text-fill-color: $gold !important;
}

.blobLogin {
  position: absolute;
  width: 20vh;
  height: 20vh;
  user-select: none;
  z-index: 1;
}

.blobLogin1 {
  top: 15vh;
  left: 5vh;
  transform: scale(2);
}

.blobLogin2 {
  bottom: 50vh;
  right: 80vh;
  transform: scale(2);
}

.blobLogin3 {
  top: 90vh;
  left: -10vh;
  transform: scale(3.5);

}

@include phoneLandscape {
  .loginComponent {
    margin-top: 4rem;
    width: 90% !important;
  }
}