@import '../../Utils/mixins.scss';

@include phoneLandscape {
  .agents {
    .agentsSearchBarWrapper {
      width: 95% !important;
      height: 15rem;
    }

    .appList {
      width: 100%;
    }

    .appList-filtered {
      width: 100%;
    }

    .recommendations {
      margin-top: 6rem;
    }
  }
}