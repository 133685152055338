@import "../../../../Utils/mixins.scss";

.guide {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  backdrop-filter: blur(5rem);


  &-wrapper {
    overflow: hidden;
    position: relative;
  }
}
