@import "../../../Utils/mixins.scss";

.customPdfViewer {
  height: calc(100vh - 4rem);
  //padding-top: 4rem;
  //overflow-y: hidden;
  //display: flex;
  //justify-content: center;
  width: 100%;
  background: rgba(50, 54, 57, 255);
  position: relative;
  display: flex;

  embed {
    height: 100%;
    width: 100%;
  }

  &-controlls {
    position: fixed;
    bottom: 0;
    height: 3rem;
    display: flex;
    align-items: center;
    background: transparentize($darkBackground, 0.2);
    backdrop-filter: blur(5px);
    width: 100%;
    z-index: 2;

    &-button {
      color: $white;
      width: 1.2rem;
      height: 1.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 0.1rem;
      background: $pink;
      border-radius: 50%;
      margin: 0 1rem;
      cursor: pointer;
      transition: .3s;

      &:hover {
        background: $gold;
      }
    }

    p {
      color: $white;
      margin: 0;
      padding: 0;
      font-size: 1.2rem;
    }
  }
}

.AreaHighlight {
  .AreaHighlight__part {
    background: transparentize($pink, 0.8) !important;
    border-radius: 0.5rem;
    //border: .2rem solid transparentize($pink, .5);
    box-shadow: 0 0 1rem .1rem rgba(0, 0, 0, 0.2);
  }

}