@import "../../Utils/mixins.scss";
@import "../../Utils/colors.scss";

.titleComponent {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;

  &-img {
    background: linear-gradient(60deg, $gold, $pink);
    animation: titleFlow 5s infinite;
    padding: 1rem;
    background-size: 200% 200%;
    border-radius: $mediumRadius;
    @include shadow;
    display: flex;
    align-items: center;
    justify-content: center;

    svg,
    img {
      height: 100%;
    }

    svg {
      color: $white;
      width: 100%;
    }
  }

  &-text {
    display: flex;
    flex-direction: column;

    &-title,
    &-title-highlight {
      color: $white;
      font-size: 4rem;
      font-family: $title-font-family, sans-serif;
      font-weight: 500;
      line-height: 1.2;

      &-highlight {
        @include gradientText(60deg, $roseGold, $pink);
        margin-top: -1rem;
      }
    }

    &-subtitle {
      color: $white;
      font-size: 1.5rem;
      font-family: $header-font-family, sans-serif;
      font-weight: 400;
      width: 80%;
      margin-top: 1rem;
      text-align: center;
    }
  }
}

@keyframes titleFlow {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

@include tablets {
  .titleComponent {
    &-img {
      padding: 1rem;
    }

    &-text {

      &-title,
      &-title-highlight {
        font-size: 3rem;
      }

      &-subtitle {
        font-size: 1.2rem;
      }
    }
  }
}

@include phoneLandscape {
  .titleComponent {
    &-img {
      padding: 0.5rem;
    }

    &-text {

      &-title,
      &-title-highlight {
        font-size: 2.5rem;
        text-align: center;
      }

      &-title-highlight {
        margin-top: .1rem;
      }

      &-subtitle {
        font-size: 1rem;
      }
    }
  }
}
