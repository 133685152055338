@import "../../../../Utils/colors.scss";
@import "../../../../Utils/mixins.scss";

.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $white;
  background-color: $darkBackground;
  height: 100%;
  max-height: 120vh;
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);

  &-icon {
    width: 5rem;
    height: 5rem;
    margin-right: .7rem;
    margin-left: .7rem;
    margin-bottom: .7rem;
    margin-top: 1.8rem;
  }

  &-role {
    margin-top: 0.5rem;
    margin-bottom: 1.2rem;
  }

  &-nav {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 3rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    z-index: 1;

    &-buttonContainer {
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border-right: 1px solid $gold;

      button {
        font-size: 1.1rem;
        font-weight: 500;
        width: 100%;
        height: 100%;
        background: none;
        border: none;
        color: $white;
        transition: background-color 0.1s ease;
      }

      &:last-child {
        border: none;
      }

      :hover {
        background-color: $gold;
        color: black;
      }
    }
  }

  &-agents {
    width: 90%;
    margin-right: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &-edit {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 80%;
        
        &-dropdown {
            margin-bottom: 1.5rem;
            margin-top: 1.5rem;

            p {
                min-width: 40%;
            }

            .dropdown {
              margin: unset;
            }
        }

        .dropdown {
          margin: unset;
        }
      }

      button {
        @include button(red);
      }
    }


  &-stats {
    width: 100%;
    margin-bottom: 5rem;
    
    .weeklyTeamUsage {
      border: unset;
    }
  }
}

.clicked {
  background-color: $darkerBackground;
}
