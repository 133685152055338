@import "../../../Utils/mixins.scss";

.organization-create {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: $darkBackground;
  color: $white;
}