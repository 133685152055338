@import "../../../../Utils/mixins.scss";

.overview-content-organizationTeam {
  display: flex;

  &-members {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    justify-content: center;
    gap: 2rem;
    flex: 1;
    overflow-y: auto;
    align-content: flex-start;
    max-height: 100%;
  }

  &-member {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
    background: $darkBackground;
    height: 8rem;
    transition: transform 0.3s;
    cursor: pointer;

    &-name {
      font-family: $header-font-family, sans-serif;
      font-size: 1.8rem;
      font-weight: 300;
      color: $white;
      margin-top: 1rem;
    }

    &-role {
      font-family: $header-font-family, sans-serif;
      font-size: 1rem;
      font-weight: 300;
      color: $gold;
      margin-top: 0.5rem;
    }

    &-image {
      width: 10rem;
      height: 10rem;
      border-radius: 50%;
      margin-top: 1rem;
    }

  }

  &-preview {
    width: 70%;
    background: $darkerBackground;
    margin-left: 1rem;
  }
}