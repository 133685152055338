@import '../../Utils/mixins.scss';

.agentCard {
  display: flex;
  justify-content: flex-start;
  width: 25rem;
  min-height: 14rem;
  margin: 1rem;
  cursor: pointer;
  box-shadow: 0 0 1rem .1rem rgba(0, 0, 0, 0.5);
  position: relative;
  border: $thinBorder;
  background: $darkerBackground;
  border-radius: $smallRadius;
  transition: .3s linear;
  overflow: hidden;

  #blob_1, #blob_2 {
    animation: unset;
  }

  #blob_1 {
    position: absolute;
    bottom: -15%;
    right: -7%;
    width: 100%;
    height: 100%;
    z-index: 1;
    filter: blur(5rem);
    transform: scale(1.5);
    opacity: .5;
  }

  &-content {
    width: calc(100% - 6rem);
    margin-top: 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &-logo {
    background: $darkerBackground;
    padding: .7rem;
    height: 4rem;
    width: 4rem;
    border-radius: 1rem;
    margin: 1rem;

    &-default {
      height: 2.5rem;
      width: 2.5rem;
    }
  }

  &-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 1rem);
    font-family: $header-font-family;
    font-size: 1.3rem;
    font-weight: 600;
    color: $white
  }

  &-author {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 1rem);
    font-family: $paragraph-font-family;
    font-size: .9rem;
    margin-top: .2rem;
    font-weight: 400;
    color: $offWhite05;
  }

  &-description {
    display: flex;
    justify-content: flex-start;
    min-height: 4rem;
    font-family: $paragraph-font-family;
    font-size: .9rem;
    font-weight: 400;
    color: $grey;
    margin-top: 1rem;
    width: calc(100% - 1rem);
  }

  &-lock {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: .7rem;
    color: $white;
    padding: .4rem;
    border-radius: 50%;
    background: transparentize($pink, .2);
    font-size: .9rem;
    font-weight: 300;
    transition: .1s linear;
    z-index: 2;
  }

  .categoriesSection {
    display: flex;
    align-items: flex-start;
    width: calc(100% - 1rem);
    margin-top: 1rem;
    padding-bottom: .5rem;
    flex-wrap: wrap;
    position: absolute;
    bottom: 0;

    .categoriesSectionItem {
      font-family: $paragraph-font-family;
      font-size: .8rem;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: .25rem;
      padding: .3rem .5rem;
      color: $white;
      border-radius: $smallRadius;
      border: $thinBorder;
    }
  }

  &:hover {
    border: 1px solid transparentize($white, 0.5);

  }
}

.agentCard-small {
  width: 100%;
  height: 8rem;
  min-height: unset;
  box-shadow: none;
  border: 1px solid transparentize($white, .8);

  .agentCard-content {
    margin-top: unset;
  }

  .categoriesSection {
    display: none;
  }
}

.agentCard-noColor {
  background: transparent;
  box-shadow: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 4rem;
  border-radius: $smallRadius;
  margin: .5rem;

  .agentCard-logo {
    position: relative;

    &-default {
      height: 2rem;
      width: 2rem;
    }
  }

  .agentCard-lock {
    display: none;
  }

  .agentCard-author {
    display: none;
  }

  .agentCard-title {
    color: $white;
    font-size: 1.3rem;
    font-weight: 400;
    margin-top: unset;
    margin-left: unset;
  }

  .agentCard-author {
    color: $grey;
  }

  .agentCard-description {
    color: $offWhite05;
  }

  .categoriesSectionItem {
    color: $white;
  }
}