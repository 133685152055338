@import "../../../../Utils/mixins.scss";

.customAgents {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparentize($darkerBackground, .5);
  z-index: 3;
  position: relative;

  &-wrapper {
    overflow: hidden;
    position: relative;
    z-index: 0;
  }

  .customizable {
    width: 70%;

    .staticListPoint {
      height: 15rem;
    }

    &-llms, &-tweak, &-share {
      width: 100%;
      height: 100%;
      border-radius: $mediumRadius;
      border: $thinBorder;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      img {
        width: 4rem;
        height: 4rem;
        object-fit: cover;
      }
    }


  }

  .commissions {
    width: 100%;
    min-width: 50rem;

    .staticListPoint {
      height: 20rem;
      width: 80%;
      margin-left: 10%;
      padding-bottom: 0;
    }

    &-component {
      height: 100%;
      width: 100%;
      border-radius: $mediumRadius;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-graph {
        height: 80%;
        width: 100%;
        border: $thinBorder;
        border-radius: $mediumRadius;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 3rem;
          margin: 0 1.2rem;
          height: 3rem;
          padding: .7rem;
          border: $thinBorder;
          border-radius: $mediumRadius;
          background: $pink;
          color: $white;
        }

        &-arrow {
          width: 2.5rem !important;
          height: 2.5rem !important;
          color: $white !important;
          background: unset !important;
          border: unset !important;
        }
      }

      h1 {
        color: $white;
        width: 100%;
        height: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
      }
    }
  }

  &-document {
    .staticList-list2 {
      width: 60%;
      grid-template-columns: 3fr 3fr;
    }

    .staticListPoint {
      height: 22rem !important;
    }

    &-reliable, &-secure {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: $thinBorder;
      border-radius: $mediumRadius;
      margin-top: .5rem;

      svg {
        width: 4rem;
        height: 4rem;
        margin: 1rem;
        background: $pink;
        padding: 1rem;
        color: $white;
        border-radius: $mediumRadius;
      }
    }

  }

  &-experts {
    margin: 5rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-list {
      margin-top: 2rem;
      width: 90%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .agentCard {
        flex: 1;
        width: unset;
        min-width: 25rem;
        max-width: 30rem;
      }
    }
  }

  &-start {
    margin: 5rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-component {
      margin-top: 2rem;
      height: 30rem;
      width: 50%;
      min-width: 50rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: .3rem;
      background: transparentize($darkerBackground, .4);
      border-radius: $mediumRadius;
      border: $thinBorder;
      box-shadow: 0 0 1rem .5rem transparentize($darkestBlack, .4);

      img {
        width: 90%;
        height: 70%;
        object-fit: cover;
      }
    }
  }
}


@include tablets {
  .customizable, .commissions {
    width: 100% !important;
    min-width: unset !important;
    align-items: center;

    .staticListPoint {
      width: 100% !important;
      margin-left: 0 !important;
      align-self: center;
    }


  }

  .commissions {
    .staticListPoint {
      height: 30rem !important;
    }

    &-component {
      &-graph {
        flex-direction: column;
      }
    }
  }
}